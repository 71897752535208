import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { RoadsideEvent } from '@types';
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import { convertUTCDateToLocalTime } from 'utilities/date-utils';
import {
  UtilityToolTip,
  SuccessToolTip,
  ErrorToolTip,
  HighlightToolTip,
} from 'components/Tooltips';
import React from 'react';
import { RoadsideEventStatuses } from 'constants/RoadsideEventStatuses';

enum EventTypes {
  Tow = 'Tow',
  WinchOut = 'Winch Out',
  BatteryJump = 'Battery Jump',
  TireOut = 'Tire Out',
  LockOut = 'Lock Out',
  FuelDelivery = 'Fuel Delivery',
}

export interface RoadsideEventTimelineProps {
  roadsideEvent: RoadsideEvent;
}

const HorizontalTimeline = styled(Timeline)(({ theme }) => ({
  flexDirection: 'row',
  color: theme.namedColors.secondary.default,
  paddingLeft: '0',
  paddingRight: '0',
  marginTop: '.25rem',
  marginBottom: '.25rem',
}));

const HorizontalTimelineSeparator = styled(TimelineSeparator)(() => ({
  flexDirection: 'row',
  flex: '1 1 0',
}));

const HorizontalTimelineItem = styled(TimelineItem)(() => ({
  minWidth: '70px',
  flex: '1 1 0',
  minHeight: 'auto',
  '&:before': {
    flex: 0,
    padding: 0,
  },
  '&:last-of-type': {
    minWidth: 'auto',
    flex: '0',
  },
}));
const HorizontalTimelineConnector = styled(TimelineConnector)(({ theme }) => ({
  backgroundColor: theme.namedColors.secondary.default,
  height: '3px',
  width: 'auto',
  marginLeft: '-2px',
  marginRight: '-2px',
}));

const SuccessCheck = styled(CheckCircleIcon)(({ theme }) => ({
  fill: theme.namedColors.performance.text.good,
}));

const CompleteDivider = styled(HorizontalTimelineConnector)(({ theme }) => ({
  backgroundColor: theme.namedColors.performance.text.good,
}));

const InprogressDivider = styled(HorizontalTimelineConnector)(({ theme }) => {
  {
    const fillColor = theme.namedColors.performance.text.good.replace('#', '');
    return {
      backgroundImage: `url("data:image/svg+xml,%3Csvg height='3' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='1.5' x2='100%25' y2='1.5' stroke='%23${fillColor}' stroke-width='3' stroke-dasharray='6 6'/%3E%3C/svg%3E")`,
      backgroundRepeat: 'no-repeat no-repeat',
      backgroundPosition: 'center center',
      backgroundColor: 'transparent',
      backgroundSize: 'cover',
    };
  }
});

const TOW_EVENT_TYPES = [EventTypes.Tow];

const getCancelIcon = (tooltipTitle: string): JSX.Element => {
  return (
    <ErrorToolTip title={tooltipTitle}>
      <CancelIcon
        sx={(theme) => ({ color: theme.namedColors.utility.critical })}
      />
    </ErrorToolTip>
  );
};
const getSuccessIcon = (tooltipTitle: string): JSX.Element => {
  return (
    <SuccessToolTip title={tooltipTitle}>
      <SuccessCheck />
    </SuccessToolTip>
  );
};
const getTerminalIcon = (roadsideEvent: RoadsideEvent): JSX.Element | null => {
  switch (roadsideEvent.status) {
    case RoadsideEventStatuses.CustomerNotOnScene:
      return getCancelIcon(
        getToolTipText(
          RoadsideEventStatuses.CustomerNotOnScene,
          roadsideEvent.statusGoaAtEastern
        )
      );
    case RoadsideEventStatuses.CustomerCanceled:
      return getCancelIcon(
        getToolTipText(
          RoadsideEventStatuses.CustomerCanceled,
          roadsideEvent.statusCanceledAtEastern
        )
      );
    case RoadsideEventStatuses.Unsuccessful:
      return getCancelIcon(
        getToolTipText(RoadsideEventStatuses.Unsuccessful, '')
      );
    case RoadsideEventStatuses.Released:
      return getCancelIcon(
        getToolTipText(
          RoadsideEventStatuses.Released,
          roadsideEvent.statusReleasedAtEastern
        )
      );
    case RoadsideEventStatuses.Referred:
      return getCancelIcon(getToolTipText(RoadsideEventStatuses.Referred, ''));
    case RoadsideEventStatuses.Reassigned:
      return (
        <HighlightToolTip
          title={getToolTipText(
            'Reassigned',
            roadsideEvent.statusReassignedAtEastern
          )}
        >
          <SwapHorizontalCircleRoundedIcon
            sx={(theme) => ({ color: theme.namedColors.utility.highlight })}
          />
        </HighlightToolTip>
      );
  }
  return null;
};

const getLastEventIndex = (timeline: Array<string | Date | null>) => {
  let index = -1;
  for (let i = 0; i < timeline.length; i++) {
    if (timeline[i]) {
      index = i;
    }
  }
  return index;
};

const hasFutureStatus = (
  referenceStatus: RoadsideEventStatuses,
  currentStatus: string
) => {
  const statusTimeline = [
    RoadsideEventStatuses.ProviderAssigned,
    RoadsideEventStatuses.EnRoute,
    RoadsideEventStatuses.OnScene,
    RoadsideEventStatuses.Towing,
    RoadsideEventStatuses.Completed,
  ] as string[];
  if (!currentStatus) {
    return false;
  }
  return (
    statusTimeline.indexOf(currentStatus) >
    statusTimeline.indexOf(referenceStatus)
  );
};

const getFinalTimelineItem = (
  roadsideEvent: RoadsideEvent,
  tooltipText: string,
  overrideIcon: JSX.Element | null
): JSX.Element => {
  if (overrideIcon && overrideIcon !== null) {
    return overrideIcon;
  }

  if (roadsideEvent.status === RoadsideEventStatuses.Completed) {
    return getSuccessIcon(tooltipText);
  }
  return (
    <UtilityToolTip title={tooltipText}>
      <CircleRoundedIcon />
    </UtilityToolTip>
  );
};

const getTimelineItem = (
  roadsideEvent: RoadsideEvent,
  tooltipText: string,
  currentEvent: RoadsideEventStatuses,
  overrideIcon: JSX.Element | null,
  isNotFinalTimedEvent: boolean | null
): JSX.Element => {
  const isStatusComplete =
    hasFutureStatus(currentEvent, roadsideEvent.status) || isNotFinalTimedEvent;

  let icon =
    isStatusComplete || currentEvent === roadsideEvent.status ? (
      getSuccessIcon(tooltipText)
    ) : (
      <UtilityToolTip title={tooltipText}>
        <CircleOutlinedIcon />
      </UtilityToolTip>
    );

  if (overrideIcon && overrideIcon !== null) {
    icon = overrideIcon;
  }

  let nextDivider = <HorizontalTimelineConnector />;

  if (
    roadsideEvent.status === currentEvent &&
    roadsideEvent.status !== RoadsideEventStatuses.Completed
  ) {
    nextDivider = <InprogressDivider />;
  } else if (isStatusComplete) {
    nextDivider = <CompleteDivider />;
  }

  return (
    <>
      {icon}
      {nextDivider}
    </>
  );
};

const getToolTipText = (
  text: string,
  eventDateTime: string | Date | null
): string => {
  if (eventDateTime) {
    return `${text} at ${convertUTCDateToLocalTime(eventDateTime)}`;
  }
  return text;
};

const getBaseTimelineItems = (
  roadsideEvent: RoadsideEvent,
  timeLineIndex: number,
  terminalIcon: JSX.Element | null
) => {
  return [
    getTimelineItem(
      roadsideEvent,
      getToolTipText(
        'Provider Assigned',
        roadsideEvent.statusAssignedAtEastern
      ),
      RoadsideEventStatuses.ProviderAssigned,
      terminalIcon && timeLineIndex === -1 ? terminalIcon : null,
      terminalIcon && timeLineIndex > -1
    ),
    getTimelineItem(
      roadsideEvent,
      getToolTipText(
        RoadsideEventStatuses.EnRoute,
        roadsideEvent.statusEnRouteAtEastern
      ),
      RoadsideEventStatuses.EnRoute,
      timeLineIndex === 0 ? terminalIcon : null,
      terminalIcon && timeLineIndex > 0
    ),
    getTimelineItem(
      roadsideEvent,
      getToolTipText(
        RoadsideEventStatuses.OnScene,
        roadsideEvent.statusOnSiteAtEastern
      ),
      RoadsideEventStatuses.OnScene,
      timeLineIndex === 1 ? terminalIcon : null,
      terminalIcon && timeLineIndex > 1
    ),
  ];
};

const getTowTimeline = (roadsideEvent: RoadsideEvent): JSX.Element[] => {
  const terminalIcon = getTerminalIcon(roadsideEvent);
  const timeLineIndex = getLastEventIndex([
    roadsideEvent.statusAssignedAtEastern,
    roadsideEvent.statusEnRouteAtEastern,
    roadsideEvent.statusOnSiteAtEastern,
    roadsideEvent.statusTowingAtEastern,
    roadsideEvent.statusCompletedAtEastern,
  ]);

  return [
    ...getBaseTimelineItems(roadsideEvent, timeLineIndex, terminalIcon),
    getTimelineItem(
      roadsideEvent,
      getToolTipText(
        RoadsideEventStatuses.Towing,
        roadsideEvent.statusTowingAtEastern
      ),
      RoadsideEventStatuses.Towing,
      timeLineIndex === 2 ? terminalIcon : null,
      terminalIcon && timeLineIndex > 2
    ),
    getFinalTimelineItem(
      roadsideEvent,
      getToolTipText(
        RoadsideEventStatuses.Completed,
        roadsideEvent.statusCompletedAtEastern
      ),

      timeLineIndex === 3 || timeLineIndex === 4 ? terminalIcon : null
    ),
  ];
};

const getNonTowTimeline = (roadsideEvent: RoadsideEvent): JSX.Element[] => {
  const terminalIcon = getTerminalIcon(roadsideEvent);
  const timeLineIndex = getLastEventIndex([
    roadsideEvent.statusAssignedAtEastern,
    roadsideEvent.statusEnRouteAtEastern,
    roadsideEvent.statusOnSiteAtEastern,
    roadsideEvent.statusCompletedAtEastern,
  ]);

  return [
    ...getBaseTimelineItems(roadsideEvent, timeLineIndex, terminalIcon),
    getFinalTimelineItem(
      roadsideEvent,
      getToolTipText(
        RoadsideEventStatuses.Completed,
        roadsideEvent.statusCompletedAtEastern
      ),

      timeLineIndex === 2 || timeLineIndex === 3 ? terminalIcon : null
    ),
  ];
};

const getTimeLine = (roadsideEvent: RoadsideEvent): JSX.Element[] => {
  if (TOW_EVENT_TYPES.find((status) => status === roadsideEvent.serviceType)) {
    return getTowTimeline(roadsideEvent);
  }
  return getNonTowTimeline(roadsideEvent);
};

const RoadsideEventTimelineRaw = ({
  roadsideEvent,
}: RoadsideEventTimelineProps) => {
  const timeline = getTimeLine(roadsideEvent);
  return (
    <HorizontalTimeline data-testid="roadside-timeline">
      {timeline?.map((item, i) => (
        <HorizontalTimelineItem key={i}>
          <HorizontalTimelineSeparator>{item}</HorizontalTimelineSeparator>
        </HorizontalTimelineItem>
      ))}
    </HorizontalTimeline>
  );
};

export const RoadsideEventTimeline = React.memo(RoadsideEventTimelineRaw);
