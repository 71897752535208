import React, { useContext, useEffect, useState } from 'react';
import { UserSettings, ClientSettings, SettingData } from '@types';
import { useAuthentication } from './useAuthentication';
import { SettingFeature } from '../constants/SettingFeature';
import { useClientCode } from './useClientCode';

export interface SettingsContextProps {
  setClientSettings: React.Dispatch<
    React.SetStateAction<ClientSettings[] | []>
  >;
  clientSettings: ClientSettings[] | [];
  showExitDialog: boolean;
  setShowExitDialog: React.Dispatch<React.SetStateAction<boolean>>;
  enableFeature: (name: SettingFeature) => boolean;
  agentSettings: UserSettings | null | undefined;
  setAgentSettings: React.Dispatch<
    React.SetStateAction<UserSettings | null | undefined>
  >;
  getClientConfigByCode: (
    code: string | null,
    isAdminUser?: boolean
  ) => ClientSettings | null;
}

export const SettingsContext = React.createContext<SettingsContextProps | null>(
  null
);

export const SettingsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [clientSettings, setClientSettings] = useState<ClientSettings[] | []>(
    []
  );
  const [showExitDialog, setShowExitDialog] = useState<boolean>(false);
  const [agentSettings, setAgentSettings] = useState<
    UserSettings | null | undefined
  >(null);

  const { auth } = useAuthentication();
  const clientCode = useClientCode();

  useEffect(() => {
    setLoading(false);
  }, []);

  const enableFeature = (name: SettingFeature): boolean => {
    const settingByClient = getClientConfigByCode(clientCode, false);
    const setting = settingByClient
      ? settingByClient.settings.filter(
          (item: SettingData) => item.settingName == name
        )[0]
      : null;
    if (setting && setting.enabled) {
      return true;
    }
    return false;
  };

  const getClientConfigByCode = (
    code: string | null,
    isAdminUser = true
  ): ClientSettings | null => {
    const config = clientSettings.length
      ? clientSettings.filter(
          (item: ClientSettings) => item.clientCode == code
        )[0]
      : null;
    if (isAdminUser && config) {
      if (auth.isSystemAdmin() || auth.isSupportSpecialist()) {
        config.settings.forEach((item: SettingData) => {
          item['enabled'] = item.feature.isPremium
            ? item.feature.systemAdminEnabled === true
            : item.enabled;
        });
      } else if (auth.isClientAdmin()) {
        const settingsFiltered = config.settings.filter((item: SettingData) => {
          return item.feature.isPremium
            ? item.feature.systemAdminEnabled == true
            : true;
        });
        config.settings = settingsFiltered;
      }
    }
    return config;
  };

  if (loading) {
    return null;
  }

  return (
    <SettingsContext.Provider
      value={{
        setClientSettings,
        clientSettings,
        showExitDialog,
        setShowExitDialog,
        enableFeature,
        setAgentSettings,
        agentSettings,
        getClientConfigByCode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export function useSettings(): SettingsContextProps {
  const ga = useContext(SettingsContext);
  return ga ? ga : assertNever();
}

function assertNever(): never {
  throw Error('Cannot use useSettings outside of SettingsProvider');
}
