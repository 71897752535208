/**
 * Status events associated with roadside events.  These have to match up with values passed in from the API.
 *
 * @export
 * @enum {number}
 */
export enum RoadsideEventStatuses {
  RequestCreated = 'Request Created',
  ProviderAssigned = 'Provider Assigned',
  Completed = 'Completed',
  CustomerNotOnScene = 'Customer Not On Scene',
  CustomerCanceled = 'Customer Canceled',
  Unsuccessful = 'Unsuccessful',
  Released = 'Released',
  Towing = 'Towing',
  Referred = 'Referred',
  Reassigned = 'Reassigned',
  EnRoute = 'En Route',
  OnScene = 'On Scene',
}
