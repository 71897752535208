import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { Sidebar } from '../components/Sidebar';
import { Navbar } from '../components/Navbar';
import { useAuthentication } from 'hooks/useAuthentication';
import { Navigate } from 'react-router-dom';
import { RouterLink } from '../components/Routes/RouterLink';

const Drawer = styled(MuiDrawer)(() => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '180px',
    boxSizing: 'border-box',
  },
}));

const LayoutAdmin: FC = () => {
  const { auth } = useAuthentication();
  const SidebarLinks = RouterLink('admin');
  if (!auth.isSystemAdmin() && !auth.isSupportSpecialist()) {
    return (
      <div data-testid="navigate-element">
        <Navigate to="/unauthorized" replace />
      </div>
    );
  }

  return (
    <Box sx={{}} data-testid="admin-layout-container">
      <Navbar {...SidebarLinks} />
      <Box sx={{ display: 'flex' }}>
        <Drawer
          variant="permanent"
          sx={{ paddingTop: '70px', scrollPaddingTop: '70px' }}
        >
          <Sidebar {...SidebarLinks} />
        </Drawer>
        <Box
          component="main"
          id="agero-scroll-container"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            paddingTop: '70px',
            scrollPaddingTop: '70px',
          }}
        >
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </Box>
  );
};

export default LayoutAdmin;
