const useLoadScript = () => {
  if (!document.getElementById('hs-script-loader')) {
    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.src = 'https://js.hs-scripts.com/2932510.js';
    document.body.appendChild(script);
    return true;
  }
  return false;
};

export { useLoadScript };
