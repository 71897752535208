import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  useRoadsideEventMetrics,
  useRoadsideEventsByAgentId,
} from 'api/roadside-events';
import { useUserSettings } from 'api/user-settings';
import { useUserMetrics } from 'api/user-metrics';
import { useTheme, Theme, styled } from '@mui/material/styles';
import { useAuthentication } from 'hooks/useAuthentication';
import { getDateFromToday } from 'utilities/date-utils';
import { NoAgentIdSelectedError } from 'errors';
import DashboardWithFilters from './components/DashboardWithFilters/DashboardWithFilters';
import NpsPerformanceMetrics from './components/NpsPerformanceMetrics';
import { getNpsPerformanceMetricsFromAggregations } from 'utilities/data-utils';
import RoadsideEvents from './components/RoadsideEvents/RoadsideEvents';
import SurveyFeedback from './components/SurveyFeedback/SurveyFeedback';
import {
  Log,
  RoadsideEvent,
  RoadsidePerFormanceMetrics,
  SMSPopupData,
} from '@types';
import useMediaQuery from '@mui/material/useMediaQuery';

import logo from 'assets/images/logo.png';
import EmptyPane from './components/EmptyPane';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsPageLocation,
  GoogleAnalyticsPageTitle,
  GoogleAnalyticsContentGroup,
  GoogleAnalyticsEventOptions,
} from 'constants/GoogleAnalytics';
import { Popup } from 'components/Popup';
import { useLogger } from 'api/logger';
import { LogAction, LogLevel } from 'constants/Logger';
import { useAgentIdHistory } from 'hooks/useAgentIdHistory';
import { useSettings } from 'hooks/useSettings';
import { SettingFeature } from 'constants/SettingFeature';
import { usePreCheckSMS, useSendSMS } from 'api/message';
import { unFormatedPhoneNumber } from 'utilities/phone-utils';
import TextField from '@mui/material/TextField';
import { Toast } from 'components/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { capitalize } from 'utilities/string-utils';
import PerformanceMetricsBreakdown from './components/PerformanceMetricsBreakdown';
import { HubspotCta } from 'components/HubspotCta';
import { useClientCode } from 'hooks/useClientCode';

export type InsightGroups =
  | ''
  | 'promoter'
  | 'detractor'
  | 'neutral'
  | 'complaint';

export interface FilterValues {
  insightGroup: InsightGroups;
  dateDropDownValue: string;
  startDate: Date;
  endDate: Date;
  agentId: string | null;
  policyId: string | null;
}

const getPerformanceMetrics = (
  enableStateLevelNps: boolean,
  metricsAreLoading: boolean,
  filterValues: FilterValues,
  showPerformanceBreakDown: (flag: boolean) => void,
  metricsData?: RoadsidePerFormanceMetrics,
  sendEventToGA?: (
    event_type: GoogleAnalyticsEventType,
    data: GoogleAnalyticsEventOptions
  ) => void
) => {
  if (metricsAreLoading || !metricsData) {
    return <NpsPerformanceMetrics loading />;
  } else {
    return (
      <NpsPerformanceMetrics
        metricData={getNpsPerformanceMetricsFromAggregations(metricsData)}
        filterValues={filterValues}
        sendEventToGA={sendEventToGA}
        enableStateLevelNps={enableStateLevelNps}
        showPerformanceBreakDown={showPerformanceBreakDown}
      />
    );
  }
};

const NoDataBox = styled(Box)(() => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  zIndex: 999999,
}));

/**
 * Primary application view
 *
 * @return {*}
 */
const Dashboard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('lg'));
  const { auth } = useAuthentication();
  const { defaultAgentId, setDefaultAgentId } = useDefaultAgentId();
  const defaultClientCode = useClientCode();
  const [loading, setLoading] = useState<boolean>(false);
  const [loginReporting, setLoginReporting] = useState<boolean>(false);
  const isNonAgero = auth.getDomain() != 'agero.com';
  const hasAllReadAccess = auth.hasAllReadAccess();
  const user_id =
    isNonAgero && defaultAgentId && !hasAllReadAccess
      ? defaultAgentId
      : auth.getName();

  const policyNo = '';
  const { sendGAEvent } = useGoogleAnalytics();
  const { data: userSettings, refetch } = useUserSettings(
    user_id,
    auth.getEmail(),
    localStorage.getItem('User_LoggedIn')
      ? Math.round(new Date().getTime() / 1000)
      : 0
  );
  const [isLoginSession] = useState<boolean>(
    localStorage.getItem('User_LoggedIn') ? true : false
  );
  const refToTop = useRef<HTMLInputElement>(null);

  const { agentSettings, enableFeature, getClientConfigByCode } = useSettings();
  const [agentIdHistory, setAgentIdHistory] = useAgentIdHistory();
  const [searchByPolicy, setSearchByPolicy] = useState<boolean>(false);
  const [roadsideEvents, setRoadsideEvents] = useState<RoadsideEvent[]>([]);
  const [logData, setLogData] = useState<Log | null>(null);
  const { refetch: refetchLogger } = useLogger(logData);
  const [searchByType, setSearchByType] = useState<string | undefined>('');
  const [showPerformanceBreakDown, setShowPerformanceBreakDown] =
    useState<boolean>(false);
  const { data: userMetrics, refetch: refetchUserMetrics } =
    useUserMetrics(user_id);
  const clientSettings = getClientConfigByCode(defaultClientCode, false);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const googleReviewUrl =
    agentSettings?.settings?.googleReviewRequest.businessPageLink;
  const enableGoogleReview =
    enableFeature(SettingFeature.googleReviewRequest) &&
    auth.isAgent() &&
    agentSettings?.settings?.googleReviewRequest.isEnabled
      ? true
      : false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [googleReviewDialog, setGoogleReviewDialog] = useState<any>(null);
  const [sendGoogleRequestFor, setSendGoogleRequestFor] = useState<number>(0);
  const [preCheckValue, setPreCheckValue] = useState<string | null>(null);
  const [sendSmsValue, setSendSmsValue] = useState<{
    jobId: number;
    customerPhone: string;
    message: string;
    agentEmail: string;
  } | null>(null);

  const [requestText, setRequestText] = useState<string>('');
  const [googleReviewData, setGoogleReviewData] = useState<SMSPopupData>({
    jobId: 0,
    callbackNumber: '',
    clientName: '',
    customerLocation: '',
    firstName: '',
    lastName: '',
  });
  const {
    data: preCheckData,
    refetch: refetchPreCheck,
    status: preCheckStatus,
  } = usePreCheckSMS(user_id, preCheckValue);

  const {
    data: sendSmsData,
    refetch: refetchSendSMS,
    status: sendSmsStatus,
  } = useSendSMS(sendSmsValue, user_id);

  useEffect(() => {
    if (localStorage.getItem('User_LoggedIn')) {
      refetch();
      refetchUserMetrics();
      sendLogger({
        logAction: LogAction.LOGIN,
        logLevel: LogLevel.INFO,
        logMessage: 'Login Success',
        logDescription: 'User Successfully logged in',
      });
    }
  }, []);

  useEffect(() => {
    if (isLoginSession && agentSettings && userMetrics)
      sendGAEvent(GoogleAnalyticsEventType.PageView, {
        content_group: GoogleAnalyticsContentGroup.Insights,
        page_location: GoogleAnalyticsPageLocation.Insights,
        page_title: GoogleAnalyticsPageTitle.Insights,
        successful_review_sms: userMetrics?.successfulReviewRequestsSMS,
        unsuccessful_review_sms: userMetrics?.unSuccessfulReviewRequestsSMS,
      });
    else if (agentSettings && !isLoginSession)
      sendGAEvent(GoogleAnalyticsEventType.PageView, {
        content_group: GoogleAnalyticsContentGroup.Insights,
        page_location: GoogleAnalyticsPageLocation.Insights,
        page_title: GoogleAnalyticsPageTitle.Insights,
        successful_review_sms: userMetrics?.successfulReviewRequestsSMS,
        unsuccessful_review_sms: userMetrics?.unSuccessfulReviewRequestsSMS,
      });
  }, [agentSettings, userMetrics]);

  useEffect(() => {
    if (userSettings && localStorage.getItem('User_LoggedIn')) {
      localStorage.removeItem('User_LoggedIn');
      setLoginReporting(true);
    }
  }, [userSettings]);

  const [filterValues, setFilterValues] = useState<FilterValues>({
    insightGroup: '',
    dateDropDownValue: '90',
    startDate: getDateFromToday(-90),
    endDate: new Date(),
    agentId: defaultAgentId,
    policyId: policyNo,
  });

  const npsCategory =
    filterValues.insightGroup !== '' &&
    filterValues.insightGroup !== 'complaint'
      ? filterValues.insightGroup
      : '';
  const {
    isLoading: roadsideIsLoading,
    isError: roadsideIsError,
    data: roadsideEventData,
    error: roadsideError,
    refetch: refetchRoadsideEventsByAgentId,
  } = useRoadsideEventsByAgentId(
    filterValues.agentId,
    filterValues.startDate,
    filterValues.endDate,
    npsCategory,
    filterValues.insightGroup === 'complaint' ? true : undefined,
    filterValues.policyId
  );

  const {
    data: metricsData,
    isLoading: metricsAreLoading,
    isError: metricsDataIsError,
    error: metricsDataError,
    refetch: refetchRoadsideEventMetrics,
  } = useRoadsideEventMetrics(
    filterValues.agentId,
    filterValues.startDate,
    filterValues.endDate,
    filterValues.policyId
  );

  const onFilterChange = (event: FilterValues, filter_type = '') => {
    if (filter_type == 'policySearch' && event.policyId) {
      event.agentId = '';
    }
    setSearchByType(filter_type);
    setFilterValues(event);
    setDefaultAgentId(event.agentId);
  };

  useEffect(() => {
    if (searchByPolicy && filterValues.agentId) {
      return;
    }
    if (filterValues.agentId) {
      refetchRoadsideEventsByAgentId();
      if (searchByType != 'npsCategorySearch') refetchRoadsideEventMetrics();
    } else if (filterValues.policyId && searchByType != 'agentIdSearch') {
      refetchRoadsideEventsByAgentId();
      setSearchByPolicy(true);
    }
  }, [filterValues]);

  useEffect(() => {
    if (searchByPolicy) {
      if (
        roadsideEventData &&
        roadsideEventData.length > 0 &&
        !filterValues.agentId &&
        searchByType != 'agentIdSearch'
      ) {
        const roadsideEvent = roadsideEventData.find(
          (roadsideEvent) =>
            roadsideEvent.agentAssociateId !== null &&
            roadsideEvent.agentAssociateId !== ''
        );
        if (roadsideEvent?.agentAssociateId) {
          setDefaultAgentId(roadsideEvent.agentAssociateId);
          const newFilters = filterValues;
          newFilters.agentId = roadsideEvent.agentAssociateId;
          setFilterValues(newFilters);
          const newHistory = Array.from(
            new Set([newFilters.agentId, ...agentIdHistory])
          ).slice(0, 5);
          setAgentIdHistory(newHistory);
        }
      }
    }
  }, [roadsideEvents, searchByType, searchByPolicy]);

  useEffect(() => {
    if (searchByPolicy && filterValues.agentId) {
      setSearchByPolicy(false);
      refetchRoadsideEventMetrics();
    }
  }, [filterValues.agentId]);

  useEffect(() => {
    if (roadsideEventData) {
      setRoadsideEvents(roadsideEventData);
    }
  }, [roadsideEventData]);

  useEffect(() => {
    if (
      userMetrics &&
      loginReporting &&
      ((metricsData && !auth.hasAllReadAccess()) || auth.hasAllReadAccess())
    ) {
      const reportingData = {
        loginCount: userSettings?.login_count,
        firstLogin:
          userSettings?.first_login &&
          new Date(userSettings.first_login * 1000)?.toISOString(),
        lastLogin:
          userSettings?.last_login &&
          new Date(userSettings.last_login * 1000)?.toISOString(),
        agentState: userMetrics?.agentState,
        loginDays: userMetrics?.loginDays,
        loginWeeks: userMetrics?.loginWeeks,
        totalEngagementTime: userMetrics?.totalEngagementTime,
        averageEngagementTime: userMetrics?.averageEngagementTime,
        successfulReviewRequestsSMS: userMetrics?.successfulReviewRequestsSMS,
        unSuccessfulReviewRequestsSMS:
          userMetrics?.unSuccessfulReviewRequestsSMS,
        firstName: auth.getFirstName(),
        lastName: auth.getLastName(),
        ...(!auth.hasAllReadAccess()
          ? {
              policyHolderEvents: metricsData?.events.total,
              SurveyFeedbackPromoter:
                metricsData?.surveyFeedback.counts.promoter,
              SurveyFeedbackNeutral: metricsData?.surveyFeedback.counts.neutral,
              SurveyFeedbackDetractor:
                metricsData?.surveyFeedback.counts.detractor,
              policyHolderNPS: metricsData?.nps,
              policyHolderComplaints: metricsData?.complaints.totalCount,
              stateNPS: metricsData?.state.nps,
              stateComplaints: metricsData?.state.complaintCount,
            }
          : {}),
      };

      const action = LogAction.LOGIN_REPORTING;
      const message = 'Login Reporting';
      sendLogger({
        logAction: action,
        logLevel: LogLevel.INFO,
        logMessage: message,
        logDescription: message,
        additionalInfo: JSON.stringify(reportingData),
      });
    }
  }, [metricsData, userMetrics, loginReporting]);
  useEffect(() => {
    if (logData) refetchLogger();
  }, [logData]);

  const sendLogger = (data: Log) => {
    setLogData({
      ...data,
      user: {
        email: auth.getEmail(),
        officeCode: defaultAgentId,
        clientCode: auth.getClientCode(),
      },
    });
  };

  useEffect(() => {
    if (window.location.pathname == '/' + auth.getClientCode()) {
      setShowPerformanceBreakDown(false);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (!showPerformanceBreakDown) {
      refToTop.current &&
        refToTop.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showPerformanceBreakDown]);

  const showPerformanceMetrics = (flag: boolean) => {
    setShowPerformanceBreakDown(flag);
    if (flag) window.history.pushState(null, '', '/performance-metrics');
    else window.history.replaceState(null, '', '/' + defaultClientCode);
  };

  useEffect(() => {
    if (preCheckValue) refetchPreCheck();
  }, [preCheckValue]);

  useEffect(() => {
    if (sendSmsValue) refetchSendSMS();
  }, [sendSmsValue]);

  useEffect(() => {
    if (preCheckData && preCheckStatus === 'success') {
      if (preCheckData.optinFlag)
        setGoogleReviewDialog({
          title: 'Request a Google Review',
          subtitle:
            'You’re requesting a Google review from this customer. You can personalize your message below before sending. It is recommended to limit your message to 160 characters.',
          allowRequest: true,
          btnConfirm: 'Send Request',
          onConfirm: sendGoogleRequest,
          ...googleReviewData,
        });
      else {
        setGoogleReviewDialog({
          title: 'Request cannot be sent',
          subtitle:
            'This customer has opted out of receiving notifications. Please call this customer to request a Google review.',
          ...googleReviewData,
        });
        sendGAEvent(GoogleAnalyticsEventType.SelectContent, {
          content_type: 'Google Review Request',
          item_id: 'Customer SMS opt-out alert',
        });
      }
    }
    if (preCheckStatus == 'error' || preCheckStatus === 'success') {
      setLoading(false);
      setPreCheckValue(null);
    }
  }, [preCheckData, preCheckStatus]);

  const showGoogleReviewRequest = (popupData: SMSPopupData) => {
    setLoading(true);
    setGoogleReviewData(popupData);

    sendGAEvent(GoogleAnalyticsEventType.SelectContent, {
      content_type: 'Google Review Request',
      item_id: 'Request a Google Review Button',
    });

    if (!googleReviewUrl) {
      setGoogleReviewDialog({
        onConfirm: redirectToSettings,
        title: 'Add your Google Business Profile to your account',
        subtitle: (
          <span>
            To get started, add your Google Business Profile to your account.
            Don't have a Google Business Profile? Click{' '}
            <a
              href="https://www.google.com/business/"
              target="_blank"
              style={{ textDecoration: 'underline' }}
            >
              here
            </a>{' '}
            to learn how to set one up.
          </span>
        ),
        btnConfirm: 'Add Google Business Profile',
        ...popupData,
      });
      setLoading(false);
    } else {
      setRequestText(
        `Hi ${capitalize(popupData.firstName)},\n\nThanks for choosing ${
          popupData.clientName
        }. We hope we've provided excellent service and peace of mind. We would appreciate it if you could take a moment to share your experience on Google.\n\n${googleReviewUrl}`
      );
      setPreCheckValue(unFormatedPhoneNumber(popupData.callbackNumber));
    }
  };

  const redirectToSettings = () => {
    sendGAEvent(GoogleAnalyticsEventType.SelectContent, {
      content_type: 'Google Review Request',
      item_id: 'Add Google Business Profile Button',
    });
    navigate('/user-settings', {
      state: {
        collapsed: true,
      },
    });
  };

  useEffect(() => {
    if (
      sendSmsData &&
      sendSmsStatus === 'success' &&
      googleReviewData.callbackNumber
    ) {
      if (sendSmsData.result) {
        setGoogleReviewDialog({
          title: 'Google Review Requested',
          subtitle: '',
          allowRequest: false,
          ...googleReviewData,
        });
        setSendGoogleRequestFor(googleReviewData.jobId);
      } else {
        setGoogleReviewDialog({
          title: 'Request cannot be sent',
          subtitle: sendSmsData.message,
          allowRequest: false,
          ...googleReviewData,
        });
      }
    }
    if (sendSmsStatus == 'error' || sendSmsStatus === 'success') {
      setLoading(false);
      setSendSmsValue(null);
    }
  }, [sendSmsData, sendSmsStatus]);

  const sendGoogleRequest = () => {
    setLoading(true);
    setSendSmsValue({
      jobId: googleReviewData.jobId,
      customerPhone: unFormatedPhoneNumber(googleReviewData.callbackNumber),
      message: requestText,
      agentEmail: auth.getEmail(),
    });
    sendGAEvent(GoogleAnalyticsEventType.SelectContent, {
      content_type: 'Google Review Request',
      item_id: 'Send Request Button',
    });
  };

  const requestReview = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setRequestText(e.target.value);
  };

  const onGoogleReviewDismiss = () => {
    setPreCheckValue(null);
    setSendSmsValue(null);
    setGoogleReviewDialog(null);
    setGoogleReviewData({
      jobId: 0,
      callbackNumber: '',
      clientName: '',
      customerLocation: '',
      firstName: '',
      lastName: '',
    });

    setLoading(false);
  };

  if (
    metricsDataIsError &&
    roadsideIsError &&
    !(
      metricsDataError instanceof NoAgentIdSelectedError ||
      roadsideError instanceof NoAgentIdSelectedError
    )
  ) {
    return (
      <div data-testid="navigate-element">
        <Navigate to="/error" replace />
      </div>
    );
  }

  return (
    <>
      <Box
        ref={refToTop}
        data-testid="dashboard"
        sx={{
          display: `${showPerformanceBreakDown ? 'none' : 'flex'}`,
          height: '100%',
          flexDirection: 'column',
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }}>
          Welcome, {auth.getName()}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(1),
          }}
        >
          <Typography variant="h3">Customer Insights </Typography>
          <Box component="img" src={logo} sx={{ width: '140px' }}></Box>
        </Box>
        <DashboardWithFilters
          enablePolicySearch={enableFeature(SettingFeature.policySearch)}
          filterValues={filterValues}
          onFilterChange={onFilterChange}
          sendEventToGA={sendGAEvent}
          logger={sendLogger}
          agentIdentifier={
            clientSettings?.agentIdentifier
              ? clientSettings?.agentIdentifier
              : 'Agent Office Code'
          }
        ></DashboardWithFilters>
        {defaultClientCode == 'AGR' ? (
          <EmptyPane
            title="Select Client"
            desc="To view roadside insights, select client"
          />
        ) : !filterValues.agentId ? (
          <EmptyPane
            title={`Enter ${clientSettings?.agentIdentifier}`}
            desc={`To view roadside insights, enter an ${clientSettings?.agentIdentifier.toLowerCase()}`}
          />
        ) : (
          <>
            <Box sx={{ margin: '30px 0px' }}>
              {getPerformanceMetrics(
                enableFeature(SettingFeature.stateLevelNPS),
                metricsAreLoading,
                filterValues,
                showPerformanceMetrics,
                metricsData,
                sendGAEvent
              )}
            </Box>
            <Grid
              container
              sx={{
                flex: '1 1 0',
              }}
              spacing={2}
            >
              <Grid
                item
                sm={12}
                lg={8}
                data-testid="feedback-grid"
                sx={{
                  height: isMobile ? 'auto' : '100%',
                  paddingBottom: '25px',
                }}
              >
                <SurveyFeedback
                  isLoading={roadsideIsLoading}
                  isError={roadsideIsError}
                  data={roadsideEvents}
                  enableWinback={enableFeature(SettingFeature.winback)}
                  enableGoogleReviewRequest={enableGoogleReview}
                  sendEventToGA={sendGAEvent}
                  enableBetaNotification={
                    isLoginSession && !agentSettings?.review_requests
                      ? true
                      : false
                  }
                  showGoogleReviewRequest={showGoogleReviewRequest}
                  sendRequestFor={sendGoogleRequestFor}
                ></SurveyFeedback>
              </Grid>
              <Grid
                item
                sm={12}
                lg={4}
                sx={{
                  height: isMobile ? 'auto' : '100%',
                  paddingBottom: '25px',
                }}
              >
                <RoadsideEvents
                  isLoading={roadsideIsLoading}
                  isError={roadsideIsError}
                  data={roadsideEvents}
                ></RoadsideEvents>
              </Grid>
            </Grid>
          </>
        )}

        {searchParams.get('cta') == 'true' && <HubspotCta />}

        {googleReviewDialog && googleReviewData.callbackNumber && (
          <Popup
            maxWidth="sm"
            id="google-review-dialog"
            open={true}
            closeIcon={true}
            onDismiss={onGoogleReviewDismiss}
            title={googleReviewDialog.title}
            subtitle={googleReviewDialog.subtitle}
            btnConfirm={googleReviewDialog.btnConfirm}
            onConfirm={googleReviewDialog.onConfirm}
            btnConfirmDisabled={
              googleReviewUrl && requestText === '' ? true : false
            }
            position="left"
          >
            {googleReviewDialog?.allowRequest && (
              <>
                <Typography fontSize={'14px'} marginBottom={'10px'}>
                  {googleReviewDialog.firstName} {googleReviewDialog.lastName}
                </Typography>
                <Typography fontSize={'14px'} marginBottom={'10px'}>
                  {googleReviewDialog.callbackNumber}
                </Typography>
                <Typography fontSize={'14px'} marginBottom={'10px'}>
                  {googleReviewDialog.customerLocation}
                </Typography>
                <TextField
                  inputProps={{
                    style: {
                      fontSize: '14px',
                    },
                  }}
                  multiline
                  fullWidth
                  onChange={requestReview}
                  value={requestText}
                />
              </>
            )}
          </Popup>
        )}
        <Toast
          open={
            googleReviewData.callbackNumber &&
            googleReviewUrl &&
            (preCheckStatus === 'error' || sendSmsStatus === 'error')
              ? true
              : false
          }
          message="Some error occured. Please try again later"
          type="ERROR"
        />
        {loading && (
          <NoDataBox data-testid="gr-loading-container">
            <CircularProgress size="50px" />
          </NoDataBox>
        )}
      </Box>

      {showPerformanceBreakDown && (
        <PerformanceMetricsBreakdown
          showPerformanceBreakDown={showPerformanceMetrics}
          startDate={filterValues.startDate}
          endDate={filterValues.endDate}
        />
      )}
    </>
  );
};

export default Dashboard;
