import axios from 'axios';
import { useConfig } from 'hooks/useConfig';
import { useQuery } from 'react-query';
import { PreCheckSMS, SendSMS, SendSMSResponse } from '@types';

export const usePreCheckSMS = (
  agentId: string,
  callbacknumber: string | null,
  enabled = false
) => {
  const { api } = useConfig();

  return useQuery(
    `preCheck`,
    () => {
      return axios
        .get<PreCheckSMS>(`${api.baseUrl}/${agentId}/message/precheck`, {
          params: {
            callbacknumber: callbacknumber,
          },
        })
        .then((res) => {
          const result = res.data;
          result.timeStamp = Date.now();
          return result;
        });
    },
    {
      enabled: enabled && callbacknumber != null,
      retry: false,
    }
  );
};

export const useSendSMS = (
  requestBody: SendSMS | null,
  agentId: string,
  enabled = false
) => {
  const { api } = useConfig();
  return useQuery(
    `useSendSMS`,
    () => {
      return axios
        .post<SendSMSResponse>(
          `${api.baseUrl}/${agentId}/message/sendsms`,
          requestBody
        )
        .then((res) => {
          const result = res.data;
          result.timeStamp = Date.now();
          return result;
        });
    },
    { enabled: enabled && requestBody != null, retry: false }
  );
};
