import { AtaSplitUp } from '@types';
import MetricCard from '../MetricCard';
import PerformanceBreakoutChart from '../PerformanceBreakoutChart';

interface serviceType {
  type: string;
  percentage: number;
  npsCategoryCount: number;
}

const BreakdownByServiceType = ({
  loading,
  title,
  type,
  percentages,
  category,
}: {
  loading: boolean;

  title: string;
  type: 'percent' | 'time' | 'value';
  percentages: serviceType[] | AtaSplitUp;
  category?: string;
}) => (
  <MetricCard loading={loading} title={title} noBorder>
    <PerformanceBreakoutChart
      percentages={percentages}
      type={type}
      title={title}
      category={category}
    />
  </MetricCard>
);

export default BreakdownByServiceType;
