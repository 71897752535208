import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import { Metric } from '@types';

const MetricGroup = ({ metrics }: { metrics: Metric[] }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
      {metrics.map((metric, i) => (
        <Box
          sx={{
            minWidth: '90px',
            textAlign: 'center',
            '&:first-of-type': {
              textAlign: 'left',
            },
            '&:last-of-type': {
              textAlign: 'right',
            },
          }}
          key={i}
        >
          <Typography fontSize="1.5rem">{metric.value}</Typography>
          <Typography
            fontSize="0.75rem"
            color={theme.namedColors.brand.copyLight}
          >
            {metric.label}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default MetricGroup;
