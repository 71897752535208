import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MetricCard from '../MetricCard';
import TimeSeriesGraph from '../TimeSeriesGraph';
import { TimeSeriesGraphProps } from '../TimeSeriesGraph/TimeSeriesGraph';

interface TimeSeriesCardProps extends TimeSeriesGraphProps {
  title: string;
  loading: boolean;
}

const TimeSeriesCard = (props: TimeSeriesCardProps) => {
  const { loading, title, ...graphProps } = props;
  const theme = useTheme();
  return (
    <MetricCard
      subtitleStyle={{ display: 'none' }}
      loading={loading}
      title={title}
      noBorder
    >
      <Box sx={{ padding: '0px 14px' }}>
        <TimeSeriesGraph {...graphProps} />
        <Typography
          sx={{
            color: theme.namedColors.dark[500],
            fontWeight: 400,
            fontSize: '0.75rem',
            mt: '10px',
          }}
        >
          *Data defaulted to 90 days for trending accuracy
        </Typography>
      </Box>
    </MetricCard>
  );
};

export default TimeSeriesCard;
