import { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AntSwitch } from 'constants/SettingsStyled';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

interface AllEnabledProps {
  title: string;
  initialValue: boolean;
  onAllEnabledChange: (data: boolean) => void;
}

interface ToggleValue {
  allEnabled: boolean;
}

const AllEnabled = ({
  title,
  onAllEnabledChange,
  initialValue,
}: AllEnabledProps) => {
  const theme = useTheme();
  const [state, setState] = useState<ToggleValue>({
    allEnabled: initialValue,
  });

  useEffect(() => {
    setState({
      allEnabled: initialValue,
    });
  }, [initialValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    onAllEnabledChange(event.target.checked);
  };

  return (
    <Box
      sx={{
        mb: theme.spacing(1),
      }}
    >
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            sx={{ margin: '0px' }}
            data-testid="allEnabled"
            control={
              <AntSwitch
                checked={state.allEnabled}
                onChange={handleChange}
                name="allEnabled"
                data-testid="allEnabledToggle"
              />
            }
            label={`Enable ${title}`}
          />
        </FormGroup>
      </FormControl>
    </Box>
  );
};
export default AllEnabled;
