import axios from 'axios';
import { useConfig } from 'hooks/useConfig';
import { useQuery } from 'react-query';
import { UserSettings } from '@types';

export const useUserSettings = (
  agentId: string,
  agentEmail: string,
  last_login_time: number,
  enabled = false
) => {
  const { api } = useConfig();
  return useQuery(
    `userSettings`,
    () => {
      return axios
        .post<UserSettings>(`${api.baseUrl}/${agentId}/users/user-settings`, {
          email: agentEmail,
          ...(last_login_time != 0 && { last_login_time }),
        })
        .then((res) => res.data);
    },
    { enabled: enabled }
  );
};

export const useGetUserSettings = (
  agentId: string | null,
  agentEmail: string | null,
  enabled = false
) => {
  const { api } = useConfig();
  return useQuery(
    `getUserSettings`,
    () => {
      return axios
        .get<UserSettings>(`${api.baseUrl}/${agentId}/users/user-settings`, {
          params: {
            email: agentEmail,
          },
        })
        .then((res) => res.data);
    },
    { enabled: enabled }
  );
};

export const usePatchUserSettings = (
  setting: UserSettings | null,
  agentId: string | null,
  enabled = false
) => {
  const { api } = useConfig();
  const results = useQuery(
    `patchUserSettings`,
    () => {
      return axios
        .patch<UserSettings>(`${api.baseUrl}/${agentId}/users/user-settings`, {
          ...setting,
        })
        .then((res) => res.data);
    },
    { enabled: enabled && setting !== null, retry: false }
  );
  return {
    ...results,
    isError: results.status,
  };
};
