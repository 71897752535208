import { RoadsideEvent, SMSPopupData } from '@types';
import { useMemo } from 'react';

import { styled, Theme, useTheme } from '@mui/material/styles';

import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RemoveIcon from '@mui/icons-material/Remove';
import PhoneIcon from '@mui/icons-material/Phone';
import { formatMonthDate } from '../../../../utilities/date-utils';
import { formatPhoneNumber } from '../../../../utilities/phone-utils';
import ReadMore from 'components/ReadMore';
import GoogleReviewRequest from '../GoogleReviewRequest';

export interface SurveyFeedbackItemProps {
  data: RoadsideEvent;
  enableWinback: boolean;
  enableGoogleReviewRequest: boolean;
  showGoogleReviewRequest: (data: SMSPopupData) => void;
  sendRequestFor: number;
}
interface RoadsideEventDisplay extends RoadsideEvent {
  surveyResponseDateEasternDisplay: string;
  callbackNumberDisplay: string;
  feedbackScore: JSX.Element;
  winbackStatusDisplay: JSX.Element;
  telValue: string;
}
const NPSScoreChip = styled(Box)(({ theme }) => ({
  height: '56px',
  width: '56px',
  fontSize: '30px',
  lineHeight: '56px',
  backgroundColor: theme.namedColors.performance.backgrounds.default,
  border: `1px solid ${theme.namedColors.performance.visualization.default}`,
  borderRadius: '50%',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const RoadsideEventMeta = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  display: 'inline-flex',
  fontSize: '1rem',
  marginRight: theme.spacing(2),
  '& .MuiSvgIcon-root': {
    fontSize: '.875rem',
    marginRight: '.4rem',
  },
  '& a.visited': {
    color: '#795cb2',
  },
  '& a:hover': {
    color: '#0b57d0',
    textDecoration: 'underline',
  },
  '& a': {
    color: '#0b57d0',
  },
}));

const getFeedbackScoreChip = (score: number | string | null, theme: Theme) => {
  if (score === null || score === undefined || score === '') {
    return (
      <NPSScoreChip data-testid="nps-score-chip">
        <RemoveIcon />
      </NPSScoreChip>
    );
  }

  const colors = theme.namedColors.performance;
  let chipBorder = colors.visualization.poor;
  let chipBackground = colors.backgrounds.poor;
  if (score > 7) {
    chipBorder = colors.visualization.good;
    chipBackground = colors.backgrounds.good;
  } else if (score <= 8 && score > 6) {
    chipBorder = colors.visualization.neutral;
    chipBackground = colors.backgrounds.neutral;
  }

  return (
    <NPSScoreChip
      data-testid="nps-score-chip"
      sx={{
        backgroundColor: chipBackground,
        borderColor: chipBorder,
      }}
    >
      {score}
    </NPSScoreChip>
  );
};

const WinbackStatusChip = styled(Box)(() => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '30px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '4px 12px',
  width: '185px',
}));

const getWinbackStatusChip = (status: string | null, theme: Theme) => {
  if (status === null || status === undefined || status === '') {
    return <></>;
  }
  const colors = theme.namedColors.winback;
  let chipBorder = '';
  let chipBackground = '';
  if (status === 'Pending') {
    chipBorder = colors.visualization.pending;
    chipBackground = colors.backgrounds.pending;
  } else if (status === 'Attempted') {
    chipBorder = colors.visualization.attempted;
    chipBackground = colors.backgrounds.attempted;
  } else if (status === 'Escalated') {
    chipBorder = colors.visualization.escalated;
    chipBackground = colors.backgrounds.escalated;
  } else if (status === 'Successful') {
    chipBorder = colors.visualization.successful;
    chipBackground = colors.backgrounds.successful;
  }

  return (
    <WinbackStatusChip
      data-testid="nps-score-chip"
      sx={{
        backgroundColor: chipBackground,
        borderColor: chipBorder,
      }}
    >
      <Typography
        component="h3"
        variant="h6"
        sx={{
          color: theme.namedColors.utility.black,
          fontSize: '1em',
          fontWeight: 'normal',
        }}
      >
        Winback {status}
      </Typography>
    </WinbackStatusChip>
  );
};

const SurveyFeedbackItem = ({
  data: unsafeData,
  enableWinback,
  enableGoogleReviewRequest,
  showGoogleReviewRequest,
  sendRequestFor,
}: SurveyFeedbackItemProps) => {
  const theme = useTheme();
  const data: RoadsideEventDisplay = useMemo(() => {
    return {
      surveyResponseDateEasternDisplay: formatMonthDate(
        unsafeData.surveyResponseDateEastern
      ),
      telValue: 'tel:' + unsafeData.callbackNumber,
      callbackNumberDisplay: formatPhoneNumber(unsafeData.callbackNumber) || '',
      feedbackScore: getFeedbackScoreChip(unsafeData.npsResponse, theme),
      winbackStatusDisplay: getWinbackStatusChip(
        unsafeData.winbackStatus,
        theme
      ),
      ...unsafeData,
    };
  }, [unsafeData]);

  const surveyData = {
    firstName: data.callerFirstName,
    lastName: data.callerLastName,
    callbackNumber: data.callbackNumberDisplay,
    location: `${data.disablementCity} ${data.disablementState}`,
    showBetaNotification: data.showBetaNotification,
    clientName: data.clientName,
    jobId: data.jobId,
    googleReviewRequestStatus: data.googleReviewRequestStatus,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const telClick = (event: any) => {
    event.target.classList.add('visited');
  };

  return (
    <>
      <Box sx={{ display: 'flex', my: theme.spacing(2) }}>
        <Box sx={{ width: '56px', mr: theme.spacing(3) }}>
          {data.feedbackScore}
        </Box>
        <Box sx={{ flex: '1 1 0' }}>
          <Typography
            component="h3"
            variant="h6"
            sx={{ color: theme.namedColors.utility.black }}
          >
            {data.callerFirstName} {data.callerLastName}
          </Typography>
          <RoadsideEventMeta>
            <CalendarTodayIcon />
            {data.surveyResponseDateEasternDisplay}
          </RoadsideEventMeta>
          {data.disablementCity && data.disablementState && (
            <RoadsideEventMeta data-testid="roadside-city-state">
              <LocationOnIcon />
              {data.disablementCity}, {data.disablementState}
            </RoadsideEventMeta>
          )}
          <RoadsideEventMeta>
            <PhoneIcon />
            <a href={data.telValue} onClick={telClick}>
              {' '}
              {data.callbackNumberDisplay}{' '}
            </a>
          </RoadsideEventMeta>
        </Box>
        {enableWinback && <Box>{data.winbackStatusDisplay}</Box>}
      </Box>
      <Box sx={{ mb: theme.spacing(1) }}>
        <Typography variant="body2">
          <ReadMore charCount={500}>
            {data.surveyComments || 'Survey comments not submitted.'}
          </ReadMore>
        </Typography>
      </Box>
      {enableGoogleReviewRequest && data.googleReviewRequestStatus && (
        <GoogleReviewRequest
          surveyData={surveyData}
          showGoogleReviewRequest={showGoogleReviewRequest}
          sendRequestFor={sendRequestFor}
        />
      )}
      <Divider />
    </>
  );
};

export default SurveyFeedbackItem;
