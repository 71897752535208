import { ThemeOptions } from '@mui/material/styles';

/**
 * Base theme settings Primarily sets typography and spacing.
 *
 * @type {*} */
export const baseThemeOptions: ThemeOptions = {
  palette: {},
  typography: {
    h1: {
      fontSize: '2.25rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.375rem',
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '.875rem',
      fontWeight: 400,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: '1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100vmax',
          textTransform: 'none',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {},
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          paddingTop: '.5rem',
          paddingBottom: '.5rem',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          height: '32px',
          lineHeight: '32px',
          padding: '6px 16px',
          '&, &.MuiToggleButtonGroup-grouped, &.MuiToggleButtonGroup-grouped:not(:last-of-type), &.MuiToggleButtonGroup-grouped:not(:first-of-type)':
            {
              borderRadius: '1rem',
            },
        },
      },
    },
  },
};
