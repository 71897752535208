import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState } from 'react';
import { useSettings } from 'hooks/useSettings';
import { useClientCodeHistory } from 'hooks/useClientCodeHistory';

const ClientDropDown = () => {
  const { clientSettings } = useSettings();
  const [clientCodeHistory, setClientCodeHistory] = useClientCodeHistory();
  const [selectedClient, setSelectedClient] = useState(clientCodeHistory);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleClientChange = (event: SelectChangeEvent) => {
    const code = event.target.value;
    setSelectedClient(code);
    setClientCodeHistory(code);
    window.location.href = '/' + code;
  };

  return (
    <Select
      labelId="client-select-label"
      data-testid="client-select"
      id="client-select"
      value={selectedClient}
      sx={{ width: '237px' }}
      onChange={handleClientChange}
    >
      <MenuItem key="Select-Client" value="AGR">
        Select Client
      </MenuItem>
      {clientSettings.map((client) => (
        <MenuItem key={client.clientCode} value={client.clientCode}>
          {client.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export { ClientDropDown };
