import { useAuthentication } from './useAuthentication';
import { useAgentIdHistory } from './useAgentIdHistory';

const useInitialAgentId = () => {
  const { auth } = useAuthentication();
  const [history] = useAgentIdHistory();
  const agencyIds = auth.getAgentIds();
  if (agencyIds.length > 0) {
    if (agencyIds.indexOf(history[0]) > -1) {
      return history[0];
    }
    return agencyIds[0];
  }

  return history[0] || null;
};

export { useInitialAgentId };
