import React, { useState } from 'react';
import { Metric, NpsCategory } from '@types';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { useTheme, Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Collapse from '@mui/material/Collapse';

import MetricGroup from '../MetricGroup';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsEventOptions,
} from 'constants/GoogleAnalytics';
type PerformanceMetricsProps = React.PropsWithChildren<
  | {
      collapsible?: false;
      loading: boolean;
      npsCategory?: NpsCategory;
      sendEventToGA?: (
        event_type: GoogleAnalyticsEventType,
        data: GoogleAnalyticsEventOptions
      ) => void;
    }
  | {
      collapsible: true;
      loading: boolean;
      mainMetric: React.ReactElement;
      metrics: Metric[];
      storageKey: string;
      npsCategory?: NpsCategory;
      sendEventToGA?: (
        event_type: GoogleAnalyticsEventType,
        data: GoogleAnalyticsEventOptions
      ) => void;
    }
>;

const ExpandedTitleBar = (props: {
  theme: Theme;
  setIsCollapsed: (isCollapased: boolean) => void;
  collapsible?: boolean;
  logEventToGA: () => void;
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '12px 24px',
          width: '100%',
          border: '1px solid #DADCE0',
          borderRadius: '16px 16px 0px 0px',
          borderBottom: '0px',
          backgroundColor: props.theme.namedColors.utility.white,
        }}
      >
        <Typography variant="h4">Performance Metrics</Typography>
        <IconButton
          data-testid="expand-less"
          sx={{ visibility: props.collapsible ? 'visible' : 'hidden' }}
          onClick={() => {
            props.setIsCollapsed(true);
            props.logEventToGA();
          }}
        >
          <ExpandLessIcon />
        </IconButton>
      </Box>
      <Divider
        sx={{
          mr: theme.spacing(3),
          ml: theme.spacing(3),
          mt: '-1px',
        }}
      />
    </>
  );
};

const ExpandedBody = (props: {
  theme: Theme;
  loading: boolean;
  children: React.ReactNode;
  isMobile: boolean;
}) => {
  return (
    <Box
      sx={{
        flexWrap: props.isMobile ? 'wrap' : 'nowrap',
        justifyContent: props.loading ? 'space-around' : 'inherit',
        width: '100%',
        padding: '24px 16px',
        backgroundColor: props.theme.namedColors.utility.white,
        border: '1px solid #DADCE0',
        borderRadius: '0px 0px 16px 16px',
        borderTop: '0px',
        display: 'flex',
        flexDirection: 'row',
        rowGap: '2%',
      }}
    >
      {props.loading ? (
        <CircularProgress size="75px" sx={{ margin: '20px' }} />
      ) : (
        React.Children.map(props.children, (child) => <>{child}</>)
      )}
    </Box>
  );
};

const CollapsedView = (props: {
  theme: Theme;
  mainMetric: React.ReactElement;
  metrics: Metric[];
  loading: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
  logEventToGA: () => void;
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '24px',
      width: '100%',
      border: '1px solid #DADCE0',
      borderRadius: '16px',
      backgroundColor: props.theme.namedColors.utility.white,
    }}
  >
    <Box>
      <Typography variant="h4">Performance Metrics</Typography>
      <Typography color={props.theme.namedColors.brand.copyLight}>
        Your Policyholders
      </Typography>
    </Box>
    {props.loading ? (
      <CircularProgress />
    ) : (
      <>
        {props.mainMetric}
        <MetricGroup metrics={props.metrics} />
      </>
    )}
    <IconButton
      data-testid="expand-more"
      onClick={() => {
        props.setIsCollapsed(false);
        props.logEventToGA();
      }}
    >
      <ExpandMoreIcon />
    </IconButton>
  </Box>
);

const PerformanceMetrics = (props: PerformanceMetricsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('lg'));
  const isCollapsedKey = props.collapsible
    ? `performance-metrics-collapsed-${props.storageKey}`
    : '';
  const storedIsCollapsed =
    props.collapsible && localStorage[isCollapsedKey] === 'true';

  // simple state to manage if the view should be collapsed or open
  const [isCollapsed, setIsCollapsed] = useState(storedIsCollapsed || false);

  if (props.collapsible) {
    localStorage[isCollapsedKey] = isCollapsed;
  }

  // state used to manage the timing of the rendered component swap
  // and transtion
  const [shouldRenderCollapsedView, setShouldRenderCollapsedView] =
    useState(isCollapsed);
  const logEventToGA = () => {
    if (props.sendEventToGA) {
      props.sendEventToGA(GoogleAnalyticsEventType.SelectContent, {
        content_type: 'Performance Metrics',
        item_id: props.npsCategory ? props.npsCategory : 'all',
      });
    }
  };

  return (
    <Box sx={{ display: 'inline-block', minWidth: '100%' }}>
      <>
        {props.collapsible && shouldRenderCollapsedView ? (
          <Collapse
            data-testid="collapsibleView"
            in={isCollapsed}
            collapsedSize={16}
            sx={{ borderRadius: '16px' }}
            onTransitionEnd={() =>
              !isCollapsed && setShouldRenderCollapsedView(false)
            }
          >
            <CollapsedView
              theme={theme}
              loading={props.loading}
              setIsCollapsed={setIsCollapsed}
              mainMetric={props.mainMetric}
              metrics={props.metrics}
              logEventToGA={logEventToGA}
            />
          </Collapse>
        ) : (
          <Collapse
            data-testid="nonCollapsibleView"
            in={!isCollapsed}
            collapsedSize={16}
            sx={{ borderRadius: '16px' }}
            onTransitionEnd={() =>
              isCollapsed && setShouldRenderCollapsedView(true)
            }
          >
            <ExpandedTitleBar
              theme={theme}
              setIsCollapsed={setIsCollapsed}
              collapsible={props.collapsible}
              logEventToGA={logEventToGA}
            />
            <ExpandedBody
              theme={theme}
              isMobile={isMobile}
              loading={props.loading}
              children={props.children}
            />
          </Collapse>
        )}
      </>
    </Box>
  );
};

export default PerformanceMetrics;
