import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useAuthentication } from 'hooks/useAuthentication';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsPageLocation,
  GoogleAnalyticsPageTitle,
  GoogleAnalyticsContentGroup,
} from 'constants/GoogleAnalytics';
import { Navigate } from 'react-router-dom';
import { useSettings } from 'hooks/useSettings';
import Settings from 'views/Settings';

/**
 *Client Admin View
 *
 * @return {*}
 */
const ClientAdmin = () => {
  const theme = useTheme();
  const { auth } = useAuthentication();
  const { sendGAEvent } = useGoogleAnalytics();
  const { getClientConfigByCode } = useSettings();

  useEffect(() => {
    sendGAEvent(GoogleAnalyticsEventType.PageView, {
      content_group: GoogleAnalyticsContentGroup.Settings,
      page_location: GoogleAnalyticsPageLocation.Settings,
      page_title: GoogleAnalyticsPageTitle.Settings,
    });
  }, []);

  if (!auth.isClientAdmin()) {
    return (
      <div data-testid="navigate-element">
        <Navigate to="/unauthorized" replace />
      </div>
    );
  }

  return (
    <Box data-testid="client-admin-container">
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          px: 3,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(1),
          }}
        >
          <Typography variant="h3">Settings</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(1),
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mb: theme.spacing(1),
              mt: theme.spacing(1),
            }}
          >
            Configure the Roadside AID dashboard by enabling or disabling
            specific functionality below.
          </Typography>
        </Box>
        <Settings clientConfig={getClientConfigByCode(auth.getClientCode())} />
      </Box>
    </Box>
  );
};

export default ClientAdmin;
