import { useTheme, SxProps, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React from 'react';
import { InfoTooltip } from 'components/Tooltips';

interface MetricCardProps {
  title: string;
  subtitle?: React.ReactElement;
  subtitleStyle?: SxProps<Theme>;
  titleTooltipContent?:
    | string
    | React.ReactFragment
    | React.ReactChild
    | React.ReactPortal;
  noBorder?: boolean;
  alignLeft?: boolean;
  loading: boolean;
}

const MetricCard = (props: React.PropsWithChildren<MetricCardProps>) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.namedColors.utility.white,
        px: theme.spacing(2),
        border: !props.noBorder
          ? `1px solid ${theme.namedColors.light[900]}`
          : '',
        borderRadius: '20px',
        margin: '0 15px 20px 0',
        padding: '16px 16px 10px 16px',
        flex: '1.2',
        textAlign: props.alignLeft ? 'start' : 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          gap: '0.3rem',
        }}
      >
        <Typography
          sx={{ display: 'inline-block', fontSize: '0.9rem', fontWeight: 500 }}
        >
          {props.title}
        </Typography>
        {props.titleTooltipContent && (
          <InfoTooltip title={props.titleTooltipContent}></InfoTooltip>
        )}
      </Box>
      <Box sx={{ height: '1.25em', ...props.subtitleStyle }}>
        {props.subtitle}
      </Box>
      <Box sx={{ padding: '0', display: 'inline-block' }}>{props.children}</Box>
    </Box>
  );
};

export default MetricCard;
