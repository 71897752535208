import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { UserData } from '@types';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import './hubspot.css';
import { useAuthentication } from 'hooks/useAuthentication';
import { useClientCode } from 'hooks/useClientCode';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';

interface HubspotContactFormProps {
  region: string;
  portalId: string;
  formId: string;
  formSubmitted?: (status: boolean) => void;
}

const NoDataBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

/**
 * Container for async Hubspot support form
 *
 * Code pulled from stack overflow: https://stackoverflow.com/questions/69317628/how-to-insert-a-hubspot-form-in-a-react-js-app
 *
 * @param {HubspotContactFormProps} props
 * @return {*}
 */
const HubspotContactForm = (props: HubspotContactFormProps) => {
  const { auth } = useAuthentication();
  const clientCode = useClientCode();
  const { defaultAgentId } = useDefaultAgentId();
  const userData: UserData = {
    firstName: auth.getFirstName(),
    lastName: auth.getLastName(),
    email: auth.getEmail(),
    officeCode: defaultAgentId,
    clientCode: clientCode,
  };

  const { region, portalId, formId } = props;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/shell.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: region,
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm',
          onBeforeFormInit: function () {
            // add if any action required
          },
          onFormReady: function () {
            const email = document.getElementsByName('email')[0];
            if (email) {
              email.setAttribute('value', userData.email);
              email.dispatchEvent(new Event('input', { bubbles: true }));
            }
            const firstName = document.getElementsByName('firstname')[0];
            const lastName = document.getElementsByName('lastname')[0];
            if (!lastName) {
              firstName.setAttribute(
                'value',
                userData.firstName + ' ' + userData.lastName
              );
              firstName.dispatchEvent(new Event('input', { bubbles: true }));
            } else {
              firstName.setAttribute('value', userData.firstName);
              firstName.dispatchEvent(new Event('input', { bubbles: true }));
              lastName.setAttribute('value', userData.lastName);
              lastName.dispatchEvent(new Event('input', { bubbles: true }));
            }
            const officeCode =
              document.getElementsByName('TICKET.agent_office_code')[0] ||
              document.getElementsByName('agent_office_code')[0];
            if (officeCode && defaultAgentId) {
              officeCode.setAttribute('value', defaultAgentId);
              officeCode.dispatchEvent(new Event('input', { bubbles: true }));
            }
            const raid_client_id =
              document.getElementsByName('raid_client_id')[0];
            if (raid_client_id && userData.clientCode) {
              raid_client_id.setAttribute('value', userData.clientCode);
              raid_client_id.dispatchEvent(
                new Event('input', { bubbles: true })
              );
            }
            setLoading(false);
          },
          onFormSubmit: function () {
            // add  action required before submitting the form
          },
          onFormSubmitted: function () {
            // add  action required after submitting form
            if (props.formSubmitted) props.formSubmitted(true);
          },
        });
      }
    });
  }, [defaultAgentId]);

  return (
    <>
      {loading && (
        <NoDataBox data-testid="hubspot-loading-container">
          <CircularProgress size="50px" />
        </NoDataBox>
      )}
      <div data-testid="hubspot-form" style={{ margin: '0 auto' }}>
        <div id="hubspotForm"></div>
      </div>
    </>
  );
};

export default HubspotContactForm;
