import { ReactElement, useEffect } from 'react';
import { useSettings } from 'hooks/useSettings';
import { useLoadScript } from 'hooks/useLoadScript';
import { useAuthentication } from 'hooks/useAuthentication';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import { useGetUserSettings } from 'api/user-settings';
import { useClientSettings } from 'api/client-settings';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Navigate } from 'react-router-dom';
import { useInitialAgentId } from 'hooks/useInitialAgentId';

/**
 * Utility component to restrict user access based on settings in session
 *
 * @param {{ children: ReactElement }} { children }
 * @return {*}
 */
const RequireSettings = ({ children }: { children: ReactElement }) => {
  const { auth } = useAuthentication();
  const initialAgentId = useInitialAgentId();
  const { setDefaultAgentId } = useDefaultAgentId();
  const id = auth.hasAllReadAccess() ? auth.getName() : initialAgentId;

  const {
    data: settingsClient,
    refetch: refetchClientSettings,
    status: clientSettingsStatus,
  } = useClientSettings(id, auth.getClientCode());

  const { data: settingsAgent, refetch: refetchSettingsAgent } =
    useGetUserSettings(id, auth.getEmail());
  const { setAgentSettings, agentSettings, clientSettings, setClientSettings } =
    useSettings();

  useLoadScript();

  useEffect(() => {
    if (!agentSettings) refetchSettingsAgent();
    if (!clientSettings.length) refetchClientSettings();
  }, []);

  useEffect(() => {
    if (settingsClient) {
      setClientSettings(settingsClient);
    }
  }, [settingsClient]);

  useEffect(() => {
    if (settingsAgent) setAgentSettings(settingsAgent);
  }, [settingsAgent]);

  useEffect(() => {
    setDefaultAgentId(initialAgentId);
  }, [initialAgentId]);

  if (!clientSettings.length || clientSettingsStatus == 'loading')
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress size="75px" />
      </Box>
    );

  if (clientSettingsStatus == 'error') {
    return (
      <Navigate
        to="/error"
        state={{ from: location }}
        replace
        data-testid="navigate-element"
      />
    );
  }

  return children;
};

export default RequireSettings;
