import axios from 'axios';
import { useConfig } from 'hooks/useConfig';
import { useQuery } from 'react-query';
import { UserMetrics } from '@types';

export const useUserMetrics = (userId: string, enabled = false) => {
  const { api } = useConfig();
  return useQuery(
    `userMetrics`,
    () => {
      return axios
        .get<UserMetrics>(`${api.baseUrl}/${userId}/users/user-metrics`, {
          params: { agentOfficeCode: userId },
        })
        .then((res) => res.data);
    },
    { enabled: enabled }
  );
};
