import { zonedTimeToUtc } from 'date-fns-tz';

/**
 * Gets date X number of days from current date
 *
 * @export
 * @param {number} days
 * @return {*}
 */
export function getDateFromToday(days: number) {
  return getDateFromDate(days, new Date());
}

/**
 * Gets date X number of days
 *
 * @export
 * @param {number} days
 * @param {Date} refDate
 * @return {*}
 */
export function getDateFromDate(days: number, refDate: Date) {
  return new Date(refDate.setDate(refDate.getDate() + days));
}

/**
 * Formats date to display time only.
 *
 * @param {Date} d
 * @return {*}
 */
function formatTime(d: Date) {
  return d
    .toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replace(' ', '') // Removes gap between minutes and am/pm
    .toLowerCase();
}

/**
 * Formats Date and Time for consistent display.
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}
 */
export function formatDate(date: Date | string) {
  const d = date instanceof Date ? date : new Date(date);

  const formattedDate = d.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
  const formattedTime = formatTime(d);

  const formattedTimeZone = d
    .toLocaleTimeString('en-US', {
      timeZoneName: 'short',
    })
    .split(' ')[2];

  return `${formattedDate} ${formattedTime} ${formattedTimeZone}`;
}

/**
 * Formats date to month + day
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}
 */
export function formatMonthDate(date: Date | string) {
  const d = date instanceof Date ? date : new Date(date);
  const month = d.toLocaleString('default', { month: 'long' });
  const formattedDate = d.toLocaleString('en-US', {
    day: '2-digit',
  });

  return `${month} ${formattedDate}`;
}

/**
 * Gets UTC time zone converted date.
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}
 */
export function convertUTCDateToLocalTime(date: Date | string) {
  return formatTime(convertUTCDateToLocal(date));
}
/**
 * Gets UTC time zone converted date.
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}
 */
export function convertUTCDateToLocalDate(date: Date | string) {
  return formatDate(convertUTCDateToLocal(date));
}

export function convertUTCDateToLocal(date: Date | string) {
  // String manipulation to push
  const d: string = date instanceof Date ? date.toISOString() : date;
  return zonedTimeToUtc(d.replace('Z', ''), 'UTC');
}

/**
 * Sets passed in date to beginning of day.
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}
 */
export const getStartOfDate = (date: Date = new Date()): Date => {
  return new Date(date.setHours(0, 0, 0, 0));
};

/**
 * Sets passed in date to end of day.
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}
 */
export const getEndOfDate = (date: Date = new Date()): Date => {
  return new Date(date.setHours(23, 59, 59, 0));
};

/**
 * get hours Between current Date and the provided data in UTC
 *
 * @export
 * @param {(Date | string)} dateThenUTC
 * @return {*}
 */

export const gethoursBetweenDates = (dateThenUTC: string | Date): number => {
  const then = convertUTCDateToLocal(dateThenUTC);
  const now = new Date();
  const msBetweenDates = Math.abs(then.getTime() - now.getTime());
  // 👇️ convert ms to hours                  min  sec   ms
  const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
  return hoursBetweenDates;
};

/**
 * get time diffre
 * @export
 * @param {(Date | string)} firstdate
 * @param {(Date | string)} seconddate
 * @return {*}
 */
export function getMinsBetweenDates(
  firstdate: string | Date,
  seconddate: string | Date
): number {
  const date1 = convertUTCDateToLocal(firstdate);
  const date2 = convertUTCDateToLocal(seconddate);
  const msBetweenDates = Math.abs(date1.getTime() - date2.getTime());
  // 👇️ convert ms to mins                  min  sec   ms
  const minsBetweenDates = Math.floor(msBetweenDates / 60000);
  return minsBetweenDates;
}

/**
 * Formats Date and Time for consistent display.
 *
 * @export
 * @param {(Date | string)} date
 * @return {*}
 */
export function formatDateRange(date: Date[] | string[]) {
  const d1 = date[0] instanceof Date ? date[0] : new Date(date[0]);
  const d2 = date[1] instanceof Date ? date[1] : new Date(date[1]);

  const formattedStartDate = d1.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
  const formattedEndDate = d2.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });

  return [`${formattedStartDate}`, `${formattedEndDate}`];
}

/* Returns if today's dates is in between 2 dates.
 *
 * @export
 * @param {(Date | string)} dateFrom
 * @param {(Date | string)} dateTo
 * @return {*}
 */
export function checkDateInBetween(
  dateFrom: string | Date,
  dateTo: string | Date
) {
  const from = new Date(dateFrom);
  const to = new Date(dateTo);
  const today = new Date();
  if (today.getTime() <= to.getTime() && today.getTime() >= from.getTime()) {
    return true;
  } else {
    return false;
  }
}
