import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import { useLocation } from 'react-router-dom';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
// Hooks
import { useAuthentication } from 'hooks/useAuthentication';
import { useSettings } from 'hooks/useSettings';
import { SettingFeature } from 'constants/SettingFeature';
import AgeroLogo from 'theme/AgeroLogo';
import { useClientCode } from 'hooks/useClientCode';

const RouterLink = (layout: string) => {
  const { pathname } = useLocation();
  const { enableFeature, getClientConfigByCode } = useSettings();
  const { auth } = useAuthentication();
  const defaultClientCode = useClientCode();
  const enableAgentSettings =
    auth.isAgent() && enableFeature(SettingFeature.googleReviewRequest);

  const enableSettings = auth.isClientAdmin();

  const clientSettings = getClientConfigByCode(defaultClientCode, false);

  if (layout === 'admin')
    return {
      isAdmin: true,
      topLinks: [
        {
          icon: <ListAltIcon />,
          isActive:
            pathname === '/admin/dashboard' || pathname === '/admin/config',
          link: '/admin/dashboard',
          text: 'Configure',
        },
      ],
      bottomLinks: [],
      clientLogo: <AgeroLogo />,
      clientTheme: '',
      clientCode: defaultClientCode,
      referSPUrl: '',
      helpUrl: '',
    };
  else
    return {
      isAdmin: false,
      topLinks: [
        {
          icon: <FormatListBulletedIcon />,
          isActive: pathname === '/' + defaultClientCode || pathname === '/',
          link: '/' + defaultClientCode,
          text: 'Insights',
        },
      ],
      bottomLinks: [
        ...(enableAgentSettings
          ? [
              {
                icon: <SettingsIcon />,
                isActive: pathname === '/user-settings',
                link: '/user-settings',
                text: 'Settings',
              },
            ]
          : []),
        ...(enableSettings
          ? [
              {
                icon: <SettingsIcon />,
                isActive: pathname === '/settings',
                link: '/settings',
                text: 'Settings',
              },
            ]
          : []),
      ],
      clientLogo: clientSettings ? (
        <img src={clientSettings.logo} />
      ) : (
        <AgeroLogo />
      ),
      clientTheme: clientSettings?.theme || '',
      clientCode: defaultClientCode,
      helpUrl: clientSettings?.helpUrl || '',
      referSPUrl: clientSettings?.referSPUrl || '',
    };
};

export { RouterLink };
