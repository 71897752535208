import { useAuthentication } from './useAuthentication';
import { useClientCodeHistory } from './useClientCodeHistory';

const useClientCode = () => {
  const { auth } = useAuthentication();
  const [history] = useClientCodeHistory();

  const clientCode =
    auth.getClientCode() == 'AGR' ? history : auth.getClientCode();

  return clientCode;
};

export { useClientCode };
