/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { LogAction, LogLevel } from 'constants/Logger';

const errorLogStorageKey = 'errorlog';

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, err: Error };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, err: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    const log = {
      logAction: LogAction.RUNTIMEERROR,
      logLevel: LogLevel.ERROR,
      logMessage: 'Application RunTime error',
      logDescription: error.toString(),
      additionalInfo: errorInfo,
    };
    localStorage.setItem(errorLogStorageKey, JSON.stringify(log));
  }
  render() {
    if (this.state.hasError) {
      window.location.replace('/error');
      return null;
    }
    return this.props.children;
  }
}
