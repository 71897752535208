import { Theme } from '@mui/material/styles';

export const getNPSDetails = (nps: number | null, theme: Theme) => {
  let text: string;
  let performanceText: string;
  let color: string;

  if (nps === null) {
    text = '';
    performanceText = '';
    color = theme.namedColors.performance.text.neutral;
  } else if (nps <= 0) {
    text = 'NPS';
    performanceText = ' NEEDS IMPROVEMENT';
    color = theme.namedColors.performance.text.poor;
  } else if (nps <= 50) {
    text = 'NPS is';
    performanceText = ' GOOD';
    color = theme.namedColors.performance.text.neutral;
  } else {
    text = 'NPS is';
    performanceText = ' GREAT';
    color = theme.namedColors.performance.text.good;
  }

  return {
    text,
    performanceText,
    color,
  };
};
