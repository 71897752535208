export enum LogAction {
  UNKNOWNERROR = 'UNKNOWN-ERROR',
  AGENTNOTFOUND = 'AGENT-OFFICE-CODE-NOT-FOUND',
  SAVEUSERLOGIN = 'SAVE-USER-LOGIN',
  GETAGENTS = 'GET-AGENTS',
  GETROADSIDEEVENTS = 'GET-ROADSIDE-EVENTS',
  GETPERFORMANCEMETRICS = 'GET-PERFORMANCE-METRICS',
  LOGIN = 'LOGIN',
  DEFAULTAPIERROR = 'API-FAILED',
  LOGOUT = 'LOGOUT',
  RUNTIMEERROR = 'RUNTIME-ERROR',
  VIMEOPLAYER = 'VIMEO-PLAYER',
  GENERAL_REPORTING = 'GENERAL-REPORTING',
  LOGIN_REPORTING = 'LOGIN-REPORTING',
  APISUCCESS = 'API-SUCCESS',
}

export enum LogLevel {
  INFO = 'INFO',
  DEBUG = 'DEBUG',
  WARN = 'WARN',
  ERROR = 'ERROR',
}
