import React, { useContext, useEffect, useState } from 'react';
import { useInitialAgentId } from './useInitialAgentId';

export interface AgentIdContextProps {
  setDefaultAgentId: React.Dispatch<React.SetStateAction<string | null>>;
  defaultAgentId: string | null;
}

export const AgentIdContext = React.createContext<AgentIdContextProps | null>(
  null
);

export const AgentIdProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const initialAgentId = useInitialAgentId();
  const [loading, setLoading] = useState<boolean>(true);
  const [defaultAgentId, setDefaultAgentId] = useState<string | null>(
    initialAgentId
  );

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <AgentIdContext.Provider
      value={{
        defaultAgentId,
        setDefaultAgentId,
      }}
    >
      {children}
    </AgentIdContext.Provider>
  );
};

export function useDefaultAgentId(): AgentIdContextProps {
  const ga = useContext(AgentIdContext);
  return ga ? ga : assertNever();
}

function assertNever(): never {
  throw Error('Cannot use useAgentId outside of AgentIdProvider');
}
