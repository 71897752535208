import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { CodeAuth } from '../services/authentication';
import { useConfig } from './useConfig';
import { useClientCodeHistory } from './useClientCodeHistory';

export interface AuthContextProps {
  auth: CodeAuth;
}

export const AuthContext = React.createContext<AuthContextProps | null>(null);

export const AuthenticationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [authProps, setAuthProps] = useState<AuthContextProps | null>(null);
  const [loading, setLoading] = useState(true);
  const config = useConfig();
  const [clientCodeHistory] = useClientCodeHistory();

  useEffect(() => {
    axios.interceptors.request.use((request) => {
      if (request.headers && !request.url?.includes('/logger/log')) {
        const clientCode =
          auth.getClientCode() == 'AGR'
            ? clientCodeHistory
            : auth.getClientCode();

        request.headers.Authorization = `Bearer ${auth.getRawIdToken()}`;
        const params = request.params;
        if (!request?.params?.clientCode)
          request.params = {
            clientCode: clientCode,
            ...params,
          };
      }

      return request;
    });
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.url?.includes('/logger/log')
        ) {
          originalRequest._retry = true;

          const refreshTokenCallback = () => {
            axios.defaults.headers.common['Authorization'] =
              'Bearer ' + auth.getRawIdToken();

            Promise.resolve(axios(originalRequest));
          };

          auth.refreshToken(refreshTokenCallback);
        }
        return Promise.reject(error);
      }
    );

    const loginCallback = () => {
      console.log('user logged in successfully');
      localStorage.setItem('User_LoggedIn', 'true');
      if (auth.isSystemAdmin() && window.location.href.includes('admin'))
        localStorage.setItem('aid_systemAdmin', 'true');
    };

    const refreshCallback = () => {
      console.log('user session refreshed successfully');
    };

    const errorCallback = (error: string) => {
      console.error('authentication error occurred', { error });
    };

    const signOutCallback = () => {
      localStorage.removeItem('aid_systemAdmin');
    };

    const auth = new CodeAuth({
      loginCallback,
      refreshCallback,
      errorCallback,
      signOutCallback,
      ...config.auth,
    });

    setAuthProps({
      auth,
    });

    setLoading(false);
  }, [config]);

  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider value={authProps}>{children}</AuthContext.Provider>
  );
};

export function useAuthentication(): AuthContextProps {
  const auth = useContext(AuthContext);
  return auth ? auth : assertNever();
}

function assertNever(): never {
  throw Error('Cannot use useAuthentication outside of AuthenticationProvider');
}
