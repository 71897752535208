import axios from 'axios';
import { useConfig } from 'hooks/useConfig';
import { useQuery } from 'react-query';
import { Log } from '@types';

const REACT_APP_ENV = 'LOCAL';
const corelationID =
  new Date().valueOf() + '' + Math.floor(100000 + Math.random() * 900000);
localStorage.setItem('corelationId', corelationID);

export const useLogger = (data: Log | null, enabled = false) => {
  const log = {
    applicationName: 'ACTIONS-INSIGHTS-DASHBOARD-APP-UI',
    environment: process.env.REACT_APP_ENV || REACT_APP_ENV,
    corelationID: data?.corelationId ? data?.corelationId : corelationID,
    ...data,
    deviceInfo: {
      platform: navigator.platform,
      userAgent: navigator.userAgent,
    },
  };
  const { api } = useConfig();
  return useQuery(
    `log`,
    () => {
      return axios.post<Log>(`${api.baseUrl}/logger/log`, { ...log });
    },
    { enabled: enabled && data !== null }
  );
};
