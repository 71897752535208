/**
 * Formats phone number for consistency
 *
 * @export
 * @param {(string | number)} phoneNumberString
 * @return {*}
 */
export function formatPhoneNumber(phoneNumberString: string | number) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return null;
}

/**
 * 10 digit phone number for consistency
 *
 * @export
 * @param {(string | number)} phoneNumberString
 * @return {*}
 */
export function unFormatedPhoneNumber(phoneNumberString: string | number) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  if (cleaned) {
    return cleaned;
  }
  return '';
}
