import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthenticationProvider } from 'hooks/useAuthentication';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ConfigProvider } from 'hooks/useConfig';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import GeneratedRouter from 'constants/GeneratedRouter';
import CustomThemeProvider from 'theme/CustomThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { GoogleAnalyticsProvider } from 'hooks/useGoogleAnalytics';
import { PopupProvider } from 'hooks/usePopup';
import { LoggingProvider } from 'hooks/useLogging';
import { SettingsProvider } from 'hooks/useSettings';
import { AgentIdProvider } from 'hooks/useDefaultAgentId';
const queryClient = new QueryClient();

function App() {
  return (
    <BrowserRouter>
      <CustomThemeProvider>
        <CssBaseline />
        <ConfigProvider>
          <PopupProvider>
            <QueryClientProvider client={queryClient}>
              <AuthenticationProvider>
                <AgentIdProvider>
                  <LoggingProvider>
                    <SettingsProvider>
                      <GoogleAnalyticsProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <GeneratedRouter />
                          <ReactQueryDevtools initialIsOpen={false} />
                        </LocalizationProvider>
                      </GoogleAnalyticsProvider>
                    </SettingsProvider>
                  </LoggingProvider>
                </AgentIdProvider>
              </AuthenticationProvider>
            </QueryClientProvider>
          </PopupProvider>
        </ConfigProvider>
      </CustomThemeProvider>
    </BrowserRouter>
  );
}

export default App;
