import NpsCategoryBreakoutCard from '../NpsCategoryBreakoutCard';
import {
  NpsBreakoutPercentages,
  TimeSeries,
  ServiceType,
  AtaSplitUp,
  TimeSeriesPoint,
} from '@types';
import TimeSeriesCard from '../TimeSeriesCard';
import BreakdownByServiceType from '../BreakdownByServiceType';
import { Box, Typography } from '@mui/material';
import PerformanceMetricsOuterLayer from '../PerformanceMetricsOuterLayer';
import MetricCard from '../MetricCard';

const PerformanceBreakdownItem = (
  props:
    | {
        timeSeries: TimeSeries;
        serviceType: ServiceType[];
        category: string;
        count: number;
        headerTitle: string;
        policyHoldersMetrics?: {
          npsBreakoutPercentages: NpsBreakoutPercentages;
        };
        stateMetrics?: { npsBreakoutPercentages: NpsBreakoutPercentages };
        ataSplitUp?: AtaSplitUp;
        completePercent?: number;
        complaintPercent?: number;
        loading?: false;
        enableStateLevelNps: boolean;
      }
    | {
        loading: true;
        headerTitle: string;
      }
) => {
  const getTimeSeriesData = (data: TimeSeries, category: string) => {
    return data.map((d: TimeSeriesPoint) => {
      if (category === 'ATA') {
        return {
          ...d,
          policyHolders: d.policyHolders > 120 ? 1.2 : d.policyHolders / 100,
          state: d.state > 120 ? 1.2 : d.state / 100,
          date: new Date(`${d.date}T00:00`),
          count: d.policyHolders
            ? `${Math.round(d.policyHolders)} min ATA`
            : `0 min ATA`,
        };
      } else if (category === 'NPS') {
        return {
          ...d,
          policyHolders: d.policyHolders / 100,
          state: d.state / 100,
          date: new Date(`${d.date}T00:00`),
          count: d.policyHolders
            ? `${Math.round(d.policyHolders)} NPS`
            : '0 NPS',
        };
      } else {
        return {
          ...d,
          date: new Date(`${d.date}T00:00`),
          count: d.policyHolders
            ? `${Math.round(d.policyHolders * 100)}% Digital Request`
            : '0% Digital Request',
        };
      }
    });
  };
  const loading = props.loading || false;

  return loading ? (
    <PerformanceMetricsOuterLayer loading={true} title={props.headerTitle} />
  ) : (
    <PerformanceMetricsOuterLayer
      loading={loading}
      metricType={{
        count:
          props.category === 'SB'
            ? Math.round(props.count * 100)
            : Math.round(props.count),
        title: props.headerTitle,
        type: props.category,
      }}
    >
      {props.category === 'NPS' ? (
        <>
          {props.policyHoldersMetrics && props.stateMetrics && (
            <NpsCategoryBreakoutCard
              loading={loading}
              percentages={{
                policyHolders:
                  props.policyHoldersMetrics.npsBreakoutPercentages,
                state: props.stateMetrics.npsBreakoutPercentages,
              }}
              enableStateLevelNps={props.enableStateLevelNps}
            />
          )}

          <BreakdownByServiceType
            title="NPS By Service Type"
            loading={loading}
            percentages={props.serviceType}
            type="value"
            category={props.category}
          />
          <TimeSeriesCard
            loading={loading}
            color={'#000'}
            data={getTimeSeriesData(props.timeSeries, props.category)}
            range={{ min: -1, max: 1 }}
            title={`NPS Over Time`}
            category={props.category}
            pointsColor={'#000'}
            enableStateLevelNps={props.enableStateLevelNps}
          />
        </>
      ) : props.category === 'ATA' ? (
        <>
          <BreakdownByServiceType
            title="Percent of Arrival Time"
            loading={loading}
            type="percent"
            percentages={props.ataSplitUp ? props.ataSplitUp : []}
          />
          <BreakdownByServiceType
            title="ATA By Service Type"
            loading={loading}
            percentages={props.serviceType}
            type="time"
            category={props.category}
          />
          <TimeSeriesCard
            loading={loading}
            color={'#000'}
            data={getTimeSeriesData(props.timeSeries, props.category)}
            range={{ min: 0, max: 1.2 }}
            title={`ATA Over Time`}
            category={props.category}
            pointsColor={'#000'}
            enableStateLevelNps={props.enableStateLevelNps}
          />
        </>
      ) : (
        <>
          <MetricCard
            title=""
            loading={false}
            subtitleStyle={{ display: 'none' }}
            noBorder
            alignLeft
          >
            <div
              style={{
                minWidth: '400px',
                padding: '0 20px',
              }}
            >
              <Box
                sx={{
                  border: '1px solid #B1B1B1',
                  width: '100%',
                  padding: '20px',
                  borderRadius: '20px',
                  marginBottom: '30px',
                }}
              >
                <Typography
                  sx={{
                    display: 'inline-block',
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    paddingBottom: '15px',
                  }}
                >
                  COMPLETION RATE
                </Typography>
                <Typography variant="subtitle2">
                  {props.completePercent
                    ? `${Math.round(props.completePercent * 100)}%`
                    : '0%'}
                </Typography>
              </Box>
              <Box
                sx={{
                  border: '1px solid #B1B1B1',
                  width: '100%',
                  padding: '20px',
                  borderRadius: '20px',
                }}
              >
                <Typography
                  sx={{
                    display: 'inline-block',
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    paddingBottom: '15px',
                  }}
                >
                  COMPLAINT RATE
                </Typography>
                <Typography variant="subtitle2">
                  {props.complaintPercent
                    ? `${(props.complaintPercent * 100).toFixed(2)}%`
                    : '0%'}
                </Typography>
              </Box>
            </div>
          </MetricCard>
          <BreakdownByServiceType
            title="Volume Breakdown"
            loading={loading}
            percentages={props.serviceType}
            type="percent"
          />
          <TimeSeriesCard
            loading={loading}
            color={'#000'}
            data={getTimeSeriesData(props.timeSeries, props.category)}
            range={{ min: 0, max: 1 }}
            title={`Digital Intake Over Time`}
            pointsColor={'#000'}
            enableStateLevelNps={props.enableStateLevelNps}
          />
        </>
      )}
    </PerformanceMetricsOuterLayer>
  );
};

export { PerformanceBreakdownItem };
