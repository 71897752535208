import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
interface EmptyPaneprops {
  title: string;
  desc: string;
}
const EmptyPane = (props: EmptyPaneprops) => (
  <Box
    data-testid="empty-pane"
    sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}
  >
    <Box sx={{ textAlign: 'center' }}>
      <VisibilityOffIcon sx={{ fontSize: '4rem', mb: '38px' }} />
      <Typography variant="h3" sx={{ mb: '16px' }}>
        {props.title}
      </Typography>
      <Typography> {props.desc}</Typography>
    </Box>
  </Box>
);

export default EmptyPane;
