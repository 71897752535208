import { Cards } from 'components/Cards';
import {
  SupportCardContent,
  SupportCardHeader,
  SupportCardButton,
} from './ReferStyled';

const ReferProvider = ({ referSPUrl }: { referSPUrl: string }) => {
  const referAProvider = () => {
    window.open(referSPUrl, '_blank', 'noopener');
  };

  return (
    <>
      <Cards
        sx={{
          mb: 1,
          mt: 1,
          width: '100%',
          minHeight: 'auto',
          overflow: 'visible',
        }}
      >
        <Cards.Content sx={{ minHeight: '108px' }}>
          <SupportCardHeader>
            <span style={{ marginRight: '8px' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M15 10h5.39l-3.33-4H15v4m2.5 8.5c.39 0 .74-.13 1.04-.43c.3-.3.46-.65.46-1.07c0-.39-.16-.74-.46-1.04c-.3-.3-.65-.46-1.04-.46c-.42 0-.77.16-1.07.46c-.3.3-.43.65-.43 1.04c0 .42.13.77.43 1.07c.3.3.65.43 1.07.43M6 18.5c.44 0 .8-.13 1.08-.43s.42-.65.42-1.07c0-.39-.14-.74-.42-1.04c-.28-.3-.64-.46-1.08-.46c-.44 0-.8.16-1.08.46c-.28.3-.42.65-.42 1.04c0 .42.14.77.42 1.07c.28.3.64.43 1.08.43M18 4l5 6v7h-2.5c0 .83-.31 1.53-.91 2.13c-.59.59-1.29.87-2.09.87c-.83 0-1.53-.28-2.12-.87c-.6-.6-.88-1.3-.88-2.13H9c0 .83-.3 1.53-.89 2.13c-.61.59-1.3.87-2.11.87c-.81 0-1.5-.28-2.11-.87C3.3 18.53 3 17.83 3 17H1v-4h8.19L3 8.11V11H1V5h1l11 6.06V4h5Z"
                />
              </svg>
            </span>
            Refer a Provider
          </SupportCardHeader>
          <SupportCardContent>
            Nominate your trusted local service provider to be added to Agero’s
            service provider network.
          </SupportCardContent>
        </Cards.Content>
        <Cards.Actions>
          <SupportCardButton onClick={referAProvider}>
            Get Started
          </SupportCardButton>
        </Cards.Actions>
      </Cards>
    </>
  );
};
export default ReferProvider;
