import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import { FC, useEffect, useState } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import {
  getDateFromDate,
  getStartOfDate,
  getEndOfDate,
} from '../../../../utilities/date-utils';
import { FilterValues, InsightGroups } from 'views/Dashboard/Dashboard';
import { useAuthentication } from 'hooks/useAuthentication';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import AgentTextField from '../AgentTextField';
import AgentDropDown from '../AgentDropDown';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiTextFieldProps } from '@mui/x-date-pickers/internals';
import isValid from 'date-fns/isValid';
import {
  GoogleAnalyticsEventOptions,
  GoogleAnalyticsEventType,
} from 'constants/GoogleAnalytics';
import PolicyTextField from '../PolicyTextField';
import { Log } from '@types';
import ClientDropDown from '../ClientDropDown';

const DEFAULT_START_DATE_NUMBER = 90;
const DEFAULT_START_DATE_STRING = DEFAULT_START_DATE_NUMBER.toString();

const DatePickerInput = styled(TextField)(() => ({
  height: '2rem',
}));

export interface DashboardWithFiltersProps {
  enablePolicySearch: boolean;
  onFilterChange: (filters: FilterValues, filter_type?: string) => void;
  filterValues?: FilterValues;
  sendEventToGA?: (
    event_type: GoogleAnalyticsEventType,
    data: GoogleAnalyticsEventOptions
  ) => void;
  logger: (data: Log) => void;
  agentIdentifier: string;
}

const DashboardWithFilters: FC<DashboardWithFiltersProps> = ({
  enablePolicySearch,
  onFilterChange,
  filterValues = {
    insightGroup: '',
    dateDropDownValue: DEFAULT_START_DATE_STRING,
    startDate: getDateFromDate(-DEFAULT_START_DATE_NUMBER, getStartOfDate()),
    endDate: getStartOfDate(),
    agentId: '',
    policyId: '',
  },
  sendEventToGA,
  logger,
  agentIdentifier,
}) => {
  const [startDate, setStartDate] = useState(filterValues.dateDropDownValue);
  const [startDateRange, setStartDateRange] = useState<Date | null>(null);
  const [endDateRange, setEndDateRange] = useState<Date | null>(null);
  const [showDateRange, setShowDateRange] = useState(false);
  const { auth } = useAuthentication();
  const { defaultAgentId } = useDefaultAgentId();

  const handleInsightGroup = (
    event: React.MouseEvent<HTMLElement>,
    newGroup: InsightGroups
  ) => {
    if (newGroup !== null) {
      const newFilters = {
        ...filterValues,
        insightGroup: newGroup,
      };

      onFilterChange(newFilters, 'npsCategorySearch');

      if (sendEventToGA) {
        sendEventToGA(GoogleAnalyticsEventType.SelectContent, {
          content_type: 'NPS Category',
          item_id: newGroup ? newGroup : 'All',
        });
      }
    }
  };

  const handleStartDateChange = (event: SelectChangeEvent) => {
    const dateValue = event.target.value as string;
    setStartDate(dateValue);

    const parsedValue = parseInt(dateValue);
    if (!isNaN(parsedValue)) {
      const newStartDate = getDateFromDate(-parsedValue, getStartOfDate());
      const newFilters = {
        ...filterValues,
        startDate: newStartDate,
        dateDropDownValue: dateValue,
      };
      onFilterChange(newFilters);
    } else {
      setShowDateRange(true);
    }
  };
  const resetDateRange = () => {
    setShowDateRange(false);
    setStartDate(DEFAULT_START_DATE_STRING);

    const newStartDate = getDateFromDate(
      -DEFAULT_START_DATE_NUMBER,
      getStartOfDate()
    );
    const newFilters = {
      ...filterValues,
      startDate: newStartDate,
      endDate: getStartOfDate(),
      dateDropDownValue: DEFAULT_START_DATE_STRING,
    };
    setStartDateRange(null);
    setEndDateRange(null);
    onFilterChange(newFilters);
  };
  const handleStartDateRangeChange = (newValue: string | null) => {
    if (newValue !== null) {
      setStartDateRange(getStartOfDate(new Date(newValue)));
    }
  };
  const handleEndDateRangeChange = (newValue: string | null) => {
    if (newValue !== null) {
      setEndDateRange(getStartOfDate(new Date(newValue)));
    }
  };

  useEffect(() => {
    if (
      startDateRange !== null &&
      document.querySelector('.start-date .Mui-error') == null &&
      isValid(startDateRange) &&
      endDateRange !== null &&
      document.querySelector('.end-date .Mui-error') == null &&
      isValid(endDateRange)
    ) {
      const newFilters = {
        ...filterValues,
        startDate: startDateRange,
        endDate: getEndOfDate(endDateRange),
      };
      onFilterChange(newFilters);
    }
  }, [startDateRange, endDateRange]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <ToggleButtonGroup
          value={filterValues.insightGroup}
          exclusive
          onChange={handleInsightGroup}
        >
          <ToggleButton value="">All</ToggleButton>
          <ToggleButton value="promoter">Promoters</ToggleButton>
          <ToggleButton value="neutral">Neutrals</ToggleButton>
          <ToggleButton value="detractor">Detractors</ToggleButton>
          <ToggleButton value="complaint">Complaints</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Box>
        <Box sx={{ display: 'flex', gap: '8px 8px', flexWrap: 'wrap' }}>
          {auth.hasAllReadAccess() && enablePolicySearch && (
            <PolicyTextField
              onPolicySelected={(id: string) =>
                onFilterChange(
                  { ...filterValues, policyId: id },
                  'policySearch'
                )
              }
            />
          )}
          {auth.hasAllReadAccess() ? (
            <AgentTextField
              onAgentSelected={(id: string) =>
                onFilterChange(
                  { ...filterValues, agentId: id },
                  'agentIdSearch'
                )
              }
              agentId={filterValues.agentId}
              logger={logger}
              agentIdentifier={agentIdentifier}
            />
          ) : (
            defaultAgentId && (
              <AgentDropDown
                onAgentSelected={(id: string) =>
                  onFilterChange({ ...filterValues, agentId: id })
                }
                validAgentIds={auth.getAgentIds()}
                defaultAgentId={defaultAgentId}
              />
            )
          )}
          {auth.getDomain() == 'agero.com' && <ClientDropDown />}
          {showDateRange ? (
            <>
              <DatePicker
                className="start-date"
                label="Start Date"
                value={startDateRange}
                disableFuture
                onChange={handleStartDateRangeChange}
                renderInput={(params: MuiTextFieldProps) => {
                  if (params && params.inputProps) {
                    params.inputProps['data-testid'] = 'start-date-range';
                  }
                  return (
                    <DatePickerInput
                      size="small"
                      {...params}
                      autoComplete="off"
                    />
                  );
                }}
              />
              <DatePicker
                className="end-date"
                label="End Date"
                value={endDateRange}
                onChange={handleEndDateRangeChange}
                disableFuture
                renderInput={(params: MuiTextFieldProps) => {
                  if (params && params.inputProps) {
                    params.inputProps['data-testid'] = 'end-date-range';
                  }
                  return (
                    <DatePickerInput
                      size="small"
                      {...params}
                      autoComplete="off"
                    />
                  );
                }}
              />
              <IconButton
                onClick={resetDateRange}
                data-testid="date-range-close"
              >
                <CloseIcon />
              </IconButton>
            </>
          ) : (
            <Select
              labelId="date-select-label"
              data-testid="date-select"
              id="date-select"
              value={startDate}
              sx={{ width: '237px' }}
              onChange={handleStartDateChange}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarTodayIcon sx={{ height: '1rem', width: '1rem' }} />
                </InputAdornment>
              }
            >
              <MenuItem value={'7'}>Last 7 days</MenuItem>
              <MenuItem value={'14'}>Last 14 days</MenuItem>
              <MenuItem value={'30'}>Last 30 days</MenuItem>
              <MenuItem value={'60'}>Last 60 days</MenuItem>
              <MenuItem value={'90'}>Last 90 days (Default)</MenuItem>
              <MenuItem value={'Custom'}>Custom</MenuItem>
            </Select>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardWithFilters;
