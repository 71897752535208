import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

interface ReadMoreProps {
  children: string;
  charCount: number;
}

/**
 * Read More component. Truncates text and will show a read more/show less button at the end of it.
 *
 * @param {ReadMoreProps} { children, charCount }
 * @return {*}
 */
const ReadMore = ({ children, charCount }: ReadMoreProps) => {
  const text = children;

  const theme = useTheme();
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <>
      {isReadMore ? text.slice(0, charCount) : text}
      {text.length > charCount && (
        <Typography
          data-testid="read-more-label"
          component="span"
          onClick={toggleReadMore}
          sx={{
            cursor: 'pointer',
            color: theme.namedColors.primary.default,
            fontWeight: 500,
          }}
        >
          {isReadMore ? '...Read more' : ' Show less'}
        </Typography>
      )}
    </>
  );
};

export default ReadMore;
