import ReactDOM from 'react-dom';
import { useContext, useEffect, useCallback } from 'react';
import { Blocker, History } from 'history';
import {
  UNSAFE_NavigationContext as NavigationContext,
  Navigator,
} from 'react-router-dom';

import CustomThemeProvider from 'theme/CustomThemeProvider';

import { Popup } from 'components/Popup';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 *
 * @param  blocker
 * @param  when
 *
 */
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as Navigator & Pick<History, 'block'>).block(
      (tx) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            // Automatically unblock the transition so it can play all the way
            // through before retrying it.
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      }
    );

    return unblock;
  }, [navigator, blocker, when]);
}
/**
 * Prompts the user with an Alert before they leave the current screen.
 *
 * @param  message
 * @param  when
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function usePrompt(message: string, when = true, callback: any) {
  const blocker = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (tx: any) => {
      const element = document.createElement('div');
      element.setAttribute('id', 'prompt-dialog-container');
      element.setAttribute('aria-hidden', 'true');

      const closePrompt = (state: boolean) => {
        if (element) {
          ReactDOM.unmountComponentAtNode(element);
        }
        if (!state) {
          callback();
          document.body.removeChild(element);
        } else {
          tx.retry();
        }
      };

      document.body.appendChild(element);
      ReactDOM.render(
        <CustomThemeProvider>
          <Popup
            maxWidth="xs"
            id="exit-dialog"
            data-testid="exit-dialog"
            open={!!message}
            title="Save Changes?"
            closeIcon={false}
            btnCancel="Discard Changes"
            btnConfirm="Save Changes"
            onDismiss={() => closePrompt(true)}
            onConfirm={() => closePrompt(false)}
          >
            {message}
          </Popup>
        </CustomThemeProvider>,
        element
      );
    },
    [message]
  );

  useBlocker(blocker, when);
}
