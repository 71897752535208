import { useQuery } from 'react-query';
import axios from 'axios';
import { useConfig } from 'hooks/useConfig';
import {
  PerFormanceMetricsBreakout,
  RoadsideEvent,
  RoadsidePerFormanceMetrics,
} from '@types';
import { NoAgentIdSelectedError } from 'errors';

export const useRoadsideEventsKeys = {
  byAgentId: (agentId: string | null, eventKeys: string[]) => [
    `${agentId}-${eventKeys.join('-')}`,
  ],
};

export const useRoadsideEventsByAgentId = (
  agentId: string | null,
  startDate: Date,
  endDate: Date,
  npsCategory: string,
  isExternalComplaint?: boolean,
  policyNumber?: string | null,
  enabled = false
) => {
  const { api } = useConfig();

  const eventKeys = [];

  const startDateString = startDate.toISOString();
  eventKeys.push(startDateString);

  const endDateString = endDate.toISOString();
  eventKeys.push(endDateString);

  if (npsCategory !== '') {
    eventKeys.push(npsCategory);
  }

  const externalComplaintString =
    isExternalComplaint === true || isExternalComplaint === false
      ? isExternalComplaint.toString()
      : '';

  if (externalComplaintString !== '') {
    eventKeys.push(externalComplaintString);
  }
  const policyNumberString =
    policyNumber !== undefined && policyNumber !== null && policyNumber !== ''
      ? policyNumber.toString()
      : '';

  if (policyNumberString !== '') {
    eventKeys.push(policyNumberString);
  }

  let apiUrl: string;
  if (!agentId) {
    apiUrl = `${api.baseUrl}/agents/roadside-events`;
  } else {
    apiUrl = `${api.baseUrl}/agents/${agentId}/roadside-events`;
  }

  const results = useQuery(
    useRoadsideEventsKeys.byAgentId(agentId, eventKeys),
    () => {
      return axios
        .get<RoadsideEvent[]>(apiUrl, {
          params: {
            startDate: startDateString,
            endDate: endDateString,
            ...(npsCategory && { npsCategory }),
            ...(policyNumberString && { policyNumber: policyNumberString }),
            ...(externalComplaintString && {
              isExternalComplaint: externalComplaintString,
            }),
          },
        })
        .then((res) => res.data);
    },
    { enabled: enabled }
  );
  if (!agentId) {
    return {
      ...results,
      isError: true,
      error: new NoAgentIdSelectedError(),
    };
  } else {
    return results;
  }
};

export const useRoadsideEventMetrics = (
  agentId: string | null,
  startDate: Date,
  endDate: Date,
  policyNumber?: string | null,
  enabled = false
) => {
  const { api } = useConfig();

  const eventKeys = [];

  const startDateString = startDate.toISOString();
  eventKeys.push(startDateString);

  const endDateString = endDate.toISOString();
  eventKeys.push(endDateString);

  const policyNumberString =
    policyNumber !== undefined && policyNumber !== null && policyNumber !== ''
      ? policyNumber.toString()
      : '';

  if (policyNumberString !== '') {
    eventKeys.push(policyNumberString);
  }

  const results = useQuery(
    `${useRoadsideEventsKeys.byAgentId(agentId, eventKeys)}-metrics`,
    () => {
      return axios
        .get<RoadsidePerFormanceMetrics>(
          `${api.baseUrl}/agents/${agentId}/roadside-events/metrics`,
          {
            params: {
              startDate: startDateString,
              endDate: endDateString,
              ...(policyNumberString && { policyNumber: policyNumberString }),
            },
          }
        )
        .then((res) => res.data);
    },
    { enabled: enabled }
  );

  if (!agentId) {
    return {
      ...results,
      isError: true,
      error: new NoAgentIdSelectedError(),
    };
  } else {
    return results;
  }
};

export const usePerformanceMetrics = (
  agentId: string | null,
  startDate: Date,
  endDate: Date,
  enabled = false
) => {
  const { api } = useConfig();

  const eventKeys = [];

  const startDateString = startDate.toISOString();
  eventKeys.push(startDateString);

  const endDateString = endDate.toISOString();
  eventKeys.push(endDateString);

  const results = useQuery(
    `${useRoadsideEventsKeys.byAgentId(agentId, eventKeys)}-metrics-breakdown`,
    () => {
      return axios
        .get<PerFormanceMetricsBreakout>(
          `${api.baseUrl}/agents/${agentId}/roadside-events/metrics-breakdown`,
          {
            params: {
              startDate: startDateString,
              endDate: endDateString,
            },
          }
        )
        .then((res) => res.data);
    },
    { enabled: enabled }
  );

  if (!agentId) {
    return {
      ...results,
      isError: true,
      error: new NoAgentIdSelectedError(),
    };
  } else {
    return results;
  }
};
