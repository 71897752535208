import HubspotContactForm from 'components/HubspotContactForm/HubspotContactForm';
import { Popup } from 'components/Popup';
import { useState } from 'react';

/**
 * HubspotCta component for use on the main dashboard cards.
 *
 * @return {*}
 */
const HubspotCta = () => {
  const [openPopup, setPopupOpen] = useState<boolean>(true);
  window.history.replaceState(null, '', '/');

  return (
    <Popup
      maxWidth="md"
      id="hubspotcta-dialog"
      data-testid="hubspotcta-dialog"
      open={openPopup}
      title=""
      closeIcon={true}
      onDismiss={() => setPopupOpen(false)}
    >
      <HubspotContactForm
        region="na1"
        portalId={`${process.env.REACT_APP_HUBSPOT_PORTAL_ID || '2932510'}`}
        formId={'7a949dbd-3d7b-418a-bac1-3c7ff7e17060'}
      />
    </Popup>
  );
};
export default HubspotCta;
