import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

interface CardsProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
}
/**
 * Generic Card component for use on the main dashboard cards.
 *
 * @param {CardsProps} { children, sx }
 * @return {*}
 */
const Cards = ({ children, sx }: CardsProps) => {
  return (
    <Card
      variant="outlined"
      sx={{
        minHeight: '255px',
        display: 'flex',
        flexFlow: 'wrap',
        width: '236px',
        padding: '16px',
        margin: '0 16px 16px 0',
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};

Cards.Content = ({ children, sx }: CardsProps) => (
  <CardContent sx={{ minHeight: '190px', padding: '8px', ...sx }}>
    {children}
  </CardContent>
);
Cards.Actions = ({ children }: CardsProps) => (
  <CardActions sx={{ padding: '8px 8px 0px' }}>{children}</CardActions>
);

export default Cards;
