import { useState, useEffect } from 'react';
import { tryJSONParse } from 'utilities/json';

/**
 * React hook for storing and retrieving client-code-history
 *
 * @return {*}
 */
const useClientCodeHistory = () => {
  const clientCodeStorageKey = 'client-code-history';
  const [history, setHistory] = useState<string>(
    tryJSONParse(localStorage[clientCodeStorageKey]) || 'AGR'
  );

  useEffect(() => {
    localStorage[clientCodeStorageKey] = JSON.stringify(history);
  }, [history]);

  return [history, setHistory] as const;
};

export { useClientCodeHistory };
