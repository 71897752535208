import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { Gauge } from '../Gauge';
import MetricCard from '../MetricCard';
import { NpsMetricsPerformance } from '@types';
import { getNPSDetails } from '../../utilities/GetNPSDetails';
import InsightsIcon from '@mui/icons-material/Insights';

interface NpsMetricCardProps {
  loading: boolean;
  title: string;
  showSubtitle?: boolean;
  npsMetrics: NpsMetricsPerformance;
  enableStateLevelNps: boolean;
}

const getSubtitle = (nps: number | null, theme: Theme) => {
  const { text, performanceText, color } = getNPSDetails(nps, theme);

  return (
    <>
      <Typography
        color={theme.namedColors.brand.copyLight}
        display="inline"
        variant="body2"
      >
        {text}
      </Typography>
      <Typography color={color} display="inline" variant="body2">
        <strong data-testid="performance-text">{performanceText}</strong>
      </Typography>
    </>
  );
};

const NpsMetricCard = ({
  loading,
  title,
  npsMetrics,
  showSubtitle,
  enableStateLevelNps,
}: NpsMetricCardProps) => {
  const theme = useTheme();
  return (
    <MetricCard
      loading={loading}
      title={title}
      subtitle={showSubtitle ? getSubtitle(npsMetrics.nps, theme) : undefined}
      titleTooltipContent={
        <Typography>
          <strong>Net Promoter Score</strong> (NPS) is a customer loyalty and
          satisfaction measurement taken from asking customers how likely are
          they to recommend their roadside service to friends or colleagues (on
          a scale of 0-10).
        </Typography>
      }
    >
      <Box sx={{ padding: '0px 20px' }}>
        <Gauge
          min={-100}
          max={100}
          value={npsMetrics.nps}
          stateValue={npsMetrics.stateNps}
          label={getSubtitle(npsMetrics.nps, theme)}
          sectionColors={[
            theme.namedColors.performance.visualization.poor,
            theme.namedColors.performance.visualization.poor,
            theme.namedColors.performance.visualization.neutral,
            theme.namedColors.performance.visualization.good,
          ]}
          kpiColors={[
            theme.namedColors.performance.text.poor,
            theme.namedColors.performance.text.poor,
            theme.namedColors.performance.text.neutral,
            theme.namedColors.performance.text.good,
          ]}
          enableStateLevelNps={enableStateLevelNps}
        />
      </Box>
      <Box sx={{ marginTop: '20px', display: 'flex' }}>
        <InsightsIcon />
        <Typography sx={{ marginLeft: '10px', textAlign: 'left' }}>
          {npsMetrics.promoterPercentage}% of your policyholders are loyal
          customers who will recommend your products or services.
        </Typography>
      </Box>
    </MetricCard>
  );
};

export default NpsMetricCard;
