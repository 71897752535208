/**
 * Events associated with google analytics.
 *
 * @export
 * @enum {number}
 */
export enum GoogleAnalyticsEventType {
  PageView = 'page_view',
  SelectContent = 'select_content',
  Video = 'video',
}

/**
 * Event options associated with google analytics.
 *
 * @export
 * @interface
 */

export interface GoogleAnalyticsEventOptions {
  page_location?: GoogleAnalyticsPageLocation;
  debug_mode?: boolean;
  user_id?: string;
  page_title?: GoogleAnalyticsPageTitle;
  user_properties?: GoogleAnalyticsUserProperties;
  content_type?: string;
  item_id?: string;
  content_group?: string;
  video_action?: GoogleAnalyticsVideoAction;
  video_url?: string;
  video_percent?: number;
  video_title?: string;
  successful_review_sms?: number;
  unsuccessful_review_sms?: number;
}

/**
 * page_location associated with google analytics page_view.
 *
 * @export
 * @enum {number}
 */
export enum GoogleAnalyticsPageLocation {
  Login = 'page_view_login',
  Insights = 'page_view_insights',
  Settings = 'page_view_settings',
  UserSettings = 'page_view_user_settings',
  Admin = 'page_view_admin',
  ClientConfigure = 'page_view_admin_client_onfiguration',
}

/**
 * page_title associated with google analytics page_view.
 *
 * @export
 * @enum {number}
 */
export enum GoogleAnalyticsPageTitle {
  Login = 'Login',
  Insights = 'Insights',
  Settings = 'Settings',
  UserSettings = 'User Settings',
  Admin = 'Admin',
  ClientConfigure = 'Admin Client Configuration',
}

/**
 *user properties associated with google analytics.
 *
 * @export
 * @interface
 */

export interface GoogleAnalyticsUserProperties {
  user_alias_id?: string;
  user_domain?: string;
  agent_office_code?: string;
  state_code?: string;
  user_type?: string;
}

/**
 *select_content associated with google analytics.
 *
 * @export
 * @interface
 */

export interface GoogleAnalyticsSelectContent {
  content_type?: string;
  item_id?: string;
}

/**
 * content_group associated with google analytics page_view.
 *
 * @export
 * @enum {number}
 */
export enum GoogleAnalyticsContentGroup {
  Login = '/Login',
  Insights = '/Insights',
  Settings = '/Settings',
  UserSettings = '/UserSettings',
  Admin = '/Admin',
  ClientConfigure = '/Admin/ClientConfigure',
}

/**
 * video_action associated with vimeo player.
 *
 * @export
 * @enum {number}
 */
export enum GoogleAnalyticsVideoAction {
  Play = 'play',
  Pause = 'pause',
  Finish = 'finish',
  Progress = 'progress',
}
