import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePerformanceMetrics } from 'api/roadside-events';
import { useTheme } from '@mui/material/styles';
import { NoAgentIdSelectedError } from 'errors';
import { getNPSBreakownAggregations } from 'utilities/data-utils';
import logo from 'assets/images/logo.png';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import PerformanceBreakdownItem from '../PerformanceBreakdownItem';
import { PerFormanceMetricsBreakout } from '@types';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import { getDateFromToday } from 'utilities/date-utils';
import { useSettings } from 'hooks/useSettings';
import { SettingFeature } from 'constants/SettingFeature';
import { useClientCode } from 'hooks/useClientCode';

const getBreakdown = (
  enableStateLevelNps: boolean,
  metricsAreLoading: boolean,
  title: string,
  category: 'NPS' | 'ATA' | 'SB',
  metricsData?: PerFormanceMetricsBreakout
) => {
  if (metricsAreLoading || !metricsData) {
    return <PerformanceBreakdownItem loading headerTitle={title} />;
  }
  const { netPromoterScore, averageArrivalTime, completedEvents } = metricsData;
  const npsSortedServiceType = netPromoterScore.serviceType.sort((a, b) =>
    a.type.toLowerCase().localeCompare(b.type.toLowerCase())
  );
  const ataSortedServiceType = averageArrivalTime.serviceType.sort((a, b) =>
    a.type.toLowerCase().localeCompare(b.type.toLowerCase())
  );
  const ceSortedServiceType = completedEvents.serviceType.sort((a, b) =>
    a.type.toLowerCase().localeCompare(b.type.toLowerCase())
  );

  switch (category) {
    case 'NPS':
      return (
        <PerformanceBreakdownItem
          policyHoldersMetrics={getNPSBreakownAggregations(
            netPromoterScore.surveyFeedback.policyHolder
          )}
          stateMetrics={getNPSBreakownAggregations(
            netPromoterScore.surveyFeedback.state
          )}
          timeSeries={netPromoterScore.timeSeries}
          serviceType={npsSortedServiceType}
          category={category}
          count={netPromoterScore.nps}
          headerTitle={title}
          enableStateLevelNps={enableStateLevelNps}
        />
      );
    case 'ATA':
      return (
        <PerformanceBreakdownItem
          timeSeries={averageArrivalTime.timeSeries}
          serviceType={ataSortedServiceType}
          ataSplitUp={averageArrivalTime.ataSplitup}
          category={category}
          count={averageArrivalTime.ata}
          headerTitle={title}
          enableStateLevelNps={enableStateLevelNps}
        />
      );
    case 'SB':
      return (
        <PerformanceBreakdownItem
          timeSeries={completedEvents.timeSeries}
          serviceType={ceSortedServiceType}
          category={category}
          count={completedEvents.appRequestPercentage}
          complaintPercent={completedEvents.complaintRate}
          completePercent={completedEvents.percentage}
          headerTitle={title}
          enableStateLevelNps={enableStateLevelNps}
        />
      );
  }
};

const PerformanceMetricsBreakdown = (props: {
  showPerformanceBreakDown?: (flag: boolean) => void;
  startDate?: Date;
  endDate?: Date;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enableFeature } = useSettings();
  const { defaultAgentId } = useDefaultAgentId();
  const defaultClientCode = useClientCode();

  const [startDate] = useState<Date>(
    props.startDate ? props.startDate : getDateFromToday(-90)
  );
  const [endDate] = useState<Date>(props.endDate ? props.endDate : new Date());
  if (!defaultAgentId) navigate('/' + defaultClientCode);

  const {
    data: metricsData,
    isLoading: metricsAreLoading,
    isError: metricsDataIsError,
    error: metricsDataError,
    refetch: refetchPerformanceMetrics,
  } = usePerformanceMetrics(defaultAgentId, startDate, endDate);

  const refToTop = useRef<HTMLInputElement>(null);

  useEffect(() => {
    refToTop.current && refToTop.current.scrollIntoView({ behavior: 'smooth' });
  }, [props.endDate]);

  useEffect(() => {
    refetchPerformanceMetrics();
  }, []);

  const gotoInsights = () => {
    if (props.showPerformanceBreakDown) props.showPerformanceBreakDown(false);
    else navigate('/' + defaultClientCode);
  };

  if (
    metricsDataIsError &&
    !(metricsDataError instanceof NoAgentIdSelectedError)
  ) {
    return (
      <div data-testid="navigate-element">
        <Navigate to="/error" replace />
      </div>
    );
  }

  return (
    <Box
      ref={refToTop}
      data-testid="PerformanceMetricsBreakdown"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        px: 3,
        py: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: theme.spacing(2),
        }}
      >
        <Typography
          data-testid="back-to-insights"
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
          onClick={gotoInsights}
        >
          <NavigateBeforeIcon />
          BACK TO CUSTOMER INSIGHTS
        </Typography>
        <Box component="img" src={logo} sx={{ width: '140px' }}></Box>
      </Box>
      <Typography variant="h3">Performance Breakdown </Typography>
      <Box sx={{ margin: '10px 0px' }}>
        {getBreakdown(
          enableFeature(SettingFeature.stateLevelNPS),
          metricsAreLoading,
          'Net Promoter Score',
          'NPS',
          metricsData
        )}
      </Box>
      <Box sx={{ margin: '10px 0px' }}>
        {getBreakdown(
          enableFeature(SettingFeature.stateLevelNPS),
          metricsAreLoading,
          'Actual Arrival Time',
          'ATA',
          metricsData
        )}
      </Box>
      <Box sx={{ margin: '10px 0px', paddingBottom: '20px' }}>
        {getBreakdown(
          enableFeature(SettingFeature.stateLevelNPS),
          metricsAreLoading,
          'Service Breakdown',
          'SB',
          metricsData
        )}
      </Box>
    </Box>
  );
};

export default PerformanceMetricsBreakdown;
