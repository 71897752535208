import { useEffect, useRef, useState } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { useAuthentication } from 'hooks/useAuthentication';
import Typography from '@mui/material/Typography';
import { useClientCode } from 'hooks/useClientCode';

/**
 * Callback page used for logging in.
 *
 * @return {*}
 */
const Callback = () => {
  const { auth } = useAuthentication();
  const [isParsed, setIsParsed] = useState(false);
  const location = useLocation();
  const defaultClientCode = useClientCode();

  const initializedRef = useRef(false);
  useEffect(() => {
    const parseCallback = () => {
      setIsParsed(true);
    };
    if (initializedRef.current === false) {
      initializedRef.current = true;
      auth.parseRedirect(parseCallback);
    }
  }, [auth]);
  if (isParsed) {
    return (
      <Navigate
        to={`/${defaultClientCode}`}
        state={{ from: location }}
        replace
        data-testid="navigate-element"
      />
    );
  }
  return (
    <Box
      data-testid="callback-loading-element"
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h3" sx={{ mb: 3 }}>
        Signing you in
      </Typography>
      <CircularProgress size="75px" />
    </Box>
  );
};

export default Callback;
