import {
  RoadsideEventAggregations,
  NpsCategory,
  NpsMetrics,
  NpsCategoryMetrics,
  ComplaintMetrics,
  RoadsidePerFormanceMetrics,
  NpsMetricsPerformance,
  SurveyFeedback,
  NpsBreakoutPercentages,
} from '@types';
import { Theme } from '@mui/material/styles';

const getNpsMetricsFromAggregations = (
  aggs: RoadsideEventAggregations | null
): NpsMetrics => {
  if (aggs === null) {
    return {
      nps: null,
      eventsCompleted: 0,
      eventsInProgress: 0,
      averageArrivalTime: 0,
      appEngagement: 0,
      npsBreakoutPercentages: {
        promoter: 0,
        neutral: 0,
        detractor: 0,
      },
    };
  }
  return {
    nps: aggs?.nps !== null ? Math.round(aggs?.nps) : null,
    eventsCompleted: aggs?.events.counts.completed,
    eventsInProgress: aggs?.events.counts.inProgress,
    averageArrivalTime: Math.round(aggs?.avgAta),
    appEngagement: aggs?.appEngagementPercentage,
    npsBreakoutPercentages: {
      promoter: Math.round(aggs?.surveyFeedback.percentages.promoter * 100),
      neutral: Math.round(aggs?.surveyFeedback.percentages.neutral * 100),
      detractor: Math.round(aggs?.surveyFeedback.percentages.detractor * 100),
    },
  };
};

const getNpsPerformanceMetricsFromAggregations = (
  aggs: RoadsidePerFormanceMetrics | null
): NpsMetricsPerformance => {
  if (aggs === null) {
    return {
      nps: null,
      stateNps: null,
      eventsCompleted: 0,
      eventsTotal: 0,
      eventsInProgress: 0,
      averageArrivalTime: 0,
      appEngagement: 0,
      promoterPercentage: 0,
      complaintRate: 0,
      vsState: {
        averageArrivalTime: 0,
        appEngagement: 0,
        complaintRate: 0,
      },
    };
  }

  return {
    nps: aggs?.nps !== null ? Math.round(aggs?.nps) : null,
    stateNps: aggs?.state.nps !== null ? Math.round(aggs?.state.nps) : null,
    eventsCompleted: aggs?.events.completed,
    eventsTotal: aggs?.events.total,
    eventsInProgress: aggs?.events.inProgress,
    averageArrivalTime: Math.round(aggs?.avgAta),
    appEngagement: Math.round(aggs?.appRequestPercentage * 100),
    complaintRate: Math.round(aggs?.complaints.rate * 100),
    promoterPercentage: Math.round(
      aggs?.surveyFeedback.percentages.promoter * 100
    ),
    vsState: {
      averageArrivalTime: Math.round(aggs?.avgAtaVsState * 100),
      appEngagement: Math.round(aggs?.appRequestVsState * 100),
      complaintRate: Math.round(aggs?.complaints.rateVsState * 100),
    },
  };
};

const getNpsCategoryMetricsFromAggregations = (
  aggs: RoadsideEventAggregations | null,
  npsCategory: NpsCategory
): NpsCategoryMetrics => {
  if (aggs === null || aggs.events === null) {
    return {
      eventsCompleted: 0,
      eventsInProgress: 0,
      eventsInGroup: 0,
      eventsInGroupPercentage: 0,
      surveyResponsePercentage: 0,
    };
  }
  return {
    eventsCompleted: aggs.events.counts.completed,
    eventsInProgress: aggs.events.counts.inProgress,
    eventsInGroup: aggs.surveyFeedback.counts[npsCategory],
    eventsInGroupPercentage: aggs.surveyFeedback.percentages[npsCategory],
    surveyResponsePercentage: aggs.surveyFeedback.percentages.total,
  };
};

const getNPSBreakownAggregations = (
  aggs: SurveyFeedback | null
): {
  npsBreakoutPercentages: NpsBreakoutPercentages;
} => {
  if (aggs === null) {
    return {
      npsBreakoutPercentages: {
        promoter: 0,
        neutral: 0,
        detractor: 0,
      },
    };
  }
  return {
    npsBreakoutPercentages: {
      promoter: Math.round(aggs?.percentages.promoter * 100),
      neutral: Math.round(aggs?.percentages.neutral * 100),
      detractor: Math.round(aggs?.percentages.detractor * 100),
    },
  };
};

const getComplaintMetricsFromAggregations = (
  aggs: RoadsideEventAggregations | null
): ComplaintMetrics => {
  if (aggs === null || aggs.events === null) {
    return {
      eventsCompleted: 0,
      totalComplaints: 0,
      resolutionsInProgress: 0,
      complaintPercentage: 0,
      resolvedPercentage: 0,
    };
  }
  return {
    eventsCompleted: aggs.events.counts.completed,
    totalComplaints: aggs.complaints.counts.total,
    resolutionsInProgress: aggs.complaints.counts.inProgress,
    complaintPercentage: aggs.complaints.percentages.total,
    resolvedPercentage: aggs.complaints.percentages.resolved,
  };
};

const getNpsBarChartData = (
  theme: Theme,
  npsPercentage: {
    policyHolders: NpsBreakoutPercentages;
    state: NpsBreakoutPercentages;
  },
  enableStateLevelNps: boolean
) => {
  return [
    {
      label: 'Your Promoters',
      value: npsPercentage.policyHolders.promoter,
      color: theme.namedColors.performance.visualization.good,
      valueLabel: `${npsPercentage.policyHolders.promoter}%`,
    },
    ...(enableStateLevelNps
      ? [
          {
            label: 'State Promoters',
            value: npsPercentage.state.promoter,
            color: theme.namedColors.performance.visualization.good,
            valueLabel: `${npsPercentage.state.promoter}%`,
            opacity: 0.35,
          },
        ]
      : []),
    {
      label: 'Your Neutrals',
      value: npsPercentage.policyHolders.neutral,
      color: theme.namedColors.performance.visualization.neutral,
      valueLabel: `${npsPercentage.policyHolders.neutral}%`,
    },
    ...(enableStateLevelNps
      ? [
          {
            label: 'State Neutrals',
            value: npsPercentage.state.neutral,
            color: theme.namedColors.performance.visualization.neutral,
            valueLabel: `${npsPercentage.state.neutral}%`,
            opacity: 0.35,
          },
        ]
      : []),
    {
      label: 'Your Detractors',
      value: npsPercentage.policyHolders.detractor,
      color: theme.namedColors.performance.visualization.poor,
      valueLabel: `${npsPercentage.policyHolders.detractor}%`,
    },
    ...(enableStateLevelNps
      ? [
          {
            label: 'State Detractors',
            value: npsPercentage.state.detractor,
            color: theme.namedColors.performance.visualization.poor,
            valueLabel: `${npsPercentage.state.detractor}%`,
            opacity: 0.35,
          },
        ]
      : []),
  ];
};

export {
  getNpsMetricsFromAggregations,
  getNpsCategoryMetricsFromAggregations,
  getComplaintMetricsFromAggregations,
  getNPSBreakownAggregations,
  getNpsPerformanceMetricsFromAggregations,
  getNpsBarChartData,
};
