import { SxProps, Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
interface TitleProps {
  sx?: SxProps<Theme>;
  children?: ReactNode;
}
/**
 * Generic Title component for use on the main dashboard cards.
 *
 * @param {TitleProps} { children, sx }
 * @return {*}
 */
const Title = ({ children, sx }: TitleProps) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{ px: theme.spacing(3), py: theme.spacing(2), ...sx }}
      component="h2"
      variant="h5"
    >
      {children}
    </Typography>
  );
};
export default Title;
