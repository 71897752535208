import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Button, { ButtonProps } from '@mui/material/Button';

const SupportCardHeaderToStyle = ({ children, ...props }: TypographyProps) => (
  <Typography {...props}>{children}</Typography>
);
export const SupportCardHeader = styled(SupportCardHeaderToStyle)(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '28px',
    display: 'flex',
  })
);
const SupportCardContentToStyle = ({ children, ...props }: TypographyProps) => (
  <Typography {...props}>{children}</Typography>
);
export const SupportCardContent = styled(SupportCardContentToStyle)(() => ({
  fontSize: 14,
}));

const SupportCardButtonToStyle = ({ children, ...props }: ButtonProps) => (
  <Button {...props}>{children}</Button>
);
export const SupportCardButton = styled(SupportCardButtonToStyle)(
  ({ theme }) => ({
    mb: 1,
    width: '150px',
    height: '32px',
    borderRadius: '100px',
    background: theme.namedColors.utility.white,
    color: theme.namedColors.brand.copy,
    borderColor: theme.namedColors.secondary.default,
    borderStyle: 'solid',
    borderWidth: '1px',
    lineHeight: 1,
    '&:hover': {
      background: theme.namedColors.utility.white,
      color: theme.namedColors.brand.copy,
    },
  })
);
