import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthConfig } from 'agero-js-identity';
import { ApiConfig, AppConfig } from '@types';

const NONPROD_CLIENT_ID = '3mcl7nuqslue72p11nhim9bia3';

const NONPROD_API_URL =
  'https://nonprod.api.oauth.agero.com/multi-nonprod/api/v1';

const NONPROD_WEB_DOMAIN =
  'agero-auth-nonprod.auth.us-east-1.amazoncognito.com';

const LOCALHOST_REDIRECT_URI = 'http://localhost:3000/callback';
const LOCALHOST_SIGNOUT_URI = 'http://localhost:3000';

const LOCALHOST_REDIRECT_URI_ADMIN = 'http://localhost:3000/admin/callback';
const LOCALHOST_SIGNOUT_URI_ADMIN = 'http://localhost:3000/admin';

const NONPROD_API_BASE_URL =
  'https://insurance-agent-api.rsa-nonprod.ageroapi.net/dev';

const ACITIVITY_TIMEOUT = 1800;

export interface ConfigContextProps {
  auth: AuthConfig;
  api: ApiConfig;
  app: AppConfig;
}

export const ConfigContext = createContext<ConfigContextProps | null>(null);

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [config, setConfig] = useState<ConfigContextProps | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const isSystemAdmin = window.location.href.includes('admin') ? true : false;

    const pathSignin = isSystemAdmin
      ? process.env.REACT_APP_AUTH_REDIRECT_URI_SIGN_IN_ADMIN ||
        LOCALHOST_REDIRECT_URI_ADMIN
      : process.env.REACT_APP_AUTH_REDIRECT_URI_SIGN_IN ||
        LOCALHOST_REDIRECT_URI;
    const pathSignOut = isSystemAdmin
      ? process.env.REACT_APP_AUTH_REDIRECT_URI_SIGN_OUT_ADMIN ||
        LOCALHOST_SIGNOUT_URI_ADMIN
      : process.env.REACT_APP_AUTH_REDIRECT_URI_SIGN_OUT ||
        LOCALHOST_SIGNOUT_URI;

    const auth: AuthConfig = {
      clientId: process.env.REACT_APP_AUTH_CLIENT_ID || NONPROD_CLIENT_ID,
      apiFullUrl: process.env.REACT_APP_AUTH_API_URL || NONPROD_API_URL,
      appWebDomain:
        process.env.REACT_APP_AUTH_APP_WEB_DOMAIN || NONPROD_WEB_DOMAIN,
      redirectUriSignIn: pathSignin,
      redirectUriSignOut: pathSignOut,
      useMultiTenantApi: true,
      activityTimeout: ACITIVITY_TIMEOUT,
    };

    const api: ApiConfig = {
      baseUrl: process.env.REACT_APP_API_BASE_URL || NONPROD_API_BASE_URL,
    };

    const app: AppConfig = {
      enableDomainSwap:
        process.env.REACT_APP_APP_DOMAIN_SWAP === 'true' || false,
    };

    setConfig({
      auth,
      api,
      app,
    });

    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  if (config === null) {
    return <div>Cannot load configuration!</div>;
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export function useConfig(): ConfigContextProps {
  const config = useContext(ConfigContext);
  return config ? config : assertNever();
}

function assertNever(): never {
  throw new Error('Cannot use useConfig outside of ConfigProvider');
}
