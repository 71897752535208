import React, { FC, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mergeDeepRight from 'ramda.mergedeepright';

import { baseThemeOptions } from './baseThemeOptions';
import getBrandTheme from './baseBrandTheme';

interface ThemeContext {
  currentTheme: string;
  setTheme?: (name: string) => void;
}
export const CustomThemeContext = React.createContext<ThemeContext>({
  currentTheme: 'AidTheme',
});

export interface Props {
  children: React.ReactNode;
  theme?: string;
  themePrimary?: string;
}

const CustomThemeProvider: FC<Props> = (props) => {
  const { children } = props;

  // Read current theme from localStorage or maybe from an api
  const currentTheme =
    props.theme || localStorage.getItem('appTheme') || 'AidTheme';

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(currentTheme);

  // Retrieve the theme object by theme name
  const theme = createTheme(
    mergeDeepRight(baseThemeOptions, getBrandTheme(themeName))
  );

  if (props.themePrimary) {
    theme.namedColors.client = props.themePrimary;
    // theme.namedColors.primary.default = props.themePrimary;
  }

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeName = (name: string) => {
    localStorage.setItem('appTheme', name);
    _setThemeName(name);
  };

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
