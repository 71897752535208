import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import logo from 'assets/images/logo.png';

/**
 * Page Not Found View
 *
 * @return {*}
 */
const PageNotFound = () => {
  return (
    <Box
      sx={{
        height: '100%',
        px: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      data-testid="page-not-found-container"
    >
      <Box
        component="img"
        src={logo}
        sx={{ maxWidth: '400px', width: '100%', px: '1rem' }}
      ></Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '30vh',
          my: 5,
          width: '100%',
        }}
      >
        <svg
          style={{
            width: '100%',
            maxWidth: '750px',
            height: '80%',
            display: 'block',
            margin: '0 auto',
          }}
          viewBox="0 0 741 292"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M206.549 186.794V224.27H1.56181L0 195.968L122.602 3.90374H160.281L119.478 73.7807L49.0017 186.794H206.549ZM171.018 3.90374V288.096H123.969V3.90374H171.018Z"
            fill="#888888"
          />
          <path
            d="M463.232 122.187V168.642C463.232 190.893 461.02 209.891 456.595 225.636C452.3 241.251 446.052 253.939 437.853 263.698C429.654 273.457 419.827 280.614 408.374 285.168C397.051 289.723 384.361 292 370.305 292C359.112 292 348.7 290.569 339.069 287.706C329.568 284.843 320.978 280.354 313.299 274.238C305.62 268.122 299.047 260.25 293.581 250.62C288.245 240.861 284.08 229.215 281.086 215.682C278.223 202.149 276.792 186.469 276.792 168.642V122.187C276.792 99.8057 279.004 80.9376 283.429 65.5829C287.854 50.098 294.167 37.541 302.366 27.9118C310.566 18.1524 320.327 11.0606 331.65 6.63636C343.103 2.21212 355.858 0 369.914 0C381.237 0 391.649 1.43137 401.15 4.29412C410.782 7.02674 419.372 11.3859 426.92 17.3717C434.599 23.3574 441.107 31.1649 446.443 40.7941C451.909 50.2932 456.074 61.8093 458.937 75.3422C461.801 88.7451 463.232 104.36 463.232 122.187ZM416.183 175.278V115.16C416.183 103.84 415.532 93.885 414.231 85.2968C412.929 76.5784 410.977 69.2264 408.374 63.2406C405.901 57.1248 402.777 52.18 399.003 48.4064C395.229 44.5027 390.934 41.705 386.118 40.0134C381.302 38.1916 375.901 37.2808 369.914 37.2808C362.626 37.2808 356.118 38.7121 350.392 41.5749C344.665 44.3075 339.849 48.7317 335.945 54.8476C332.04 60.9635 329.047 69.0312 326.965 79.0508C325.012 88.9403 324.036 100.977 324.036 115.16V175.278C324.036 186.729 324.687 196.814 325.988 205.532C327.29 214.25 329.242 221.733 331.845 227.979C334.448 234.094 337.572 239.169 341.216 243.203C344.99 247.107 349.285 249.97 354.101 251.791C359.047 253.613 364.448 254.524 370.305 254.524C377.723 254.524 384.296 253.093 390.023 250.23C395.749 247.367 400.565 242.813 404.469 236.567C408.374 230.191 411.302 221.928 413.255 211.778C415.207 201.628 416.183 189.462 416.183 175.278Z"
            fill="#888888"
          />
          <path
            d="M741 186.794V224.27H536.013L534.451 195.968L657.053 3.90374H694.731L653.929 73.7807L583.453 186.794H741ZM705.469 3.90374V288.096H658.419V3.90374H705.469Z"
            fill="#888888"
          />
        </svg>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '400px',
        }}
      >
        <Typography variant="h1" sx={{ mb: 2 }}>
          Page not found
        </Typography>
        <Typography variant="body1">
          We can't find the page you're looking for. Try to refresh the page or
          go back to the dashboard.
        </Typography>
      </Box>
      <Link
        to={localStorage.getItem('aid_systemAdmin') ? '/admin/dashboard' : '/'}
      >
        <Button variant="contained" sx={{ py: 1, my: 2 }}>
          Go to Dashboard
        </Button>
      </Link>
    </Box>
  );
};

export default PageNotFound;
