import { SxProps, Theme } from '@mui/material/styles';
import React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

export interface PopupProps {
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
  id: string;
  open: boolean;
  onDismiss?: () => void;
  onConfirm?: () => void;
  title: string;
  btnCancel?: string;
  btnConfirm?: string;
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  closeIcon: boolean;
  subtitle?: string;
  btnConfirmDisabled?: boolean;
  position?: string;
}

/**
 * Popup container .
 *
 * @param {PopupProps} {
 *   children,
 *   sx,
 *   id,
 *   open,
 *   onDismiss,
 *   onConfirm
 *   title,
 *   btnCancel,
 *   btnConfirm,
 *   maxWidth,
 *   closeIcon,
 *   subtitle,
 *   btnConfirmDisabled,
 *   position,
 * }
 * @return {*}
 */
const Popup = (props: PopupProps) => {
  const {
    onDismiss,
    onConfirm,
    open,
    children,
    id,
    title,
    btnCancel,
    btnConfirm,
    maxWidth,
    closeIcon,
    subtitle,
    btnConfirmDisabled,
    position,
    ...other
  } = props;
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby={`${id}-title`}
      open={open}
      className={id}
      id={id}
      {...other}
    >
      <DialogTitle id={`${id}-title`} sx={{ padding: '16px 24px 10px' }}>
        {title && <p style={{ margin: 0 }}>{title}</p>}
        {subtitle && (
          <p style={{ fontSize: '14px', margin: '10px 0' }}>{subtitle}</p>
        )}
        {closeIcon && (
          <CloseIcon
            data-testid={`${id}-cancelIcon`}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '14px',
              cursor: 'pointer',
            }}
            onClick={onDismiss}
          />
        )}
      </DialogTitle>
      {children && (
        <DialogContent sx={{ padding: '10px 24px 10px 32px' }}>
          {children}
        </DialogContent>
      )}
      {(btnConfirm || btnCancel) && (
        <DialogActions
          sx={{
            padding: '10px 24px 21px 24px',
            alignItems: position == 'left' ? 'start' : 'default',
            justifyContent: position == 'left' ? 'start' : 'default',
          }}
        >
          {btnCancel && (
            <Button
              onClick={onDismiss}
              sx={(theme) => ({
                border: '1px solid ' + theme.namedColors.secondary.default,
                padding: '7px 15px',
                color: theme.namedColors.dark[900],
                background: theme.namedColors.utility.white,
              })}
              data-testid={`${id}-cancelButton`}
            >
              {btnCancel}
            </Button>
          )}
          {btnConfirm && (
            <Button
              onClick={onConfirm}
              variant="contained"
              style={{ padding: '7px 15px' }}
              data-testid={`${id}-confirmButton`}
              disabled={btnConfirmDisabled}
            >
              {btnConfirm}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
export default Popup;
