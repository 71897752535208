import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ClientSettings } from '@types';

interface ClientsProps {
  client: ClientSettings;
  clientConfiguration: (code: string) => void;
}
const Clients = (props: ClientsProps) => {
  const theme = useTheme();
  return (
    <Box
      data-testid="client-container"
      key={props.client.clientCode}
      sx={{
        maxWidth: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Button
        sx={{
          background: props.client.theme,
          borderRadius: '16px',
          width: '250px',
          height: '141px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            background: props.client.theme,
          },
        }}
        data-testid="client-btn"
        onClick={() => props.clientConfiguration(props.client.clientCode)}
      >
        <img src={props.client.logo} width="100" height="115" />
      </Button>
      <Typography
        variant="body1"
        sx={{
          mb: theme.spacing(1),
          mt: theme.spacing(1),
          alignSelf: 'flex-start',
          fontWeight: '500',
        }}
      >
        {props.client.name} [{props.client.clientCode}]
      </Typography>
    </Box>
  );
};

export default Clients;
