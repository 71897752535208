import { useMemo } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, Theme, useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { RoadsideEvent } from '@types';
import { Title } from 'components/Title';
import { RoadsideEventComponent } from '../RoadsideEvent/RoadsideEvent';
import { compare } from '../../../../utilities/compare';
import { RoadsideEventStatuses } from 'constants/RoadsideEventStatuses';

export interface RoadSideEventsProps {
  data: RoadsideEvent[];
  isLoading: boolean;
  isError: boolean;
}
const NoDataBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const IN_PROGRESS_EVENTS = [
  RoadsideEventStatuses.ProviderAssigned,
  RoadsideEventStatuses.EnRoute,
  RoadsideEventStatuses.OnScene,
  RoadsideEventStatuses.Towing,
] as string[];

const RoadsideEvents = ({
  data: unsafeData,
  isLoading,
  isError,
}: RoadSideEventsProps) => {
  const data = useMemo(() => {
    const inProgressEvents = unsafeData
      .filter((x) => IN_PROGRESS_EVENTS.indexOf(x.status) > -1)
      .sort((a, b) => compare(a.jobCreatedAtLocal, b.jobCreatedAtLocal, false));

    const terminalEvents = unsafeData
      .filter((x) => IN_PROGRESS_EVENTS.indexOf(x.status) === -1)
      .sort((a, b) => compare(a.jobCreatedAtLocal, b.jobCreatedAtLocal, false));

    return [...inProgressEvents, ...terminalEvents];
  }, [unsafeData]);
  const isSmallScreen = useMediaQuery((mq: Theme) => mq.breakpoints.down('md'));
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: data.length > 0 && !isSmallScreen ? '100%' : 'auto',
        overflow: 'hidden',
      }}
    >
      <Title>Roadside Events</Title>
      <Divider
        sx={{
          mr: theme.spacing(3),
          ml: theme.spacing(3),
        }}
      />
      {!isLoading && !isError && data.length === 0 ? (
        <NoDataBox data-testid="empty-container">
          <Typography component="h3" variant="h6">
            No Roadside Events Found
          </Typography>
        </NoDataBox>
      ) : (
        <>
          {isLoading ? (
            <NoDataBox data-testid="loading-container">
              <Typography gutterBottom>Loading Events</Typography>
              <CircularProgress size="50px" />
            </NoDataBox>
          ) : (
            <>
              {isError ? (
                <NoDataBox data-testid="error-container">
                  <Typography component="h3" variant="h6">
                    Error Loading Events
                  </Typography>
                </NoDataBox>
              ) : (
                <Box
                  sx={{
                    flex: '1 1 0',
                    maxHeight: '100%',
                    minHeight: !isSmallScreen ? '500px' : 'auto',
                    overflowY: !isSmallScreen ? 'auto' : 'visible',
                    margin: '2px', // Offsets the scroll bar
                  }}
                  data-testid="data-container"
                >
                  <Box sx={{ px: theme.spacing(2), py: theme.spacing(1) }}>
                    {data.map((dataItem) => {
                      return (
                        <RoadsideEventComponent
                          key={dataItem.jobId}
                          data={dataItem}
                        ></RoadsideEventComponent>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Paper>
  );
};

export default RoadsideEvents;
