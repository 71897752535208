import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import { useState, useRef, useEffect, useCallback, ChangeEvent } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { Paper } from '@mui/material';
import { usePolicyNoHistory } from 'hooks/usePolicyNoHistory';

interface PolicyTextFieldProps {
  onPolicySelected: (id: string) => void;
}

const PolicyTextField = (props: PolicyTextFieldProps) => {
  const [policyNoHistory, setPolicyNoHistory] = usePolicyNoHistory();

  const [policyId, setPolicyId] = useState('');
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [focusedPolicyIndex, setFocusedPolicyIndex] = useState<number | null>(
    null
  );

  const theme = useTheme();
  const containerRef = useRef<HTMLElement>(null);

  const handleDocumentClick = useCallback(
    (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as HTMLElement)
      ) {
        setIsHistoryOpen(false);
      }
    },
    [containerRef]
  );
  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => window.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  const handlePolicySelected = useCallback(
    (id: string) => {
      const cleanedPolicyId = id.trim().toUpperCase();
      props.onPolicySelected(cleanedPolicyId);
      if (cleanedPolicyId && cleanedPolicyId != '') {
        setErrorMessage('');
        const newHistory = Array.from(
          new Set([cleanedPolicyId, ...policyNoHistory])
        ).slice(0, 5);
        setPolicyNoHistory(newHistory);
        setIsHistoryOpen(false);
      }
    },
    [props.onPolicySelected, policyNoHistory]
  );

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handlePolicySelected(policyId);
    } else if (e.key === 'ArrowDown') {
      const newIndex =
        focusedPolicyIndex === null
          ? 0
          : Math.min(focusedPolicyIndex + 1, policyNoHistory.length - 1);
      setFocusedPolicyIndex(newIndex);
      setPolicyId(policyNoHistory[newIndex]);
    } else if (e.key === 'ArrowUp') {
      const newIndex =
        focusedPolicyIndex === null
          ? null
          : Math.max(focusedPolicyIndex - 1, 0);
      setFocusedPolicyIndex(newIndex);
      if (newIndex !== null) {
        setPolicyId(policyNoHistory[newIndex]);
      }
    }
  };

  const onResetPolicy = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const policyNo = event.target.value.trim();
    if (!policyNo) {
      handlePolicySelected(policyNo);
    }
  };

  const fieldColor = errorMessage
    ? theme.namedColors.utility.caution
    : 'inherit';

  const label =
    !isTextFieldFocused && !policyId ? 'Enter Policy Number' : 'Policy Number';
  return (
    <Box ref={containerRef}>
      <TextField
        data-testid="policy-id-text-field"
        sx={{
          width: '237px',
          'label.Mui-focused': {
            color: fieldColor,
          },
          '& .Mui-focused fieldset': {
            borderColor: `${fieldColor} !important`,
          },
        }}
        size="small"
        variant="outlined"
        label={label}
        value={policyId}
        autoComplete="off"
        onChange={(e) => {
          setPolicyId(e.target.value);
          onResetPolicy(e);
        }}
        onKeyDown={onKeyDown}
        onFocus={() => setIsTextFieldFocused(true)}
        onBlur={() => setIsTextFieldFocused(false)}
        onClick={() => setIsHistoryOpen(true)}
      />
      <Typography
        color="error"
        fontSize="0.75rem"
        sx={{ fontSize: '0.75rem', position: 'absolute', margin: '4px 16px' }}
      >
        {errorMessage}
      </Typography>

      {/* Dropdown */}
      <Paper
        sx={{
          position: 'absolute',
          display: isHistoryOpen && policyNoHistory.length ? 'inherit' : 'none',
          width: '237px',
          borderRadius: '8px',
          zIndex: '2',
        }}
      >
        <MenuList sx={{}} onKeyDown={onKeyDown} disableListWrap>
          {policyNoHistory.map((id, i) => (
            <MenuItem
              key={id}
              data-testid="policy-id-item"
              sx={{
                backgroundColor:
                  i === focusedPolicyIndex
                    ? theme.palette.action.focus
                    : 'inherit',
              }}
              onClick={() => {
                setPolicyId(id);
                handlePolicySelected(id);
              }}
            >
              <HistoryIcon
                color="secondary"
                fontSize="small"
                sx={{ marginRight: '8px' }}
              />
              <Typography variant="body2">{id}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </Box>
  );
};

export { PolicyTextField };
