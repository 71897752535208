import axios from 'axios';
import { useConfig } from 'hooks/useConfig';
import { useQuery } from 'react-query';

export const useAgents = (enabled = false) => {
  const { api } = useConfig();
  return useQuery(
    `agents`,
    () => {
      return axios
        .get<{ id: string }[]>(`${api.baseUrl}/agents`)
        .then((res) => res.data);
    },
    { enabled: enabled }
  );
};
