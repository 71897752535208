/**
 * Compare function used for sorting.
 *
 * @export
 * @param {(number | string | Date)} a
 * @param {(number | string | Date)} b
 * @param {boolean} isAsc
 * @return {*}
 */
export function compare(
  a: number | string | Date,
  b: number | string | Date,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
