import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';

// Hooks
import { useAuthentication } from 'hooks/useAuthentication';
import { useLogger } from 'api/logger';
import { Log } from '@types';
import { LogAction, LogLevel } from 'constants/Logger';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import { useEffect, useState } from 'react';

interface SidebarLinkItem {
  link: string;
  icon: JSX.Element;
  text: string;
  isActive: boolean;
}

// Start Styled Components
const NavigationButton = styled(ListItemButton)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'left',
  '&.isActive , &.isActive:hover': {
    background: theme.namedColors.brand.copy,
  },
  '&:hover': {
    background: 'inherit',
  },
  paddingLeft: theme.spacing(3),
}));

const ListItemIconLeft = styled(ListItemIcon)(({ theme }) => ({
  alignItems: 'left',
  borderRadius: '8px',
  justifyContent: 'left',
  minWidth: '30px',
  '&.isActive': {
    color: theme.namedColors.utility.white,
  },
}));
const SideBarText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: theme.namedColors.brand.copy,
    fontSize: '.875rem',
  },
  '&.isActive .MuiListItemText-primary': {
    color: theme.namedColors.utility.white,
  },
}));
const SidebarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'auto hidden',
  width: '100%',
  padding: '0',
  backgroundColor: theme.namedColors.utility.white,
  '& .MuiList-root': {
    color: theme.namedColors.brand.copy,
  },
}));

const SidebarBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(2),
}));
const SidebarFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  justifyContent: 'end',
  marginBottom: theme.spacing(2),
}));

// End Styled Components

const SidebarRelativeLink = (linkItem: SidebarLinkItem) => {
  const clasCss = linkItem.isActive === true ? 'isActive' : '';
  return (
    <Link to={linkItem.link} key={linkItem.link}>
      <NavigationButton className={clasCss}>
        <ListItemIconLeft className={clasCss}>{linkItem.icon}</ListItemIconLeft>
        <SideBarText primary={linkItem.text} className={clasCss} />
      </NavigationButton>
    </Link>
  );
};

interface SidebarProps {
  topLinks: SidebarLinkItem[];
  bottomLinks: SidebarLinkItem[];
}

const Sidebar = (props: SidebarProps) => {
  const { auth } = useAuthentication();
  const [logData, setLogData] = useState<Log | null>(null);
  const { refetch: refetchLogger } = useLogger(logData);
  const { defaultAgentId } = useDefaultAgentId();

  useEffect(() => {
    if (logData) refetchLogger();
  }, [logData]);

  const signOut = () => {
    setLogData({
      logAction: LogAction.LOGOUT,
      logLevel: LogLevel.INFO,
      logMessage: 'Logout Success',
      logDescription: 'User successfully logout',
      user: {
        email: auth.getEmail(),
        officeCode: defaultAgentId,
        clientCode: auth.getClientCode(),
      },
    });
    auth.signOut();
  };

  return (
    <SidebarContainer>
      <SidebarBody>
        <List component="nav" sx={{ flex: '1 1 0' }}>
          {props.topLinks.map((relativeLink) => {
            return SidebarRelativeLink(relativeLink);
          })}
        </List>
      </SidebarBody>
      <SidebarFooter>
        <List component="nav">
          <>
            {props.bottomLinks.map((relativeLink) => {
              return SidebarRelativeLink(relativeLink);
            })}
            <NavigationButton data-testid="sign-out-button" onClick={signOut}>
              <ListItemIconLeft>
                <LogoutIcon />
              </ListItemIconLeft>
              <SideBarText primary="Sign Out" />
            </NavigationButton>
          </>
        </List>
      </SidebarFooter>
    </SidebarContainer>
  );
};
export default Sidebar;
