import { CustomTheme } from '@types';
import { ThemeOptions } from '@mui/material/styles';

const namedColors = {
  client: '#403C3C',
  primary: {
    default: '#d62311',
    hover: '#a11b13',
    pressed: '#671e17',
    depressed: '#400B05',
    disabled: '#EAA9A3',
  },
  secondary: {
    default: '#888888',
    hover: '#313131',
    pressed: '#1C1C1C',
    depressed: '#0F0F0F',
    disabled: '#E3E3E3',
  },
  utility: {
    highlight: '#005DB2',
    success: '#008555',
    critical: '#A30017',
    caution: '#AE0019',
    warning: '#F3A113',
    black: '#000000',
    white: '#FFFFFF',
  },
  brand: {
    copy: '#403C3C',
    copyLight: '#676C6F',
    background: '#E5E5E5',
  },
  light: {
    900: '#B1B1B1',
    700: '#C8C8C8',
    500: '#DFDFDF',
    300: '#F5F5F5',
    100: '#F2F2F2',
  },
  dark: {
    900: '#403C3C',
    700: '#575353',
    500: '#6D6A6A',
    300: '#848383',
    100: '#9B9B9B',
  },
  performance: {
    visualization: {
      poor: '#FFA642',
      neutral: '#FFDD64',
      good: '#76DBA0',
      default: '#BABDBE',
    },
    backgrounds: {
      poor: 'rgba(255, 166, 66, 0.25)',
      neutral: 'rgba(255, 221, 100, 0.25)',
      good: 'rgba(118, 219, 160, 0.25)',
      default: 'rgba(186, 189, 190, 0.25)',
    },
    text: {
      poor: '#9A6F1D',
      neutral: '#986D1B',
      good: '#23854C',
    },
    points: {
      poor: '#9A6F1D',
      neutral: '#986D1B',
      good: '#23854C',
    },
  },
  winback: {
    visualization: {
      pending: '#fffac0',
      attempted: '#ffdbb0',
      successful: '#dcf7e7',
      escalated: '#e8bec5',
    },
    backgrounds: {
      pending: '#fffac0',
      attempted: '#ffdbb0',
      successful: '#dcf7e7',
      escalated: '#e8bec5',
    },
  },
};

/**
 * Default color theme for application.
 *
 * @type {*} */
const theme: ThemeOptions = {
  palette: {
    background: {
      default: namedColors.brand.background,
    },
    primary: {
      main: namedColors.primary.default,
    },
    secondary: {
      main: namedColors.secondary.default,
    },
    error: {
      main: namedColors.utility.caution,
    },
  },
  typography: {
    allVariants: {
      color: namedColors.brand.copy,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      *::-webkit-scrollbar {
        width: 0.375rem;
        margin-right: 2px;
      }
      *::-webkit-scrollbar-track {
      }
      *::-webkit-scrollbar-thumb {
        background-color: #c9cccf;
        border-radius: 4px;
        margin-right: 2px;
        width: 2px;
        opacity: 0.6;
        outline: none;
      } 
      `,
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: namedColors.primary.depressed,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: namedColors.primary.depressed,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&, &.MuiToggleButtonGroup-grouped, &.MuiToggleButtonGroup-grouped:not(:last-of-type), &.MuiToggleButtonGroup-grouped:not(:first-of-type)':
            {
              backgroundColor: namedColors.utility.white,
              border: `1px solid ${namedColors.secondary.default}`,
              color: namedColors.brand.copy,
              margin: '0 .25rem',
              '&.Mui-selected': {
                borderColor: namedColors.primary.pressed,
                color: namedColors.primary.pressed,
                backgroundColor: 'rgba(214, 35, 17, 0.1)',
              },
            },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: namedColors.utility.white,
        },
      },
    },
    // MuiCircularProgress: {
    //   styleOverrides: {
    //     root: {
    //       color: namedColors.primary.default,
    //     },
    //   },
    // },
  },
  namedColors: namedColors,
};

const AidTheme: CustomTheme = {
  key: 'AidTheme',
  theme: theme,
};

export { namedColors };
export default AidTheme;
