import axios from 'axios';
import { useConfig } from 'hooks/useConfig';
import { useQuery } from 'react-query';
import { ClientSettings, SettingData } from '@types';

export const useClientSettings = (
  id: string | null,
  clientCode: string,
  enabled = false
) => {
  const { api } = useConfig();
  return useQuery(
    `clientSettings`,
    () => {
      return axios
        .get<ClientSettings[]>(
          `${api.baseUrl}/${id}/settings/client-settings`,
          {
            params: {
              clientCode: clientCode,
            },
          }
        )
        .then((res) => res.data);
    },
    { enabled: enabled }
  );
};

export const useSaveClientSetting = (
  data: SettingData | null,
  clientCode: string,
  id: string | null,
  enabled = false
) => {
  const { api } = useConfig();
  const results = useQuery(
    `saveClientSetting`,
    () => {
      return axios
        .patch<ClientSettings>(
          `${api.baseUrl}/${id}/settings/client-settings`,
          { ...data },
          {
            params: {
              clientCode: clientCode,
            },
          }
        )
        .then((res) => res.data);
    },
    { enabled: enabled && data !== null && clientCode != '', retry: false }
  );
  return {
    ...results,
    isError: results.status,
  };
};
