import { ThemeOptions } from '@mui/material/styles';
import AidTheme from './AidTheme';

/**
 * List of available themes
 *  @type {*} */
const themes = [AidTheme];

/**
 * Gets theme from available themes.
 *
 * @export
 * @param {string} theme
 * @return {*}  {ThemeOptions}
 */
export default function getBrandTheme(theme: string): ThemeOptions {
  return themes.find((x) => x.key === theme)?.theme || {};
}
