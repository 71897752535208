import batteryChangeIcon from './assets/battery-change.svg';
import fuelDeliveryIcon from './assets/fuel-delivery.svg';
import lockOutIcon from './assets/lock-out.svg';
import tireChangeIcon from './assets/tire-change.svg';
import towIcon from './assets/tow.svg';
import winchOutIcon from './assets/winch-out.svg';

interface RoadsideEventIconProps {
  serviceType: string;
}

const iconMap = [
  {
    key: 'Battery Change',
    icon: batteryChangeIcon,
  },
  {
    key: 'Fuel Delivery',
    icon: fuelDeliveryIcon,
  },
  {
    key: 'Lock Out',
    icon: lockOutIcon,
  },
  {
    key: 'Tire Change',
    icon: tireChangeIcon,
  },
  {
    key: 'Lock Out',
    icon: lockOutIcon,
  },
  {
    key: 'Tow',
    icon: towIcon,
  },
  {
    key: 'Winch Out',
    icon: winchOutIcon,
  },
  {
    key: 'Battery Jump',
    icon: batteryChangeIcon,
  },
];

const RoadsideEventIcon = ({ serviceType }: RoadsideEventIconProps) => {
  const icon = iconMap.find((im) => im.key === serviceType) || {
    key: '',
    icon: tireChangeIcon,
  };

  return <img src={icon.icon} alt={serviceType} />;
};
export default RoadsideEventIcon;
