import { useState, useEffect, useRef } from 'react';

interface TimerProps {
  countDownSeconds: number;
}

/**
 * Timer component. Shows timer in seconds
 *
 * @param {TimerProps} { countDownSeconds }
 * @return {*}
 */
const Timer = ({ countDownSeconds }: TimerProps) => {
  const [seconds, setSeconds] = useState<number>(countDownSeconds);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timerRef = useRef<any>();

  useEffect(() => {
    function tick() {
      setSeconds((prevSeconds: number) => prevSeconds - 1);
    }
    timerRef.current = setInterval(() => tick(), 1000);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      clearInterval(timerRef.current);
    }
  }, [seconds]);
  return <span data-testid="timer">{seconds}</span>;
};

export default Timer;
