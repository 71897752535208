import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import logo from 'assets/images/logo.png';

/**
 * Unauthorized View
 *
 * @return {*}
 */
const Unauthorized = () => {
  return (
    <Box
      sx={{
        height: '100%',
        px: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      data-testid="unauthorized-container"
    >
      <Box
        component="img"
        src={logo}
        sx={{ maxWidth: '400px', width: '100%', px: '1rem' }}
      ></Box>
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '400px',
        }}
      >
        <Typography variant="h1" sx={{ m: 4, fontSize: '3.5rem' }}>
          Unauthorized
        </Typography>
        <Typography variant="body1">
          Please click the below link to go back to the dashboard.
        </Typography>
      </Box>
      <Link to="/">
        <Button variant="contained" sx={{ py: 1, my: 2 }}>
          Go to Dashboard
        </Button>
      </Link>
    </Box>
  );
};

export default Unauthorized;
