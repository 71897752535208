import { styled } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';

const SettingsHeaderToStyle = ({ children, ...props }: TypographyProps) => (
  <Typography variant="h4" {...props}>
    {children}
  </Typography>
);
export const SettingsHeader = styled(SettingsHeaderToStyle)(({ theme }) => ({
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  marginBottom: theme.spacing(2),
  fontWeight: '600',
}));
const SettingsContentToStyle = ({ children, ...props }: TypographyProps) => (
  <Typography variant="body1" {...props}>
    {children}
  </Typography>
);
export const SettingsContent = styled(SettingsContentToStyle)(({ theme }) => ({
  lineHeight: '1.75rem',
  marginBottom: theme.spacing(1),
}));

const SettingsSubTitleToStyle = ({ children, ...props }: TypographyProps) => (
  <Typography {...props} variant="h1">
    {children}
  </Typography>
);
export const SettingsSubTitle = styled(SettingsSubTitleToStyle)(
  ({ theme }) => ({
    fontWeight: '500',
    lineHeight: '1.75rem',
    fontSize: '1.1rem',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  })
);
const FormLabelToStyle = ({ children }: TypographyProps) => (
  <FormLabel>{children}</FormLabel>
);
export const FormLabelStyled = styled(FormLabelToStyle)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  '&.Mui-focused': {
    color: theme.palette.text.primary,
  },
}));
const SettingBoxToStyle = ({ children }: TypographyProps) => (
  <Box>{children}</Box>
);
export const SettingBoxStyled = styled(SettingBoxToStyle)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

export const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 17,
  padding: 0,
  marginRight: 17,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 11,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(10px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '3px 2px 2px 2px',
    '&.Mui-checked': {
      transform: 'translateX(17px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#3dcc79',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 11,
    height: 11,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#e42d3d',
    boxSizing: 'border-box',
  },
}));
