import { useTheme } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ToastProps {
  type: 'INFO' | 'ERROR';
  open: boolean;
  message: string;
  onClose?: () => void;
  autoHideDuration?: number | null;
  vertical?: 'top' | 'bottom';
  horizontal?: 'center' | 'right' | 'left';
}
/**
 * Generic Toast component for use on the main dashboard cards.
 *
 * @param {ToastProps}
 * @return {*}
 */
const Toast = ({
  vertical = 'top',
  horizontal = 'center',
  ...props
}: ToastProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    if (props.onClose) props.onClose();
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={props.autoHideDuration ? props.autoHideDuration : 10000}
      onClose={handleClose}
      action={action}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Box
        component="div"
        data-testid="toastcontent"
        sx={{
          padding: theme.spacing(1.5),
          background: '#FFFFFF',
          boxShadow:
            '0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          display: 'flex',
        }}
      >
        {props.type === 'INFO' && (
          <CheckCircleOutlineIcon sx={{ color: '#008555' }} />
        )}
        {props.type === 'ERROR' && (
          <ErrorOutlineIcon sx={{ color: '#d61928' }} />
        )}
        <Box component="div" sx={{ padding: '0px 10px' }}>
          {props.message}
        </Box>
        <IconButton
          size="medium"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          sx={{ padding: '0px' }}
          data-testid="toastclose"
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Box>
    </Snackbar>
  );
};
export default Toast;
