import React, { createContext, useContext, useEffect, useState } from 'react';
import { Popup } from '../components/Popup';

export interface OpenPopupProps {
  children: React.ReactNode;
  id: string;
  open?: boolean;
  onDismiss?: () => void;
  onConfirm?: () => void;
  title?: string;
  subtitle?: string;
  btnCancel?: string;
  btnConfirm?: string;
  btnConfirmDisabled?: boolean;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  closeIcon?: boolean;
}

export interface PopupContextProps {
  openPopup: (options: OpenPopupProps) => void;
  closePopup: () => void;
}

interface DialogConfig {
  children: React.ReactNode;
  id: string;
  open: boolean;
  onDismiss?: () => void;
  onConfirm?: () => void;
  title: string;
  subtitle: string;
  btnCancel: string;
  btnConfirm: string;
  btnConfirmDisabled: boolean;
  maxWidth: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  closeIcon: boolean;
}
export const PopupContext = createContext<PopupContextProps | null>(null);

export const PopupProvider = ({ children }: { children: React.ReactNode }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dialogOpen, setPopupOpen] = useState<boolean>(false);
  const [dialogConfig, setPopupConfig] = useState<DialogConfig | null>(null);

  const openPopup = (options: OpenPopupProps) => {
    setPopupConfig({
      children: options.children,
      id: options.id,
      open: true,
      onConfirm: options?.onConfirm,
      onDismiss: options?.onDismiss,
      title: options?.title ? options?.title : '',
      subtitle: options?.subtitle ? options.subtitle : '',
      btnCancel: options?.btnCancel ? options?.btnCancel : '',
      btnConfirm: options?.btnConfirm ? options.btnConfirm : '',
      btnConfirmDisabled: options?.btnConfirmDisabled
        ? options?.btnConfirmDisabled
        : false,
      maxWidth: options?.maxWidth ? options.maxWidth : 'xs',
      closeIcon: options?.closeIcon ? options?.closeIcon : false,
    });
    setPopupOpen(true);
  };
  const closePopup = () => {
    setPopupOpen(false);
    setPopupConfig(null);
  };

  const onConfirm = () => {
    closePopup();
    if (dialogConfig?.onConfirm) {
      dialogConfig?.onConfirm();
    }
  };

  const onDismiss = () => {
    closePopup();
    if (dialogConfig?.onDismiss) {
      dialogConfig.onDismiss();
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  return (
    <PopupContext.Provider value={{ openPopup, closePopup }}>
      {children}
      {dialogOpen && dialogConfig && (
        <Popup
          maxWidth={dialogConfig.maxWidth}
          id={dialogConfig.id}
          data-testid={dialogConfig.id}
          open={dialogOpen}
          onDismiss={onDismiss}
          onConfirm={onConfirm}
          title={dialogConfig.title}
          subtitle={dialogConfig.subtitle}
          btnCancel={dialogConfig.btnCancel}
          btnConfirm={dialogConfig.btnConfirm}
          btnConfirmDisabled={dialogConfig.btnConfirmDisabled}
          closeIcon={dialogConfig.closeIcon}
        >
          {dialogConfig?.children}
        </Popup>
      )}
    </PopupContext.Provider>
  );
};

export function usePopup(): PopupContextProps {
  const context = useContext(PopupContext);
  return context || assertNever();
}

function assertNever(): never {
  throw Error('Cannot use usePopup outside of PopupProvider');
}
