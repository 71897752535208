import { memo } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { LayoutMain } from 'layouts/LayoutMain';
import { RequireAuth } from 'components/RequireAuth';
import { Callback } from 'views/Callback';
import Dashboard from 'views/Dashboard';
import Login from 'views/Login';
import Error from 'views/Error';
import PageNotFound from 'views/PageNotFound';
import { RequireSettings } from 'components/RequireSettings';
import Unauthorized from 'views/Unauthorized';
import UserSettings from 'views/UserSettings';
import PerformanceMetricsBreakdown from 'views/Dashboard/components/PerformanceMetricsBreakdown';
import { LayoutAdmin } from 'layouts/LayoutAdmin';
import { Admin } from 'views/Admin';
import ClientConfiguration from 'views/ClientConfiguration';
import { AdminCallback } from 'views/AdminCallback';
import ClientAdmin from 'views/ClientAdmin';

const routeHandler = (): RouteObject[] => {
  return [
    {
      element: (
        <RequireAuth>
          <RequireSettings>
            <LayoutAdmin />
          </RequireSettings>
        </RequireAuth>
      ),
      children: [
        {
          path: '/admin/dashboard',
          element: <Admin />,
        },
        {
          path: '/admin/config',
          element: <ClientConfiguration />,
        },
      ],
    },
    {
      element: (
        <RequireAuth>
          <RequireSettings>
            <LayoutMain />
          </RequireSettings>
        </RequireAuth>
      ),
      children: [
        {
          path: '/',
          element: <Dashboard />,
        },
        {
          path: '/:clientCodeSlug',
          element: <Dashboard />,
        },
        { path: '/user-settings', element: <UserSettings /> },
        {
          path: '/performance-metrics',
          element: <PerformanceMetricsBreakdown />,
        },
        {
          path: '/settings',
          element: <ClientAdmin />,
        },
      ],
    },
    {
      path: '/admin',
      element: <Login />,
    },
    {
      path: '/admin/callback',
      element: <AdminCallback />,
    },
    {
      path: '/login',
      element: <Login />,
    },

    {
      path: '/callback',
      element: <Callback />,
    },
    {
      path: '/error',
      element: <Error />,
    },
    {
      path: '/unauthorized',
      element: <Unauthorized />,
    },
    {
      path: '/404',
      element: <PageNotFound />,
    },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ];
};

const Routes = () => {
  return useRoutes(routeHandler());
};

const GeneratedRouter = memo(Routes);

export default GeneratedRouter;
