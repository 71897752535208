import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { GoogleAnalyticsEventOptions } from 'constants/GoogleAnalytics';
import { useAuthentication } from './useAuthentication';
import { useDefaultAgentId } from './useDefaultAgentId';

const NONPROD_DEV_GOOGLE_ANALYTICS_ID = 'G-RZRD0X0WRT';
const APP_ENV = 'DEV';

export interface GoogleAnalyticsContextProps {
  sendGAEvent: (name: string, options: GoogleAnalyticsEventOptions) => void;
  sendGAEventLogin: (
    name: string,
    options: GoogleAnalyticsEventOptions
  ) => void;
}

export const GoogleAnalyticsContext =
  React.createContext<GoogleAnalyticsContextProps | null>(null);

export const GoogleAnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { auth } = useAuthentication();
  const { defaultAgentId } = useDefaultAgentId();

  const [loading, setLoading] = useState(true);
  const [isUserid, setIsUserid] = useState(false);

  const isNonAgero = auth.getDomain() != 'agero.com' ? true : false;
  const hasAllReadAccess = auth.hasAllReadAccess();
  const user_id =
    isNonAgero && defaultAgentId && !hasAllReadAccess
      ? defaultAgentId
      : auth.getName();

  const agent_office_code =
    isNonAgero && !hasAllReadAccess ? defaultAgentId : '';
  const state_code = agent_office_code ? agent_office_code.split('-')[0] : '';

  const get_user_type = () => {
    if (auth.isSystemAdmin()) return 'System Admin';
    else if (auth.isSupportSpecialist()) return 'Support Specialist';
    else if (auth.isClientAdmin()) return 'Client Admin';
    else if (auth.isManager()) return 'Manager';
    else if (auth.isAgent()) return 'Agent';
    else return '';
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const sendGAEvent = (name: string, options: GoogleAnalyticsEventOptions) => {
    const user_type = get_user_type();
    if (!isUserid) {
      const GAID =
        auth.getDomain() == 'agero.com'
          ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID
          : process.env[
              'REACT_APP_GOOGLE_ANALYTICS_ID_' + auth.getClientCode()
            ];

      ReactGA.initialize(GAID || NONPROD_DEV_GOOGLE_ANALYTICS_ID, {
        gtagOptions: {
          user_id: user_id,
        },
      });
      setIsUserid(true);
    }
    if (options.content_group) {
      options.content_group = '/' + user_id + options.content_group;
    }
    options.debug_mode =
      process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE === 'true' || false;
    options.user_properties = {
      user_alias_id: auth.getName(),
      user_domain: auth.getDomain(),
      ...(agent_office_code && { agent_office_code }),
      ...(state_code && { state_code }),
      ...(user_type && { user_type }),
    };
    ReactGA.event(name, options);
  };

  const sendGAEventLogin = (
    name: string,
    options: GoogleAnalyticsEventOptions
  ) => {
    const ENV = process.env.REACT_APP_ENV || APP_ENV;
    const GAID =
      ENV == 'PROD'
        ? process.env.REACT_APP_GOOGLE_ANALYTICS_ID_SFR
        : process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    ReactGA.initialize(GAID || NONPROD_DEV_GOOGLE_ANALYTICS_ID);
    options.debug_mode =
      process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE === 'true' || false;
    ReactGA.event(name, options);
  };

  if (loading) {
    return null;
  }

  return (
    <GoogleAnalyticsContext.Provider value={{ sendGAEvent, sendGAEventLogin }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export function useGoogleAnalytics(): GoogleAnalyticsContextProps {
  const ga = useContext(GoogleAnalyticsContext);
  return ga ? ga : assertNever();
}

function assertNever(): never {
  throw Error(
    'Cannot use useGoogleAnalytics outside of GoogleAnalyticsProvider'
  );
}
