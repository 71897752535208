import { AuthProviderMapItem } from '@types';

/**
 * Array of auth domains for swapping.
 * @type {*} */
export const AUTH_PROVIDER_MAP: AuthProviderMapItem[] = [
  {
    authEmailDomain: 'statefarm.com',
    replacementDomain: 'statefarmaid.com',
  },
  {
    authEmailDomain: 'shelterinsurance.com',
    replacementDomain: 'shelterinsurance-agent.com',
  },
];
