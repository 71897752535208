import { Theme, useTheme } from '@mui/material/styles';
import { AtaSplitUp, BarChartData, NpsBreakoutPercentages } from '@types';
import BarChart from '../BarChart';
import { getNpsBarChartData } from 'utilities/data-utils';

interface serviceType {
  type: string;
  percentage: number;
  npsCategoryCount: number;
}

interface PerformanceBreakoutChartProps {
  npsPercentage?: {
    policyHolders: NpsBreakoutPercentages;
    state: NpsBreakoutPercentages;
  };
  percentages?: serviceType[] | AtaSplitUp;
  type?: 'percent' | 'time' | 'value';
  title?: string;
  category?: string;
  enableStateLevelNps?: boolean;
}

const fetchColor = (
  val: serviceType,
  type: 'percent' | 'time' | 'value',
  theme: Theme
) => {
  if (type === 'value') {
    if (val.percentage >= 50)
      return theme.namedColors.performance.visualization.good;
    else if (val.percentage < 0)
      return theme.namedColors.performance.visualization.poor;
    else return theme.namedColors.performance.visualization.neutral;
  } else if (type === 'time') {
    if (val.percentage < 60)
      return theme.namedColors.performance.visualization.good;
    else if (val.percentage >= 60 && val.percentage <= 90)
      return theme.namedColors.performance.visualization.neutral;
    else if (val.percentage > 90)
      return theme.namedColors.performance.visualization.poor;
  } else {
    if (val.type === 'Tire Change') return '#EEDEBB';
    else if (val.type === 'Battery Jump') return '#FF695B';
    else if (val.type === 'Tow') return '#526B2B';
    else if (val.type === 'Lock Out') return '#36BDE8';
  }
};

const getData = (
  theme: Theme,
  percentages: serviceType[] | AtaSplitUp,
  type: 'percent' | 'time' | 'value',
  category: string | undefined
) => {
  if (Array.isArray(percentages)) {
    return percentages.map((item: serviceType) => {
      let valueLabel = '';
      const value = Math.round(item.percentage);
      if (
        (item.percentage == 0 &&
          category == 'NPS' &&
          item.npsCategoryCount == 0) ||
        (category === 'ATA' && item.percentage == 0)
      )
        valueLabel = 'Not Available';
      else
        valueLabel = `${value}${
          type === 'percent' ? '%' : type === 'time' ? ' min' : ''
        }`;
      return {
        label: item.type,
        value: value,
        color: fetchColor(item, type, theme),
        valueLabel: valueLabel,
      };
    });
  } else {
    return [
      {
        label: '0-30 min',
        value: Math.round(percentages['below30']),
        color: theme.namedColors.performance.visualization.good,
        valueLabel: `${Math.round(percentages['below30'])}%`,
      },
      {
        label: '31-60 min',
        value: Math.round(percentages['below60']),
        color: theme.namedColors.performance.visualization.good,
        valueLabel: `${Math.round(percentages['below60'])}%`,
      },
      {
        label: '61-90 min',
        value: Math.round(percentages['below90']),
        color: theme.namedColors.performance.visualization.neutral,
        valueLabel: `${Math.round(percentages['below90'])}%`,
      },
      {
        label: '91-120 min',
        value: Math.round(percentages['below120']),
        color: theme.namedColors.performance.visualization.neutral,
        valueLabel: `${Math.round(percentages['below120'])}%`,
      },
      {
        label: '120+ min',
        value: Math.round(percentages['above120']),
        color: theme.namedColors.performance.visualization.poor,
        valueLabel: `${Math.round(percentages['above120'])}%`,
      },
    ];
  }
};

const PerformanceBreakoutChart = (props: PerformanceBreakoutChartProps) => {
  const theme = useTheme();
  let data: BarChartData | undefined = [];
  let maxValue = 0;
  if (props.percentages && props.type) {
    data = getData(theme, props.percentages, props.type, props.category);
    //adding Math.abs() to the value, because we are showing negative NPS value in the bar chart
    maxValue = data.reduce(
      (max, { value }) => Math.max(Math.abs(value), max),
      0
    );
  } else if (props.npsPercentage) {
    data = getNpsBarChartData(
      theme,
      props.npsPercentage,
      props.enableStateLevelNps ? props.enableStateLevelNps : false
    );
    maxValue = data.reduce((max, { value }) => Math.max(value, max), 0);
  }
  return (
    <BarChart
      maxValue={maxValue}
      data={data}
      isBidirectional={props.type === 'value'}
    />
  );
};

export default PerformanceBreakoutChart;
