import { CircularProgress, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useAuthentication } from 'hooks/useAuthentication';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsPageLocation,
  GoogleAnalyticsPageTitle,
  GoogleAnalyticsContentGroup,
} from 'constants/GoogleAnalytics';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import { UserSettingsData, UserSettings } from '@types';
import { useSettings } from 'hooks/useSettings';
import { usePrompt } from '../../hooks/usePrompt';
import { Toast } from 'components/Snackbar';
import { Navigate, useLocation } from 'react-router-dom';
import { GoogleReview } from './components/GoogleReview';
import { usePatchUserSettings } from 'api/user-settings';
import { SettingFeature } from 'constants/SettingFeature';

/**
 *Setting users View
 *
 * @return {*}
 */
const SettingsUser = () => {
  const theme = useTheme();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const location: { state: any } | any = useLocation();
  const { auth } = useAuthentication();
  const { sendGAEvent } = useGoogleAnalytics();
  const { defaultAgentId } = useDefaultAgentId();
  const {
    agentSettings,
    setAgentSettings,
    showExitDialog,
    setShowExitDialog,
    enableFeature,
    clientSettings,
  } = useSettings();
  const [settingsPatchData, setSettingsPatchData] =
    useState<UserSettings | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [appData, setAppData] = useState<UserSettingsData | null>(null);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const id = auth.hasAllReadAccess() ? auth.getName() : defaultAgentId;
  const { refetch: refetchSaveSettings, status: saveSettingsStatus } =
    usePatchUserSettings(settingsPatchData, id);
  const [singleSetting, setSingleSetting] = useState<UserSettingsData | null>(
    null
  );

  const saveOnExit = () => {
    setFormSubmitted(true);
  };

  const updateAppSettings = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = agentSettings?.settings;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val: any = agentSettings;
    if (singleSetting) {
      const settingName = Object.keys(singleSetting)[0];
      if (data && singleSetting && settingName) {
        data[settingName] =
          singleSetting[settingName as keyof UserSettingsData];
        val.settings = data;
        setAgentSettings(val);
        onSettingChanged('remove', settingName);
      }
    } else {
      val.settings = appData;
      setAgentSettings(val);
      setAppData(null);
      setShowExitDialog(false);
    }
  };

  useEffect(() => {
    if (settingsPatchData) {
      refetchSaveSettings();
      if (formSubmitted) {
        setFormSubmitted(false);
        setShowToast(true);
        updateAppSettings();
      }
    }
  }, [settingsPatchData]);

  useEffect(() => {
    if (appData && showExitDialog && formSubmitted) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const invalidList: any = Object.fromEntries(
        Object.entries(appData).filter(
          ([key, value]) => key && value.isValid === false
        )
      );
      if (!Object.keys(invalidList).length) {
        Object.entries(appData).forEach(([key, value]) => {
          if (key) delete value.isValid;
        });
        setSettingsPatchData({ email: auth.getEmail(), settings: appData });
      } else setFormSubmitted(false);
    }
  }, [formSubmitted]);

  usePrompt(
    'There are unsaved changes on this page. Your changes will be lost if you don’t save them.',
    showExitDialog,
    saveOnExit
  );

  useEffect(() => {
    if (agentSettings)
      sendGAEvent(GoogleAnalyticsEventType.PageView, {
        content_group: GoogleAnalyticsContentGroup.UserSettings,
        page_location: GoogleAnalyticsPageLocation.UserSettings,
        page_title: GoogleAnalyticsPageTitle.UserSettings,
      });
    setShowExitDialog(false);
  }, [agentSettings]);

  const onSettingChanged = (
    action: string,
    name: string,
    valid?: boolean,
    data?: UserSettingsData
  ) => {
    if (collapsed) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value: any = appData ? appData : {};
      if (action === 'add' && data) {
        value[name] = data[name as keyof UserSettingsData];
        value[name]['isValid'] = valid;
      }
      if (action === 'remove') {
        delete value[name];
      }

      if (Object.keys(value).length) setShowExitDialog(true);
      else setShowExitDialog(false);
      setAppData(value);
      setSingleSetting(null);
    }
  };
  const onSaveSingleSetting = (data: UserSettingsData) => {
    setSingleSetting(data);
    setShowToast(false);
    setFormSubmitted(true);
    const val = { email: auth.getEmail(), settings: data };
    setSettingsPatchData(val);
  };

  if (
    !auth.isAgent() ||
    (clientSettings.length &&
      !enableFeature(SettingFeature.googleReviewRequest))
  ) {
    return (
      <div data-testid="navigate-element">
        <Navigate to="/unauthorized" replace />
      </div>
    );
  }

  return (
    <>
      <Box data-testid="user-settings-container">
        <Toast
          open={
            showToast &&
            (saveSettingsStatus === 'success' || saveSettingsStatus === 'error')
          }
          message={`${
            saveSettingsStatus === 'success'
              ? 'Your changes were saved succesfully'
              : 'Some error occured. Please try again later'
          }`}
          type={`${saveSettingsStatus === 'success' ? 'INFO' : 'ERROR'}`}
        />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            px: 3,
            py: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: theme.spacing(1),
            }}
          >
            <Typography variant="h3">Settings</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: theme.spacing(1),
            }}
          >
            <Typography
              variant="body1"
              sx={{
                mb: theme.spacing(1),
                mt: theme.spacing(1),
              }}
            >
              Manage your Roadside AID dashboard settings here.
            </Typography>
          </Box>
          {(!agentSettings?.settings || formSubmitted) && (
            <CircularProgress
              size="50px"
              sx={{
                margin: '0 auto',
                position: 'fixed',
                top: '50%',
                left: '50%',
                zIndex: '9999',
              }}
            />
          )}
          {agentSettings?.settings && (
            <Box
              sx={{
                pointerEvents: formSubmitted ? 'none' : 'all',
                opacity: formSubmitted ? '0.5' : '1',
              }}
            >
              <GoogleReview
                onSaveUserSetting={onSaveSingleSetting}
                initialValue={agentSettings?.settings?.googleReviewRequest}
                sendEventToGA={sendGAEvent}
                onSettingChanged={onSettingChanged}
                collapsed={setCollapsed}
                collapsible={location?.state?.collapsed}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SettingsUser;
