import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { ClientSettings } from '@types';
import { Clients } from './components/Clients';
import { useNavigate, Navigate } from 'react-router-dom';
import { useSettings } from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { useAuthentication } from 'hooks/useAuthentication';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsPageLocation,
  GoogleAnalyticsPageTitle,
  GoogleAnalyticsContentGroup,
} from 'constants/GoogleAnalytics';

const Admin = () => {
  const { auth } = useAuthentication();
  const theme = useTheme();
  const navigate = useNavigate();
  const { clientSettings } = useSettings();
  const [clientList, setClientList] = useState<ClientSettings[] | []>([]);
  const { sendGAEvent } = useGoogleAnalytics();

  useEffect(() => {
    if (clientSettings) setClientList(clientSettings);
  }, [clientSettings]);

  const gotoClientConfig = (uniqueId: string) => {
    navigate('/admin/config?id=' + uniqueId, {
      state: {
        collapsed: true,
      },
    });
  };

  useEffect(() => {
    sendGAEvent(GoogleAnalyticsEventType.PageView, {
      content_group: GoogleAnalyticsContentGroup.Admin,
      page_location: GoogleAnalyticsPageLocation.Admin,
      page_title: GoogleAnalyticsPageTitle.Admin,
    });
  }, []);

  if (!auth.isSystemAdmin() && !auth.isSupportSpecialist()) {
    return (
      <div data-testid="navigate-element">
        <Navigate to="/unAuthorized" replace />
      </div>
    );
  }

  return (
    <Box data-testid="admin-container">
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          px: 3,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(1),
          }}
        >
          <Typography variant="h3">Select a Client</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(1),
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mb: theme.spacing(1),
              mt: theme.spacing(1),
            }}
          >
            Select a client to view and manage their dashboard configuration.
          </Typography>
        </Box>
        {!clientList.length && (
          <CircularProgress
            size="50px"
            sx={{
              margin: '0 auto',
              position: 'fixed',
              top: '50%',
              left: '50%',
              zIndex: '9999',
            }}
          />
        )}
        <Box sx={{ display: 'flex', gap: '3%' }}>
          {clientList.map((client) => {
            return (
              <Clients
                key={client.clientCode}
                client={client}
                clientConfiguration={gotoClientConfig}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Admin;
