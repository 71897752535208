import PerformanceMetrics from '../PerformanceMetrics';
import NpsMetricCard from '../NpsMetricCard';
import { NpsMetricsPerformance } from '@types';
import CollapsedNpsMetric from '../CollapsedNpsMetric';
import {
  GoogleAnalyticsEventOptions,
  GoogleAnalyticsEventType,
} from 'constants/GoogleAnalytics';
import NpsPerformanceCard from '../NpsPerformanceCard';
import { FilterValues } from 'views/Dashboard/Dashboard';

const NpsPerformanceMetrics = (
  props:
    | {
        metricData: NpsMetricsPerformance;
        filterValues: FilterValues;
        loading?: false;
        sendEventToGA?: (
          event_type: GoogleAnalyticsEventType,
          data: GoogleAnalyticsEventOptions
        ) => void;
        enableStateLevelNps: boolean;
        showPerformanceBreakDown: (flag: boolean) => void;
      }
    | {
        loading: true;
        sendEventToGA?: (
          event_type: GoogleAnalyticsEventType,
          data: GoogleAnalyticsEventOptions
        ) => void;
      }
) => {
  const loading = props.loading || false;

  return loading ? (
    <PerformanceMetrics
      collapsible
      loading={true}
      mainMetric={<></>}
      metrics={[]}
      storageKey="nps"
      sendEventToGA={props.sendEventToGA}
    />
  ) : (
    <PerformanceMetrics
      collapsible
      loading={false}
      mainMetric={<CollapsedNpsMetric nps={props.metricData.nps} />}
      storageKey="nps"
      metrics={[
        {
          label: 'Total Completed Events',
          value: `${props.metricData.eventsCompleted?.toLocaleString()}`,
        },
        {
          label: 'Average Arrival Time',
          value: `${props.metricData.averageArrivalTime}min`,
        },
        {
          label: 'Digital App Request',
          value: `${Math.round(props.metricData.appEngagement)}%`,
        },
      ]}
      sendEventToGA={props.sendEventToGA}
    >
      <NpsMetricCard
        loading={loading}
        title="NET PROMOTER SCORE"
        npsMetrics={props.metricData}
        showSubtitle={false}
        enableStateLevelNps={props.enableStateLevelNps}
      />
      <NpsPerformanceCard
        metricData={props.metricData}
        enableStateLevelNps={props.enableStateLevelNps}
        showPerformanceBreakDown={props.showPerformanceBreakDown}
        sendEventToGA={props.sendEventToGA}
      />
    </PerformanceMetrics>
  );
};

export { NpsPerformanceMetrics };
