import AppBar from '@mui/material/AppBar';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import PhoneIcon from '@mui/icons-material/Phone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { Contact } from '../Contact';
import { ReferProvider } from '../ReferProvider';
import CircularProgress from '@mui/material/CircularProgress';

const StyledButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      sx={(theme) => ({
        color: theme.namedColors.utility.white,
        fontWeight: 500,
      })}
      {...props}
    >
      {children}
    </Button>
  );
};

const DrawerContent = ({
  open,
  title,
  drawerWidth,
  children,
  onClose,
}: {
  open: boolean;
  title: string;
  drawerWidth: string | number;
  children: React.ReactNode;
  onClose: () => void;
}) => {
  return (
    <Drawer
      anchor="right"
      PaperProps={{ elevation: 1 }}
      variant="persistent"
      open={open}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={(theme) => ({
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          paddingTop: '70px',
          scrollPaddingTop: '70px',
          zIndex: 1201,
          minWidth: '400px',
        },
        borderColor: theme.namedColors.secondary.default,
        borderStyle: 'solid',
        borderWidth: '1px',
      })}
    >
      <Box
        sx={(theme) => ({
          padding: theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100vh',
        })}
        data-testid={`${title}-drawer`}
      >
        <Box
          sx={(theme) => ({
            pb: theme.spacing(2),
            display: 'flex',
            justifyContent: 'space-between',
          })}
        >
          <Typography variant="h3">{title}</Typography>
          <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

interface NavbarProps {
  clientLogo: JSX.Element | string;
  isAdmin: boolean;
  clientTheme: string;
  clientCode: string;
  helpUrl: string;
  referSPUrl: string;
}

const Navbar = (props: NavbarProps) => {
  const [contactOpen, setContactOpen] = useState<boolean>(false);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const isMobile = useMediaQuery((mq: Theme) => mq.breakpoints.down('md'));
  const drawerWidth = isMobile ? '60%' : 550;
  const drawerWidthHelp = isMobile ? '70%' : 800;

  const handleContactDrawerToggle = () => {
    setContactOpen((prevState: boolean) => !prevState);
    setHelpOpen(false);
  };

  const handleHelpDrawerToggle = () => {
    setHelpOpen((prevState: boolean) => !prevState);
    setContactOpen(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        data-testid="appbar"
        sx={(theme) => ({
          padding: '2px 30px 2px 25px',
          background: props.clientTheme
            ? props.clientTheme
            : theme.namedColors.client,
          zIndex: 1205,
        })}
        elevation={1}
      >
        <Toolbar
          disableGutters
          sx={{ justifyContent: 'space-between', minHeight: '64px !important' }}
        >
          <Box sx={{ alignIitems: 'center', display: 'flex' }}>
            {props.clientLogo}{' '}
          </Box>
          {!props.isAdmin && props.clientCode !== 'AGR' && (
            <Box sx={{ alignIitems: 'center', display: 'flex' }}>
              <StyledButton
                onClick={handleContactDrawerToggle}
                data-testid="contact-icon"
              >
                <PhoneIcon sx={{ mr: '5px' }} />
                Contact
              </StyledButton>
              {props.helpUrl && (
                <StyledButton
                  onClick={handleHelpDrawerToggle}
                  data-testid="help-icon"
                >
                  <HelpOutlineIcon sx={{ mr: '5px' }} />
                  Help
                </StyledButton>
              )}
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {!props.isAdmin && (
        <nav>
          <DrawerContent
            title="Contact"
            open={contactOpen}
            drawerWidth={drawerWidth}
            onClose={handleContactDrawerToggle}
          >
            <Contact />
            {props.referSPUrl && (
              <ReferProvider referSPUrl={props.referSPUrl} />
            )}
          </DrawerContent>
          {props.helpUrl && (
            <DrawerContent
              open={helpOpen}
              drawerWidth={drawerWidthHelp}
              title="Help"
              onClose={handleHelpDrawerToggle}
            >
              {loading && (
                <CircularProgress
                  sx={{ position: 'absolute', top: '50%', left: '50%' }}
                />
              )}
              <iframe
                onLoad={() => setLoading(false)}
                src={props.helpUrl}
                height="100%"
                style={{ border: 'none' }}
              />
            </DrawerContent>
          )}
        </nav>
      )}
    </>
  );
};
export default Navbar;
