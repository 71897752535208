import { ReactElement, useEffect, useRef } from 'react';
import { useAuthentication as useAuth } from 'hooks/useAuthentication';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useConfig } from 'hooks/useConfig';
import { usePopup } from 'hooks/usePopup';
import Timer from 'components/Timer';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
import { useSettings } from 'hooks/useSettings';
import { useClientCode } from 'hooks/useClientCode';

/**
 * Utility component to restrict user access and maintain users' logged in session
 *
 * @param {{ children: ReactElement }} { children }
 * @return {*}
 */
const RequireAuth = ({ children }: { children: ReactElement }) => {
  const { auth } = useAuth();
  const { defaultAgentId } = useDefaultAgentId();
  const defaultClientCode = useClientCode();
  const { clientCodeSlug } = useParams();
  const isUserSignedIn = auth.isUserSignedIn();
  const { setShowExitDialog } = useSettings();
  const location = useLocation();
  const navigate = useNavigate();
  const config = useConfig();
  const { openPopup, closePopup } = usePopup();
  const userInfo = JSON.stringify({
    user: {
      email: auth.getEmail(),
      officeCode: defaultAgentId,
    },
    corelationId: localStorage.getItem('corelationId'),
  });

  const activityTimeout = config.auth.activityTimeout
    ? config.auth.activityTimeout
    : 30 * 60;
  const timeout = activityTimeout * 1000;

  const getLastActionTime = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const lastActionTime: any = localStorage.getItem(
      'ageroAuth_lastActionTime'
    );
    const time = isNaN(lastActionTime) ? 0 : parseInt(lastActionTime, 10);
    return time;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activityTimerRef = useRef<any>();

  const checkActivity = () => {
    const now = Date.now();
    const timeLeft = getLastActionTime() + timeout;
    const diff = timeLeft - now;
    const isTimedOut = diff < 60000;
    if (isTimedOut) {
      clearInterval(activityTimerRef.current);
      openPopup({
        children: (
          <>
            Your session is about to expire due to inactivity. You will be
            logged out in <Timer countDownSeconds={62} /> seconds.
          </>
        ),
        title: 'Session Timeout',
        id: 'sessionTimeout-dialog',
        btnConfirm: 'Keep me logged in',
        btnCancel: 'Log out Now',
        onConfirm: () => globalClickEvent(),
        onDismiss: () => signOut(),
      });
      localStorage.setItem('agent-inactive', userInfo);
    }
    if (diff < 3000) {
      setShowExitDialog(false);
    }
  };

  activityTimerRef.current = setInterval(() => {
    checkActivity();
  }, 5000);

  const signOut = () => {
    localStorage.removeItem('agent-inactive');
    clearInterval(activityTimerRef.current);
    auth.signOut();
  };

  const globalClickEvent = () => {
    auth.resetActivityTimer();
    clearInterval(activityTimerRef.current);
    activityTimerRef.current = setInterval(() => {
      checkActivity();
    }, 5000);
    closePopup();
  };

  useEffect(() => {
    if (isUserSignedIn === false) {
      const path = window.location.href.includes('admin') ? '/admin' : '/login';
      navigate(path, { state: { from: location }, replace: true });
    } else {
      if (window.location.href == window.location.origin + '/')
        navigate('/' + defaultClientCode, {
          state: { from: location },
          replace: true,
        });
      if (clientCodeSlug && clientCodeSlug != defaultClientCode) {
        navigate('/404', {
          state: { from: location },
          replace: true,
        });
      }
    }

    window.addEventListener('click', globalClickEvent);

    return () => {
      window.removeEventListener('click', globalClickEvent);
      clearInterval(activityTimerRef.current);
    };
  }, []);

  // Prevent a flash of dashboard
  if (isUserSignedIn === false) {
    return <div></div>;
  }

  return children;
};
export default RequireAuth;
