import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import logo from 'assets/images/logo.png';
import { useLogger } from 'api/logger';
import { Log } from '@types';
import { useState, useEffect } from 'react';
import { useAuthentication } from 'hooks/useAuthentication';
import { useDefaultAgentId } from 'hooks/useDefaultAgentId';
/**
 * Default error View
 *
 * @return {*}
 */
const ErrorView = () => {
  const { auth } = useAuthentication();
  const { defaultAgentId } = useDefaultAgentId();
  const [logData, setLogData] = useState<Log | null>(null);
  const { refetch: refetchLogger } = useLogger(logData);
  const errorLogStorageKey = 'errorlog';
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (logData) refetchLogger();
  }, [logData]);

  useEffect(() => {
    if (localStorage[errorLogStorageKey]) {
      setErrorMessage(
        'Please email aidsupport@agero.com for further assistance.'
      );
      const logInfo = JSON.parse(localStorage[errorLogStorageKey]);
      setLogData({
        ...logInfo,
        user: {
          email: auth.getEmail(),
          officeCode: defaultAgentId,
        },
      });
      localStorage.removeItem(errorLogStorageKey);
    } else {
      setErrorMessage(
        'Please contact your system administrator for assistance'
      );
    }
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        px: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      data-testid="error-container"
    >
      <Box
        component="img"
        src={logo}
        sx={{ maxWidth: '400px', width: '100%', px: '1rem' }}
      ></Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          height: '30vh',
          my: 5,
          width: '100%',
        }}
      >
        <svg
          style={{
            width: '100%',
            maxWidth: '750px',
            height: '80%',
            display: 'block',
            margin: '0 auto',
          }}
          viewBox="0 0 710 146"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M94.217 122.968V144.048H18.6481V122.968H94.217ZM26.8494 1.95187V144.048H0V1.95187H26.8494ZM84.356 60.6056V81.1979H18.6481V60.6056H84.356ZM93.9241 1.95187V23.1297H18.6481V1.95187H93.9241Z"
            fill="#888888"
          />
          <path
            d="M136.86 1.95187H188.508C199.313 1.95187 208.588 3.57843 216.334 6.83155C224.08 10.0847 230.035 14.8993 234.201 21.2754C238.367 27.6515 240.45 35.4915 240.45 44.7955C240.45 52.1475 239.148 58.5236 236.544 63.9238C233.941 69.324 230.263 73.8458 225.512 77.4893C220.825 81.1328 215.293 83.9955 208.914 86.0775L200.517 90.3717H154.922L154.727 69.1938H188.801C194.334 69.1938 198.923 68.2179 202.568 66.266C206.213 64.3142 208.946 61.6141 210.769 58.1658C212.656 54.7175 213.6 50.7812 213.6 46.357C213.6 41.6074 212.689 37.5085 210.866 34.0602C209.044 30.5468 206.278 27.8467 202.568 25.9599C198.857 24.0731 194.171 23.1297 188.508 23.1297H163.709V144.048H136.86V1.95187ZM217.115 144.048L184.212 80.4171L212.624 80.3195L245.917 142.682V144.048H217.115Z"
            fill="#888888"
          />
          <path
            d="M287.974 1.95187H339.623C350.427 1.95187 359.703 3.57843 367.448 6.83155C375.194 10.0847 381.15 14.8993 385.315 21.2754C389.481 27.6515 391.564 35.4915 391.564 44.7955C391.564 52.1475 390.262 58.5236 387.659 63.9238C385.055 69.324 381.378 73.8458 376.626 77.4893C371.94 81.1328 366.407 83.9955 360.028 86.0775L351.632 90.3717H306.036L305.841 69.1938H339.915C345.448 69.1938 350.037 68.2179 353.682 66.266C357.327 64.3142 360.061 61.6141 361.883 58.1658C363.771 54.7175 364.715 50.7812 364.715 46.357C364.715 41.6074 363.803 37.5085 361.981 34.0602C360.158 30.5468 357.392 27.8467 353.682 25.9599C349.972 24.0731 345.285 23.1297 339.623 23.1297H314.824V144.048H287.974V1.95187ZM368.229 144.048L335.327 80.4171L363.738 80.3195L397.032 142.682V144.048H368.229Z"
            fill="#888888"
          />
          <path
            d="M554.199 69.389V76.7086C554.199 87.4438 552.767 97.1056 549.903 105.694C547.039 114.217 542.971 121.471 537.699 127.457C532.427 133.443 526.146 138.03 518.856 141.218C511.566 144.406 503.462 146 494.545 146C485.758 146 477.686 144.406 470.331 141.218C463.041 138.03 456.728 133.443 451.39 127.457C446.053 121.471 441.92 114.217 438.991 105.694C436.062 97.1056 434.597 87.4438 434.597 76.7086V69.389C434.597 58.5887 436.062 48.9269 438.991 40.4037C441.92 31.8806 446.02 24.6261 451.293 18.6404C456.565 12.5896 462.846 7.97014 470.136 4.78209C477.491 1.59403 485.562 0 494.349 0C503.267 0 511.37 1.59403 518.66 4.78209C525.95 7.97014 532.232 12.5896 537.504 18.6404C542.841 24.6261 546.942 31.8806 549.806 40.4037C552.735 48.9269 554.199 58.5887 554.199 69.389ZM527.057 76.7086V69.1938C527.057 61.4514 526.341 54.6524 524.909 48.7968C523.477 42.8761 521.362 37.8988 518.563 33.865C515.764 29.8311 512.314 26.8057 508.213 24.7888C504.113 22.7068 499.491 21.6658 494.349 21.6658C489.142 21.6658 484.521 22.7068 480.485 24.7888C476.515 26.8057 473.13 29.8311 470.331 33.865C467.533 37.8988 465.385 42.8761 463.888 48.7968C462.456 54.6524 461.74 61.4514 461.74 69.1938V76.7086C461.74 84.3859 462.456 91.1849 463.888 97.1056C465.385 103.026 467.533 108.036 470.331 112.135C473.195 116.169 476.645 119.227 480.681 121.309C484.716 123.391 489.338 124.432 494.545 124.432C499.752 124.432 504.373 123.391 508.409 121.309C512.444 119.227 515.829 116.169 518.563 112.135C521.362 108.036 523.477 103.026 524.909 97.1056C526.341 91.1849 527.057 84.3859 527.057 76.7086Z"
            fill="#888888"
          />
          <path
            d="M600.943 1.95187H652.591C663.396 1.95187 672.671 3.57843 680.417 6.83155C688.162 10.0847 694.118 14.8993 698.284 21.2754C702.45 27.6515 704.532 35.4915 704.532 44.7955C704.532 52.1475 703.231 58.5236 700.627 63.9238C698.023 69.324 694.346 73.8458 689.594 77.4893C684.908 81.1328 679.375 83.9955 672.997 86.0775L664.6 90.3717H619.005L618.81 69.1938H652.884C658.417 69.1938 663.005 68.2179 666.65 66.266C670.295 64.3142 673.029 61.6141 674.852 58.1658C676.739 54.7175 677.683 50.7812 677.683 46.357C677.683 41.6074 676.772 37.5085 674.949 34.0602C673.127 30.5468 670.36 27.8467 666.65 25.9599C662.94 24.0731 658.254 23.1297 652.591 23.1297H627.792V144.048H600.943V1.95187ZM681.198 144.048L648.295 80.4171L676.707 80.3195L710 142.682V144.048H681.198Z"
            fill="#888888"
          />
        </svg>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '400px',
          mb: 1,
        }}
      >
        <Typography variant="h1" sx={{ mb: 2 }}>
          Something went wrong
        </Typography>
        <Typography variant="body1">{errorMessage}</Typography>
      </Box>
    </Box>
  );
};

export default ErrorView;
