/**
 * Parses JSON without throwing error for invalid JSON
 *
 * @template T
 * @param {string} json
 * @return {*}  {(T | undefined)}
 */
const tryJSONParse = <T>(json: string): T | undefined => {
  try {
    return JSON.parse(json);
  } catch (err) {
    return undefined;
  }
};

export { tryJSONParse };
