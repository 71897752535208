import Typography from '@mui/material/Typography';
import { NpsBreakoutPercentages } from '@types';
import MetricCard from '../MetricCard';
import PerformanceBreakoutChart from '../PerformanceBreakoutChart';

const NpsCategoryBreakoutCard = ({
  loading,
  percentages,
  enableStateLevelNps,
}: {
  loading: boolean;
  percentages: {
    policyHolders: NpsBreakoutPercentages;
    state: NpsBreakoutPercentages;
  };
  enableStateLevelNps: boolean;
}) => (
  <MetricCard
    loading={loading}
    title="Net Promoter Score Category"
    titleTooltipContent={
      <>
        <Typography>
          A "<strong>Promoter</strong>" responded 9 or 10 when asked their
          likelihood to recommend their roadside service to friends or
          colleagues (on a scale of 0-10).
          <br />
          <br />A "<strong>Neutral</strong>" responded 7 or 8 when asked their
          likelihood to recommend their roadside service to friends or
          colleagues (on a scale of 0-10).
          <br />
          <br />A "<strong>Detractor</strong>" responded 0-6 when asked their
          likelihood to recommend their roadside service to friends or
          colleagues (on a scale of 0-10).
        </Typography>
      </>
    }
    noBorder
  >
    <PerformanceBreakoutChart
      npsPercentage={percentages}
      enableStateLevelNps={enableStateLevelNps}
    />
  </MetricCard>
);

export default NpsCategoryBreakoutCard;
