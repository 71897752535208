import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useConfig } from './useConfig';
import { LogAction, LogLevel } from 'constants/Logger';
import { useLogger } from 'api/logger';
import { Log } from '@types';
import { useDefaultAgentId } from './useDefaultAgentId';
import { useAuthentication } from './useAuthentication';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoggingContextProps {}

export const LoggingContext = React.createContext<LoggingContextProps | null>(
  null
);

export const LoggingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(true);
  const config = useConfig();

  const [logData, setLogData] = useState<Log | null>(null);
  const { refetch: refetchLogger } = useLogger(logData);
  const { defaultAgentId } = useDefaultAgentId();
  const { auth } = useAuthentication();

  const [officeCode, setOfficeCode] = useState<string | null>(defaultAgentId);

  useEffect(() => {
    if (logData) refetchLogger();
  }, [logData]);

  const sendLogger = (data: Log, id: string | null) => {
    if (id !== officeCode) {
      setOfficeCode(id);
    }
    setLogData({
      ...data,
      user: {
        email: auth.getEmail(),
        officeCode: id ? id : officeCode,
        clientCode: auth.getClientCode(),
      },
    });
  };

  useEffect(() => {
    axios.interceptors.request.use((request) => {
      return request;
    });
    axios.interceptors.response.use(
      (response) => {
        const requestURL = response.config.url;
        const requestMethod = response.config.method;
        let logAction = LogAction.APISUCCESS;
        let logMessage = 'Api Success : ' + requestURL;
        let id = defaultAgentId;
        if (requestURL?.includes('roadside-events/metrics')) {
          logAction = LogAction.GETROADSIDEEVENTS;
          logMessage = 'Roadside event api success';
        } else if (requestURL?.includes('roadside-events')) {
          logAction = LogAction.GETPERFORMANCEMETRICS;
          logMessage = 'Performance metrics api success';
        } else if (
          requestURL?.includes('user-settings') &&
          requestMethod == 'post'
        ) {
          logAction = LogAction.SAVEUSERLOGIN;
          logMessage = 'user settings POST api success';
        }
        if (requestURL?.includes('/agents/')) {
          id = requestURL?.split('/')[5];
        }
        if (
          requestURL?.includes('settings') ||
          requestURL?.includes('/users')
        ) {
          id = requestURL?.split('/')[4];
        }
        if (!requestURL?.includes('logger/log')) {
          sendLogger(
            {
              logAction: logAction,
              logLevel: LogLevel.INFO,
              logMessage: logMessage,
              logDescription: logMessage + ' with status: ' + response.status,
              additionalInfo: JSON.stringify({ api: requestURL }),
            },
            id
          );
        }
        return response;
      },
      async function (error) {
        const requestURL = error.config.url;
        const requestMethod = error.config.method;
        let logAction = LogAction.DEFAULTAPIERROR;
        let logMessage = 'Api Failed: ' + requestURL;
        let id = defaultAgentId;
        if (requestURL.includes('roadside-events/metrics')) {
          logAction = LogAction.GETROADSIDEEVENTS;
          logMessage = 'Roadside event api failed';
        } else if (requestURL.includes('roadside-events')) {
          logAction = LogAction.GETPERFORMANCEMETRICS;
          logMessage = 'Performance metrics api failed';
        } else if (
          requestURL.includes('user-settings') &&
          requestMethod == 'post'
        ) {
          logAction = LogAction.SAVEUSERLOGIN;
          logMessage = 'user settings POST api failed';
        }
        if (requestURL?.includes('/agents/')) {
          id = requestURL?.split('/')[5];
        }
        if (
          requestURL?.includes('settings') ||
          requestURL?.includes('/users')
        ) {
          id = requestURL?.split('/')[4];
        }
        sendLogger(
          {
            logAction: logAction,
            logLevel: LogLevel.ERROR,
            logMessage: logMessage,
            logDescription:
              logMessage + ' with status: ' + error.response.status,
            additionalInfo: JSON.stringify({
              error: error.response.data,
              api: requestURL,
            }),
          },
          id
        );
        return Promise.reject(error);
      }
    );

    setLoading(false);
  }, [config]);

  if (loading) {
    return null;
  }

  return <LoggingContext.Provider value="">{children}</LoggingContext.Provider>;
};

export function useLogging(): LoggingContextProps {
  const log = useContext(LoggingContext);
  return log ? log : assertNever();
}

function assertNever(): never {
  throw Error('Cannot use useLogging outside of LoggingProvider');
}
