const AgeroLogo = () => {
  return (
    <svg
      width="88"
      height="40"
      viewBox="0 0 88 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63.3999 13.8403L65.5332 18.1292L83.7999 8.0625L63.3999 13.8403Z"
        fill="white"
      />
      <path
        d="M60.5107 8.0625L63.3996 13.8403L83.7996 8.0625H60.5107Z"
        fill="url(#paint0_linear_7580_38394)"
      />
      <path
        d="M83.7999 8.0625L65.5332 18.1292L72.1554 31.3514L83.7999 8.0625Z"
        fill="url(#paint1_linear_7580_38394)"
      />
      <path
        d="M13.3552 13.8594H15.3996L21.0885 27.0816H18.3996L17.1774 24.0594H11.4441L10.2441 27.0816H7.59961L13.3552 13.8594ZM16.3329 22.0372L14.3107 16.6594L12.2441 22.0372H16.3329Z"
        fill="#A1A1A4"
      />
      <path
        d="M31.5773 18.1297V26.2852C31.5773 29.5075 29.9995 31.5741 26.5106 31.5741C24.844 31.5741 23.3329 31.2186 22.0662 30.1075L23.4218 28.2852C24.3106 29.1075 25.244 29.5741 26.5106 29.5741C28.6662 29.5741 29.3551 28.3519 29.3551 26.5741V25.9297H29.3106C28.6662 26.7964 27.5106 27.2408 26.3773 27.2408C23.6884 27.2408 21.9106 25.1964 21.9106 22.6408C21.9106 19.9964 23.5773 17.9297 26.3329 17.9297C27.5773 17.9297 28.7551 18.4186 29.4218 19.4852H29.4662V18.1519H31.5773V18.1297ZM24.1329 22.5741C24.1329 23.9964 25.244 25.1741 26.7551 25.1741C28.4218 25.1741 29.4218 24.0408 29.4218 22.5519C29.4218 20.9964 28.3995 19.9297 26.7773 19.9297C25.2218 19.9075 24.1329 20.9964 24.1329 22.5741Z"
        fill="#A1A1A4"
      />
      <path
        d="M35.6224 23.3717C35.7779 24.705 36.7779 25.505 38.0224 25.505C39.1335 25.505 39.8668 24.9717 40.4224 24.2828L42.0224 25.505C40.9779 26.7939 39.6446 27.305 38.2446 27.305C35.5335 27.305 33.3779 25.4161 33.3779 22.5939C33.3779 19.7717 35.5335 17.8828 38.2446 17.8828C40.7557 17.8828 42.4446 19.6384 42.4446 22.7495V23.3717H35.6224ZM40.2224 21.705C40.2002 20.3939 39.3557 19.5717 37.9557 19.5717C36.6224 19.5717 35.8002 20.4161 35.6446 21.705H40.2224Z"
        fill="#A1A1A4"
      />
      <path
        d="M44.311 18.1285H46.5555V19.5507H46.5999C47.0888 18.5285 48.0666 17.9062 49.2444 17.9062C49.5333 17.9062 49.7777 17.9507 50.0444 18.0396V20.1951C49.6666 20.1063 49.311 20.0174 48.9555 20.0174C46.8444 20.0174 46.5555 21.7951 46.5555 22.284V27.084H44.311V18.1285Z"
        fill="#A1A1A4"
      />
      <path
        d="M55.667 17.9062C58.3781 17.9062 60.5336 19.7951 60.5336 22.6174C60.5336 25.4396 58.3781 27.3285 55.667 27.3285C52.9558 27.3285 50.8003 25.4396 50.8003 22.6174C50.8003 19.7729 52.9558 17.9062 55.667 17.9062ZM55.667 25.284C57.3114 25.284 58.2892 23.9951 58.2892 22.5951C58.2892 21.1951 57.3114 19.9063 55.667 19.9063C54.0225 19.9063 53.0447 21.1951 53.0447 22.5951C53.0447 23.9951 54.0225 25.284 55.667 25.284Z"
        fill="#A1A1A4"
      />
      <path
        d="M62.1997 26.4321C62.1997 26.9655 61.7331 27.4099 61.1553 27.4099C60.5775 27.4099 60.1108 26.9877 60.1108 26.4321C60.1108 25.8988 60.5775 25.4766 61.1775 25.4766C61.7331 25.4988 62.1997 25.8988 62.1997 26.4321ZM60.3775 26.4321C60.3775 26.8543 60.7108 27.2099 61.1775 27.2099C61.6219 27.2099 61.9553 26.8766 61.9553 26.4543C61.9553 26.0321 61.6219 25.6988 61.1775 25.6988C60.7108 25.6766 60.3775 26.0321 60.3775 26.4321ZM60.9997 26.9432H60.7553V25.9877C60.8442 25.9655 60.9775 25.9655 61.1553 25.9655C61.3553 25.9655 61.422 25.9877 61.4886 26.0321C61.5553 26.0766 61.5997 26.1432 61.5997 26.2321C61.5997 26.3432 61.5108 26.4321 61.3997 26.4543C61.5108 26.4766 61.5553 26.5655 61.5775 26.6988C61.5997 26.8543 61.6442 26.8988 61.6442 26.921H61.3997C61.3775 26.8766 61.3553 26.8099 61.3108 26.6988C61.2886 26.6099 61.222 26.5432 61.1108 26.5432H60.9997V26.9432ZM60.9997 26.4099H61.1108C61.2442 26.4099 61.3553 26.3655 61.3553 26.2766C61.3553 26.1877 61.2886 26.121 61.1331 26.121C61.0664 26.121 61.022 26.121 60.9997 26.121V26.4099Z"
        fill="#A1A1A4"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7580_38394"
          x1="60.5116"
          y1="10.9534"
          x2="83.8017"
          y2="10.9534"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E31837" />
          <stop offset="0.1723" stopColor="#E32239" />
          <stop offset="0.3853" stopColor="#E53841" />
          <stop offset="0.6197" stopColor="#E7524F" />
          <stop offset="0.8677" stopColor="#EB7064" />
          <stop offset="1" stopColor="#ED8072" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7580_38394"
          x1="65.5411"
          y1="19.7145"
          x2="83.802"
          y2="19.7145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E31837" />
          <stop offset="0.1723" stopColor="#E32239" />
          <stop offset="0.3853" stopColor="#E53841" />
          <stop offset="0.6197" stopColor="#E7524F" />
          <stop offset="0.8677" stopColor="#EB7064" />
          <stop offset="1" stopColor="#ED8072" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default AgeroLogo;
