import { useState, useEffect } from 'react';
import { tryJSONParse } from 'utilities/json';
import { useClientCode } from './useClientCode';

/**
 * React hook for storing and retrieving agent-id-history
 *
 * @return {*}
 */
const usePolicyNoHistory = () => {
  const defaultClientCode = useClientCode();

  const historyPolicyStorageKey = 'policy-no-history-' + defaultClientCode;
  const [policyHistory, setPolicyHistory] = useState<string[]>(
    tryJSONParse(localStorage[historyPolicyStorageKey]) || []
  );

  useEffect(() => {
    if (defaultClientCode)
      localStorage[historyPolicyStorageKey] = JSON.stringify(policyHistory);
  }, [policyHistory]);

  return [policyHistory, setPolicyHistory] as const;
};

export { usePolicyNoHistory };
