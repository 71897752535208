import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate, useSearchParams, Navigate } from 'react-router-dom';
import Settings from 'views/Settings';
import { useSettings } from 'hooks/useSettings';
import {
  GoogleAnalyticsEventType,
  GoogleAnalyticsPageLocation,
  GoogleAnalyticsPageTitle,
  GoogleAnalyticsContentGroup,
} from 'constants/GoogleAnalytics';
import { useGoogleAnalytics } from 'hooks/useGoogleAnalytics';
import { useEffect } from 'react';

const ClientConfiguration = () => {
  const [searchParams] = useSearchParams();
  const clientCode = searchParams.get('id');
  const theme = useTheme();
  const navigate = useNavigate();
  const { getClientConfigByCode } = useSettings();
  const clientConfig = getClientConfigByCode(clientCode);
  const { sendGAEvent } = useGoogleAnalytics();

  const gotoClients = () => {
    navigate('/admin/dashboard');
  };

  useEffect(() => {
    sendGAEvent(GoogleAnalyticsEventType.PageView, {
      content_group: GoogleAnalyticsContentGroup.ClientConfigure,
      page_location: GoogleAnalyticsPageLocation.ClientConfigure,
      page_title: GoogleAnalyticsPageTitle.ClientConfigure,
    });
  }, []);

  if (!clientCode) {
    return (
      <div data-testid="navigate-element">
        <Navigate to="/admin/dashboard" replace />
      </div>
    );
  }

  return (
    <Box data-testid="client-config-container">
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          px: 3,
          py: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(2),
          }}
        >
          <Typography
            data-testid="back-to-configure"
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              '&:hover': {
                cursor: 'pointer',
              },
              fontSize: '12px',
              fontWeight: '500',
            }}
            onClick={gotoClients}
          >
            <NavigateBeforeIcon />
            BACK
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(1),
          }}
        >
          <Typography variant="h3">Dashboard Configuration</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: theme.spacing(1),
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mb: theme.spacing(1),
              mt: theme.spacing(1),
            }}
          >
            Manage {clientConfig?.name}'s dashboard configuration here.
          </Typography>
        </Box>
        <Settings clientConfig={getClientConfigByCode(clientCode)} />
      </Box>
    </Box>
  );
};
export default ClientConfiguration;
