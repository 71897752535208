import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import useTheme from '@mui/material/styles/useTheme';
import { useState, useRef, useEffect, useCallback, ChangeEvent } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { Paper } from '@mui/material';
import { useAgentIdHistory } from 'hooks/useAgentIdHistory';
import { useAgents } from 'api/agents';

import { Log } from '@types';
import { LogAction, LogLevel } from 'constants/Logger';
import { useClientCode } from 'hooks/useClientCode';

interface AgentTextFieldProps {
  onAgentSelected: (id: string) => void;
  agentId?: string | null | undefined;
  logger: (data: Log) => void;
  agentIdentifier: string;
}

const AgentTextField = (props: AgentTextFieldProps) => {
  const [agentIdHistory, setAgentIdHistory] = useAgentIdHistory();
  const defaultClientCode = useClientCode();
  const { data: agents, refetch: refetchAgents } = useAgents();
  const validAgentIds = agents?.map(({ id }) => id) || [];
  const [agentId, setAgentId] = useState(agentIdHistory[0] || '');
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [focusedAgentIndex, setFocusedAgentIndex] = useState<number | null>(
    null
  );

  const theme = useTheme();
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (props.agentId != null && props.agentId != undefined) {
      setAgentId(props.agentId);
    }
  }, [props.agentId]);

  useEffect(() => {
    if (defaultClientCode !== 'AGR') refetchAgents();
  }, []);

  const handleDocumentClick = useCallback(
    (e: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as HTMLElement)
      ) {
        setIsHistoryOpen(false);
      }
    },
    [containerRef]
  );
  useEffect(() => {
    window.addEventListener('click', handleDocumentClick);
    return () => window.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  const handleAgentSelected = useCallback(
    (id: string) => {
      const cleanedAgentId =
        id !== null && id !== undefined ? id.trim().toUpperCase() : '';
      if (cleanedAgentId === '') {
        setErrorMessage('');
        props.onAgentSelected(cleanedAgentId);
      } else if (!validAgentIds.includes(cleanedAgentId)) {
        setErrorMessage(props.agentIdentifier + ' not found');
        props.logger({
          logLevel: LogLevel.ERROR,
          logMessage: props.agentIdentifier + ' not found',
          logDescription:
            props.agentIdentifier + ' not found for ' + cleanedAgentId,
          logAction: LogAction.AGENTNOTFOUND,
        });
      } else {
        setErrorMessage('');
        props.onAgentSelected(cleanedAgentId);
        const newHistory = Array.from(
          new Set([cleanedAgentId, ...agentIdHistory])
        ).slice(0, 5);
        setAgentIdHistory(newHistory);
      }
      setIsHistoryOpen(false);
    },
    [validAgentIds, props.onAgentSelected, agentIdHistory]
  );

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleAgentSelected(agentId);
    } else if (e.key === 'ArrowDown') {
      const newIndex =
        focusedAgentIndex === null
          ? 0
          : Math.min(focusedAgentIndex + 1, agentIdHistory.length - 1);
      setFocusedAgentIndex(newIndex);
      setAgentId(agentIdHistory[newIndex]);
    } else if (e.key === 'ArrowUp') {
      const newIndex =
        focusedAgentIndex === null ? null : Math.max(focusedAgentIndex - 1, 0);
      setFocusedAgentIndex(newIndex);
      if (newIndex !== null) {
        setAgentId(agentIdHistory[newIndex]);
      }
    }
  };
  const onResetAgentId = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const agentOfficeCode = event.target.value.trim();
    if (!agentOfficeCode) {
      handleAgentSelected(agentOfficeCode);
    }
  };

  const fieldColor = errorMessage
    ? theme.namedColors.utility.caution
    : 'inherit';

  const label =
    !isTextFieldFocused && !agentId
      ? 'Enter ' + props.agentIdentifier
      : props.agentIdentifier;
  return (
    <Box ref={containerRef}>
      <TextField
        data-testid="agent-id-text-field"
        sx={{
          width: '237px',
          'label.Mui-focused': {
            color: fieldColor,
          },
          '& .Mui-focused fieldset': {
            borderColor: `${fieldColor} !important`,
          },
        }}
        size="small"
        variant="outlined"
        label={label}
        value={agentId}
        autoComplete="off"
        onChange={(e) => {
          setAgentId(e.target.value);
          onResetAgentId(e);
        }}
        onKeyDown={onKeyDown}
        onFocus={() => setIsTextFieldFocused(true)}
        onBlur={() => setIsTextFieldFocused(false)}
        onClick={() => setIsHistoryOpen(true)}
      />
      <Typography
        color="error"
        fontSize="0.75rem"
        sx={{ fontSize: '0.75rem', position: 'absolute', margin: '4px 16px' }}
      >
        {errorMessage}
      </Typography>

      {/* Dropdown */}
      <Paper
        sx={{
          position: 'absolute',
          display: isHistoryOpen && agentIdHistory.length ? 'inherit' : 'none',
          width: '237px',
          borderRadius: '8px',
          zIndex: '1',
        }}
      >
        <MenuList sx={{}} onKeyDown={onKeyDown} disableListWrap>
          {agentIdHistory.map((id, i) => (
            <MenuItem
              key={id}
              data-testid="agent-id-item"
              sx={{
                backgroundColor:
                  i === focusedAgentIndex
                    ? theme.palette.action.focus
                    : 'inherit',
              }}
              onClick={() => {
                setAgentId(id);
                handleAgentSelected(id);
              }}
            >
              <HistoryIcon
                color="secondary"
                fontSize="small"
                sx={{ marginRight: '8px' }}
              />
              <Typography variant="body2">{id}</Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Paper>
    </Box>
  );
};

export { AgentTextField };
