import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { SettingData } from '@types';
import { AllEnabled } from '../AllEnabled';
import { SettingsCollapse } from 'components/SettingsCollapse';
import { SettingsContent } from 'constants/SettingsStyled';
import {
  GoogleAnalyticsEventOptions,
  GoogleAnalyticsEventType,
} from 'constants/GoogleAnalytics';

interface SettingItemProps {
  data: SettingData;
  onSaveSetting: (data: SettingData) => void;
  sendEventToGA?: (
    event_type: GoogleAnalyticsEventType,
    data: GoogleAnalyticsEventOptions
  ) => void;
  editAccess: boolean;
}

/**
 * Settinsg Item View
 *
 * @return {*}
 */
const SettingItem = ({
  data,
  onSaveSetting,
  sendEventToGA,
  editAccess,
}: SettingItemProps) => {
  const theme = useTheme();
  const [enabled, setEnabled] = useState<boolean>(data.enabled);

  const onEnableChange = (value: boolean) => {
    setEnabled(value);
    onSaveSetting({
      ...data,
      enabled: value,
    });
  };

  return (
    <SettingsCollapse
      key={`settings-collapse-${data.settingName}`}
      title={data.title}
      page={`Dashboard Configuration`}
      sendEventToGA={sendEventToGA}
    >
      <SettingsContent
        sx={{
          mb: theme.spacing(2),
        }}
      >
        {data.desc}
      </SettingsContent>
      <Box
        sx={{
          pointerEvents: editAccess ? 'all' : 'none',
          opacity: editAccess ? '1' : '0.5',
        }}
      >
        <AllEnabled
          title={data.title}
          onAllEnabledChange={onEnableChange}
          initialValue={enabled}
        />
      </Box>
    </SettingsCollapse>
  );
};

export default SettingItem;
