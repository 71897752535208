import { useState, useEffect } from 'react';
import { tryJSONParse } from 'utilities/json';
import { useClientCode } from './useClientCode';

/**
 * React hook for storing and retrieving agent-id-history
 *
 * @return {*}
 */
const useAgentIdHistory = () => {
  const defaultClientCode = useClientCode();
  const historyStorageKey = 'agent-id-history-' + defaultClientCode;

  const [history, setHistory] = useState<string[]>(
    tryJSONParse(localStorage[historyStorageKey]) || []
  );

  useEffect(() => {
    if (defaultClientCode)
      localStorage[historyStorageKey] = JSON.stringify(history);
  }, [history]);

  return [history, setHistory] as const;
};

export { useAgentIdHistory };
